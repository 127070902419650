import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledGBDFLButton } from '../ServiceRequestStyles';

function SPModalButton({ SPModal, code, value }) {
  const { t } = useTranslation();

  if (code === 'request_form_contest_leaders_short.type_edu_org') {
    const con_com = window.PassportForm.values['request_form_contest_leaders_short.con_com'];
    if (con_com !== '2' || !value) return null;
  }

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        text={t('serviceRequest_chooseServiceProvider')}
        onClick={() => SPModal.open({ code, value })}
      />
    </div>
  );
}

export default SPModalButton;
