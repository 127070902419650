import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    staticTable_selectNoOptions: 'Нет данных',
    staticTable_collapse: 'Свернуть таблицу',
    staticTable_expand: 'Развернуть таблицу',
    staticTable_load: 'Загрузить еще',
    staticTable_page: 'Страница',
  },
  kk: {
    staticTable_selectNoOptions: 'Деректер жоқ',
    staticTable_collapse: 'Кестені жиыру',
    staticTable_expand: 'Кестені ашу',
    staticTable_load: 'Тағы жүктеу',
    staticTable_page: 'Страница',
  }
});
