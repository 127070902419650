const paths = {
  homePage: '/',
  createRequestPage: '/services/create/:metadataKey',
  accountPage: '/account',
  requestsPage: '/requests',
  completedRequestsPage: '/completed-requests',
  accountSettingsPage: '/account/settings',
  viewRequestFormPage: '/view/:from/:id',
  supportPage: '/support',
  instructionsPage: '/instructions'
};

export default paths;
