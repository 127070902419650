import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    oModal_title: 'Организации образования',
    oModal_location: 'Населенный пункт',
    oDucks_loadDataFailed: 'Не удалось загрузить организации образования',
    oColumns_name: 'Наименование организации образования'
  },
  kk: {
    oModal_title: 'Организации образования',
    oModal_location: 'Населенный пункт',
    oDucks_loadDataFailed: 'Не удалось загрузить организации образования',
    oColumns_name: 'Наименование организации образования'
  }
});