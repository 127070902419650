import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';

import { ServiceProvidersApi } from './ServiceProvidersApi';
import { handleError } from '../../../modules/utils/handleError';
import { serviceRequestModule } from '../ServiceRequestDucks';

export const serviceProvidersModule = 'serviceProviders';
const LOADING = `${serviceProvidersModule}/LOADING`;
const TABLE_DATA = `${serviceProvidersModule}/TABLE_DATA`;
const SET_CHILD = `${serviceProvidersModule}/SET_CHILD_SERVICE_PROVIDER`;
const LOADING_CHILD = `${serviceProvidersModule}/LOADING_CHILD`;
const CLEAR_STATE = `${serviceProvidersModule}/CLEAR_STATE`;

/**
 * Reducer
 */

const initialState = {
  loading: false,
  tableData: {
    content: [],
    totalElements: 0
  },
  heirTableData: {
    content: [],
    totalElements: 0
  },
  loadingHeirTable: false,
  loadingChild: null
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.payload;
  },
  [TABLE_DATA]: (state, action) => {
    const filter = action.filter || {};
    if (filter.pageNumber && filter.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...action.payload.content];
    } else {
      state.tableData = action.payload;
    }
  },
  [SET_CHILD]: (state, action) => {
    state.tableData.content = action.payload;
  },
  [LOADING_CHILD]: (state, action) => {
    state.loadingChild = action.payload;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Actions
 */

export const loadTableData = filter => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING, payload: true });
    let metadataKey = getState()[serviceRequestModule].meta.id;
    metadataKey = metadataKey.replace('request_form_', '').replace('_short', '');
    if (!filter.hasOwnProperty('extraFilters')) {
      filter['extraFilters'] = {};
    } else {
      filter['extraFilters'] = JSON.parse(filter['extraFilters']);
    }
    const customFilters = {
      ...filter,
      ...filter['extraFilters']
    };
    if (!customFilters.hasOwnProperty('locationCode')) {
      return;
    }
    delete customFilters['extraFilters'];
    let filterString = Object.keys(customFilters).map(key => {
      let value = customFilters[key];
      return `${key}=${value || ''}`;
    }).join('&');
    filterString = `${filterString}&serviceType=${metadataKey}`;
    let { data } = await ServiceProvidersApi.loadTableData(filterString);

    if (data.content) {
      const content = [];
      for (let row of data.content) {
        const item = {};
        for (let r of row.passportValues) {
          item[r.code] = r.value;
        }
        content.push(item);
      }

      dispatch({
        type: TABLE_DATA, payload: { ...data, content }, filter
      });
    } else {
      handleError(data, i18next.t('serviceProviders_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING, payload: false });
  }
};

export const loadChild = parentId => async (dispatch, getState) => {
  try {
    const setSubProviders = (tableData, data) => {
      let result = [];
      for (let item of tableData) {
        if (item.id === parentId) {
          result.push({ ...item, subServiceProviders: data.result.content });
        } else {
          if (item.subServiceProviders) {
            const subServiceProviders = setSubProviders(item.subServiceProviders, data);
            let newItem = { ...item, subServiceProviders: subServiceProviders };
            result.push(newItem);
          } else {
            result.push(item);
          }
        }
      }
      return result;
    };

    dispatch({ type: LOADING_CHILD, payload: parentId });
    const { tableData } = getState()[serviceProvidersModule];
    let { data } = await ServiceProvidersApi.loadTableData(
      JSON.stringify({ parentServiceProviderId: parentId })
    );
    if (data.status === 'SUCCESS') {
      let newData = setSubProviders(tableData.content, data);
      dispatch({
        type: SET_CHILD,
        payload: newData
      });
    } else {
      handleError(data.result.content, i18next.t('serviceProviders_errorLoadTable'));
    }
  } catch (error) {
    handleError(error, i18next.t('serviceProviders_errorLoadTable'));
  } finally {
    dispatch({ type: LOADING_CHILD, payload: null });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });
