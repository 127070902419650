import React, { useState } from 'react';
import i18next from 'i18next';
import capitalize from '@material-ui/core/utils/capitalize';

import Modal from '../../../_ui/Modal/Modal';
import Select from '../../../modules/Select/Select';
import Button from '../../../modules/Button/Button';
import Dict from '../../../modules/utils/Dict';
import { changeFormValue } from '../../../passport/Passport';

export async function changeOrgnFields({ edu, ...props }) {
  switch (props.code) {
    case 'request_form_duplic_edu_short.SchoolBin':
      changeFormValue(`request_form_duplic_edu_short.SchoolBin`, edu['schoolBin']);
      changeFormValue(`request_form_duplic_edu_short.schoolNameKk`, edu['schoolNameKk']);
      changeFormValue(`request_form_duplic_edu_short.schoolNameRu`, edu['schoolNameRu']);
      if (['0', '4', '9'].includes(props.docType)) {
        const p = edu.passportValues.find(q => q.code === 'Parallel');
        const da = edu.passportValues.find(q => q.code === 'DateOfArrival');
        const dd = edu.passportValues.find(q => q.code === 'DateOfDisposal');
        const cn = edu.passportValues.find(q => q.code === 'CertificateNumber');
        changeFormValue(`request_form_duplic_edu_short.Cource_Class`, p ? p.value.value : '');
        changeFormValue(`request_form_duplic_edu_short.DateOfArrival`, da ? da.value : '');
        changeFormValue(`request_form_duplic_edu_short.DateOfDisposal`, dd ? dd.value : '');
        changeFormValue(`request_form_duplic_edu_short.serial_num_att`, cn ? cn.value : '');
      } else if (['1', '5', '7'].includes(props.docType)) {
        const sc = edu.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
        const ds = edu.passportValues.find(q => q.code === 'DiplomaSeriesandNumber');
        const fy = edu.passportValues.find(q => q.code === 'DateOfDisposal');
        const spec = sc && await Dict.item('gu_spec_duplic', sc.value.value);
        changeFormValue(`request_form_duplic_edu_short.SpecialtyAndClassifier`, (sc && spec.code) ? sc.value.value : '-1');
        changeFormValue(`request_form_duplic_edu_short.DiplomaSeries`, ds ? ds.value : '');
        changeFormValue(`request_form_duplic_edu_short.diplomaNumber`, ds ? ds.value : '');
        changeFormValue(`request_form_duplic_edu_short.finishYear`, fy ? fy.value : '');
      } else if (['2', '6', '8'].includes(props.docType)) {
        const sc = edu.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
        const ds = edu.passportValues.find(q => q.code === 'DiplomaSeries');
        const dn = edu.passportValues.find(q => q.code === 'DiplomaNumber');
        const adc = edu.passportValues.find(q => q.code === 'EducationCategory');
        const fy = edu.passportValues.find(q => q.code === 'DateOfDisposal');
        const spec = sc && await Dict.item('gu_spec_duplic', sc.value.value);
        changeFormValue(`request_form_duplic_edu_short.SpecialtyAndClassifier`, (sc && spec.code) ? sc.value.value : '-1');
        changeFormValue(`request_form_duplic_edu_short.DiplomaSeries`, ds ? ds.value : '');
        changeFormValue(`request_form_duplic_edu_short.diplomaNumber`, dn ? dn.value : '');
        changeFormValue(`request_form_duplic_edu_short.academicDegreeCode`, adc ? adc.value : '');
        changeFormValue(`request_form_duplic_edu_short.finishYear`, fy ? fy.value : '');
      }
      break;
    case 'request_form_organization_type_short.country_of_university':
      const name = edu[`schoolName${capitalize(i18next.language)}`];
      const location = edu[`locationCode`];
      const sc = edu.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
      const dr = edu.passportValues.find(q => q.code === 'DisposalReason');
      const cc = edu.passportValues.find(q => q.code === 'Cource/Class');
      const dd = edu.passportValues.find(q => q.code === 'DateOfDisposal');
      const da = edu.passportValues.find(q => q.code === 'DateOfArrival');
      const l = edu.passportValues.find(q => q.code === 'Language');
      const sf = edu.passportValues.find(q => q.code === 'StudyForm');

      changeFormValue(`request_form_organization_type_short.School_Name`, name);
      changeFormValue(`request_form_organization_type_short.location_university`, location);
      changeFormValue(`request_form_organization_type_short.Specialty_classifier`, sc ? sc.value.value : '');
      changeFormValue(`request_form_organization_type_short.Course_numb`, cc ? cc.value.value : '');
      changeFormValue(`request_form_organization_type_short.lang_instruc_cerf`, l ? l.value.value : '');
      changeFormValue(`request_form_organization_type_short.Form_train_school`, sf ? sf.value.value : '');
      changeFormValue(`request_form_organization_type_short.reg_date_school`, da ? da.value : '');
      if (props.applicType === '656') {
        changeFormValue(`request_form_organization_type_short.graduate_date`, dd ? dd.value : '');
        changeFormValue(`request_form_organization_type_short.reason_disposal`, dr ? dr.value.value : '');
      }
      break;
    default:
      break;
  }
}

function ChooseOrgn({ isOpen, close, data }) {
  const [value, setValue] = useState(null);

  const submitHandler = () => {
    const edu = data.organizations.find(item => item['studentId'] === value);
    changeOrgnFields({ edu, ...data });
    close();
  };

  let options = [];
  if (data) {
    options = data.organizations.map(item => ({
      ru_name: item['schoolNameRu'],
      kk_name: item['schoolNameKk'],
      code: item['studentId']
    }));
  }

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Выберите один из вариантов"
      children={
        <div className="p3">
          <Select
            withoutForm
            label="Наименование организации образования"
            options={options}
            onChange={({ target }) => setValue(target.value)}
            value={value}
          />
          <div className="right-align mt2">
            <Button
              text="ОК"
              disabled={!value}
              onClick={submitHandler}
            />
          </div>
        </div>
      }
    />
  );
}

export default ChooseOrgn;
