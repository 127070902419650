import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import useSimpleModal from '../../../components/_hooks/useSimpleModal';
import ChooseNCT from './ChooseNCT';

async function changeNCT({ code, t, setLoading, requesterIin, nctModal }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, graduate, eduForm;

    switch (code) {
      case 'request_form_university_grant_short.Individual_test_code':
        category = window.PassportForm.values['request_form_university_grant_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_university_grant_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН поступающего');
          }
        }
        graduate = window.PassportForm.values['request_form_university_grant_short.choice_graduate_N'];
        if (!graduate) {
          return Notice.info('Необходимо заполнить показатель "Выпускник учебного заведения"');
        }
        eduForm = window.PassportForm.values['request_form_university_grant_short.form_EDU'];
        if (!eduForm) {
          return Notice.info('Необходимо выбрать форму обучения');
        }
        response = await ServiceRequestApi.getNCT(iin);
        if (response.status === 200) {
          const student = response.data.content[0];
          if (!student) {
            Notice.info(t('dataNotFound'));
          } else {
            const entInfo = student['passportValues'].find(v => v.code === 'entinfo');
            if (entInfo) {
              let ent;
              if (
                ['001', '002', '003'].includes(graduate) &&
                eduForm === '1'
              ) {
                ent = entInfo.value.filter(e =>
                  (
                    e['entinfo_period'].value === '3' &&
                    e['entinfo_type'].value === '1' &&
                    e['is_change_cnt_subj'].value === '0'
                  ) || (
                    e['entinfo_period'].value === '3' &&
                    e['entinfo_type'].value === '1' &&
                    e['is_change_cnt_subj'].value === '1'
                  )
                );
              }
              if (ent) {
                return nctModal.open({
                  ent,
                  passportValues: student['passportValues'],
                  serviceCode: code.split('.')[0]
                });
              }

              if ('003' === graduate && eduForm === '2') {
                ent = entInfo.value.filter(e =>
                  (
                    e['entinfo_period'].value === '3' &&
                    e['entinfo_type'].value === '2' &&
                    e['is_change_cnt_subj'].value === '0'
                  ) || (
                    e['entinfo_period'].value === '3' &&
                    e['entinfo_type'].value === '2' &&
                    e['is_change_cnt_subj'].value === '1'
                  )
                );
              }
              if (ent) {
                return nctModal.open({
                  ent,
                  passportValues: student['passportValues'],
                  serviceCode: code.split('.')[0]
                });
              }
            }
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_university_admission_short.Individual_test_code':
        category = window.PassportForm.values['request_form_university_admission_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_university_admission_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН поступающего');
          }
        }
        graduate = window.PassportForm.values['request_form_university_admission_short.choice_graduate_N'];
        if (!graduate) {
          return Notice.info('Необходимо заполнить показатель "Выпускник учебного заведения"');
        }
        eduForm = window.PassportForm.values['request_form_university_admission_short.form_EDU'];
        if (!eduForm) {
          return Notice.info('Необходимо выбрать форму обучения');
        }
        response = await ServiceRequestApi.getNCT(iin);
        if (response.status === 200) {
          const student = response.data.content[0];
          if (!student) {
            Notice.info(t('nctNotFound'));
          } else {
            const entInfo = student['passportValues'].find(v => v.code === 'entinfo');
            if (entInfo) {
              let ent;
              if (
                ['001', '002', '003'].includes(graduate) &&
                eduForm === '1'
              ) {
                ent = entInfo.value.filter(e =>
                  (
                    e['entinfo_type'].value === '1' &&
                    e['is_change_cnt_subj'].value === '0'
                  ) || (
                    e['entinfo_type'].value === '1' &&
                    e['is_change_cnt_subj'].value === '1'
                  )
                );
              }
              if (ent) {
                return nctModal.open({
                  ent,
                  passportValues: student['passportValues'],
                  serviceCode: code.split('.')[0]
                });
              }

              if ('003' === graduate && eduForm === '2') {
                ent = entInfo.value.filter(e =>
                  (
                    e['entinfo_type'].value === '2' &&
                    e['is_change_cnt_subj'].value === '0'
                  ) || (
                    e['entinfo_type'].value === '2' &&
                    e['is_change_cnt_subj'].value === '1'
                  )
                );
              }
              if (ent) {
                return nctModal.open({
                  ent,
                  passportValues: student['passportValues'],
                  serviceCode: code.split('.')[0]
                });
              }

              return Notice.info(t('nctNotFound'));
            }
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      default:
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function NCT({ code, requesterIin }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const nctModal = useSimpleModal();

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeNCT({
          t,
          code,
          setLoading,
          requesterIin,
          nctModal
        })}
      />
      <ChooseNCT {...nctModal}/>
    </div>
  );
}

export default NCT;