import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    header_help: 'Помощь',
    header_register: 'Регистрация',
    header_login: 'Войти',
    header_lang_ru: 'Русский',
    header_lang_kk: 'Қазақша',
    header_myProfile: 'Личный кабинет',
    header_getEDS: 'Получить ЭЦП',
    header_exit: 'Выйти',
    header_support: 'Служба поддержки',
    header_faq: 'Часто задаваемые вопросы',
    header_instruction: 'Инструкция пользователя',
  },
  kk: {
    header_help: 'Көмек',
    header_register: 'Регистрация',
    header_login: 'Кіру',
    header_lang_ru: 'Русский',
    header_lang_kk: 'Қазақша',
    header_myProfile: 'Жеке кабинет',
    header_getEDS: 'ЭЦҚ алу',
    header_exit: 'Шығу',
    header_support: 'Қолдау қызметі',
    header_faq: 'Жиі қойылатын сұрақтар',
    header_instruction: 'Пайдаланушы нұсқаулығы',
  }
});
