import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Button from '../../../modules/Button/Button';
import { services } from '../../../_helpers/Constants';
import { changeQuestionGopFactSys, changeQuestionSerpin, isLowResult } from './checkBeforeSubmit';
import Notice from '../../../modules/utils/Notice';
import { getFormValues } from '../../../passport/Passport';
import { SaveButton } from '../ServiceRequestStyles';
import { serviceRequestModule } from '../ServiceRequestDucks';

function SubmitButton(
  {
    isDisabled,
    spBin,
    serviceProvider,
    metadataKey,
    user,
    onSave
  }
) {
  const { t, i18n } = useTranslation();
  const { loadingSave } = useSelector(state => state[serviceRequestModule]);
  const [loading, setLoading] = useState(false);

  if (isDisabled) return null;

  return (
    <SaveButton>
      <Button
        text={t('sign')}
        loading={loading || loadingSave}
        onClick={async () => {
          try {
            setLoading(true);
            if (spBin || serviceProvider) {
              if (services.university_grant_short.code === metadataKey) {
                const canSubmit = await changeQuestionGopFactSys({
                  t,
                  lang: i18n.language,
                  requesterIin: user.iin
                });
                if (!canSubmit) return;
                changeQuestionSerpin();
              }
              if (
                [
                  services.university_grant_short.code,
                  services.university_admission_short.code
                ].includes(metadataKey)
              ) {
                const test = await isLowResult({ metadataKey });
                if (test.isLow) {
                  return Notice.warning(
                    t('serviceRequest_lowTestResult', {
                      testName: test[`${i18n.language}_name`],
                      testResult: test.testResult
                    })
                  );
                }
              }
              const serviceProviderIinBin =
                spBin || serviceProvider.bin;
              if (
                serviceProviderIinBin &&
                typeof serviceProviderIinBin !== 'string'
              ) {
                return Notice.info('Не указан БИН услугодателя');
              }
              const changes = await getFormValues();
              if (changes && changes[metadataKey]) {
                onSave({
                  serviceType: metadataKey.replace('request_form_', ''),
                  serviceProviderIinBin,
                  requesterIinBin: user.iin,
                  requestFormChanges: changes[metadataKey]
                });
              }
            } else {
              const element = document.getElementsByClassName(
                `service-provider`
              )[0];
              element.classList.add('shake');
              element.classList.add('animated');
              element.scrollIntoView({
                behavior: 'smooth',
                block: 'center',
                inline: 'center'
              });
              setTimeout(() => {
                element.classList.remove('shake');
                element.classList.remove('animated');
              }, 2000);
            }
          } catch (e) {
            // ignore
          } finally {
            setLoading(false);
          }
        }}
      />
    </SaveButton>
  );
}

export default SubmitButton;