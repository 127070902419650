import React from 'react';
import { connect } from 'react-redux';
import Button from '@material-ui/core/Button';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import _ from 'lodash';

import withTranslation from '../../components/_hoc/withTranslation';
import { logout } from '../../pages/LoginPage/LoginDucks';
import { TokenRefresherApi } from './TokenRefresherApi';
import Modal from '../../_ui/Modal/Modal';
import { handleError } from '../utils/handleError';
import './TokenRefresherTranslate';
import jwt_decode from 'jwt-decode';

const timerDuration = 600000;

class TokenRefresher extends React.Component {
  state = {
    diff: timerDuration,
    userActive: true,
    showBtnTimer: 0,
    expiredAt: null
  };

  componentDidMount() {
    let expiredAt = localStorage.getItem('expiredAt');
    if (expiredAt && expiredAt > Date.now() + 10000) {
      window.addEventListener('mousemove', this.moveListener);
      window.addEventListener('keypress', this.moveListener);
      let diff = expiredAt - Date.now();
      this.setState({ diff, expiredAt });
      this.interval = setInterval(this.checkToken, 1000);
      this.expiredInterval = setInterval(this.checkExpiredAt, 5000);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('mousemove', this.moveListener);
    window.removeEventListener('keypress', this.moveListener);
    clearInterval(this.interval);
    clearInterval(this.expiredInterval);
  }

  checkExpiredAt = () => {
    let expiredAt = localStorage.getItem('expiredAt');
    if (expiredAt && expiredAt !== this.state.expiredAt) {
      let diff = expiredAt - Date.now();
      this.setState({ diff, expiredAt });
    }
  };

  moveListener = () => {
    const { diff, userActive } = this.state;
    if (diff > 60000 && !userActive) {
      this.setState({ userActive: true });
    }
  };

  checkToken = () => {
    const { diff, userActive, showBtnTimer } = this.state;
    if (diff <= 500) {
      this.props.logout();
    } else if (diff <= 60000 && (userActive || window.test || localStorage.getItem('activeSession'))) {
      this.refreshToken();
    }

    if (showBtnTimer > 0) {
      this.setState({ showBtnTimer: showBtnTimer - 1 });
    }

    if (diff > 500) {
      this.setState({ diff: diff - 1000 });
    }
  };

  refreshToken = async () => {
    const { t, logout } = this.props;
    const refreshToken = localStorage.getItem('refreshToken');
    clearInterval(this.interval);
    try {
      if (refreshToken) {
        const response = await TokenRefresherApi.refreshUser(refreshToken);
        if (response.data.status === 'SUCCESS') {
          let decodedToken = jwt_decode(response.data.accessToken);
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('expiredAt', decodedToken.exp*1000 - 180000);
          this.setState({ diff: timerDuration, userActive: false });
          this.interval = setInterval(this.checkToken, 1000);
        }
      }
    } catch (error) {
      const status = _.get(error, 'response.data.status');
      if (status === 'FAIL') {
        handleError(error, t('tokenRefresher_refreshFailed'));
      } else if (status === 'INVALID_TOKEN') {
        handleError(error, t('tokenRefresher_invalidToken'));
      }
      logout();
    }
  };

  render() {
    const { diff, userActive } = this.state;
    const { t } = this.props;
    if (diff <= 60000 && !userActive) {
      return (
        <Modal open title={t('tokenRefresher_title')}>
          <DialogContent>
            <div style={{ fontSize: '18px' }}>
              {t('tokenRefresher_expiredAt')} {Math.round(diff / 1000)}
              {t('tokenRefresher_second')}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.refreshToken}>{t('tokenRefresher_stay')}</Button>
          </DialogActions>
        </Modal>
      );
    }

    return null;
  }
}

export default connect(
  null,
  { logout }
)(withTranslation(TokenRefresher));
