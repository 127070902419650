import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';
import uuid from 'uuid/v4';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeComplexValue } from '../../../passport/Passport';

async function changeCollege({ code, questionCode, complexCode, t, setLoading, lang, requesterIin }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, colleges = [], release;

    switch (`${code.split('.')[1]}.${questionCode}`) {
      case 'tipo.country_tipo':
        category = window.PassportForm.values['request_form_college_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_college_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН абитуриента');
          }
        }
        response = await ServiceRequestApi.getColleges(iin);
        if (response.status === 200) {
          colleges = response.data.content.filter(s => s['status'] === 2);
          if (!colleges.length) {
            return Notice.info(t('gbdflButton_collegeNotFound'));
          } else {
            let complexData = {};
            for (let i = 0; i < colleges.length; i++) {
              const complexKey = i === 0 ? complexCode : uuid();
              const name = colleges[i][`schoolName${capitalize(lang)}`];
              const sc = colleges[i].passportValues.find(q => q.code === 'SpecialtyAndClassifier');
              const sf = colleges[i].passportValues.find(q => q.code === 'StudyForm');
              const cc = colleges[i].passportValues.find(q => q.code === 'Cource/Class');
              const l = colleges[i].passportValues.find(q => q.code === 'Language');
              const da = colleges[i].passportValues.find(q => q.code === 'DateOfArrival');
              const dd = colleges[i].passportValues.find(q => q.code === 'DateOfDisposal');
              const dsn = colleges[i].passportValues.find(q => q.code === 'DiplomaSeriesandNumber');
              complexData[complexKey] = {
                'country_tipo': '47',
                'tipo_name': name,
                'tipo_specialty': sc ? sc.value.value : '',
                'tipo_form': sf ? sf.value.value : '',
                'tipo_course': cc ? cc.value.value : '',
                'tipo_language': l ? l.value.value : '',
                'tipo_datearrival': da ? da.value : '',
                'tipo_series': dsn ? dsn.value : '',
                'tipo_datedisposal': dd ? dd.value : ''
              };
            }
            changeComplexValue(code, complexData);
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'TIPO_edu.locality_EDU':
        release = window.PassportForm.values['request_form_university_admission_short.releases_abroad_EDU'];
        if (release === false) {
          category = window.PassportForm.values['request_form_university_admission_short.user_category'];
          if (!category) {
            return Notice.info('Сначала выберите категорию заявителя');
          }
          iin = '';
          if (category === '003') {
            iin = requesterIin;
          } else {
            iin = window.PassportForm.values['request_form_university_admission_short.Child_iin'];
            if (!iin) {
              return Notice.info('Необходимо указать ИИН поступающего');
            }
          }

          response = await ServiceRequestApi.getColleges(iin);
          if (response.status === 200) {
            colleges = response.data.content.filter(s => [0, 2].includes(s['status']));

            if (!colleges.length) {
              return Notice.info(t('gbdflButton_collegeNotFound_2'));
            } else {
              let complexData = {};
              for (let i = 0; i < colleges.length; i++) {
                const complexKey = i === 0 ? complexCode : uuid();
                const name = colleges[i][`schoolName${capitalize(lang)}`];
                const location = colleges[i][`locationCode`];
                const sc = colleges[i].passportValues.find(q => q.code === 'SpecialtyAndClassifier');
                const dsn = colleges[i].passportValues.find(q => q.code === 'DiplomaSeriesandNumber');
                const cc = colleges[i].passportValues.find(q => q.code === 'Cource/Class');
                const dt = colleges[i].passportValues.find(q => q.code === 'DiplomaType');
                const dd = colleges[i].passportValues.find(q => q.code === 'DateOfDisposal');
                const da = colleges[i].passportValues.find(q => q.code === 'DateOfArrival');
                let honor = null;
                if (dt && ['1', '2'].includes(dt.value.value)) {
                  honor = dt.value.value === '1';
                }
                complexData[complexKey] = {
                  'locality_EDU': location,
                  'EDU_Name': name,
                  'EDU_course': cc ? cc.value.value : '',
                  'EDU_date_finish': dd ? dd.value : '',
                  'EDU_doc_date': da ? da.value : '',
                  'EDU_specialty_N': sc ? sc.value.value : '',
                  'EDU_doc_series': dsn ? dsn.value : '',
                  'diploma_EDU_honors': honor
                };
              }
              changeComplexValue(code, complexData);
            }
          } else {
            handleError(response, t('dataNotFound'));
          }
        } else {
          Notice.info('Запрос данных работает только для выпускников учебного заведения внутри страны Казахстан');
        }
        break;
      default:
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function ComplexCollege({ code, value, questionCode, complexCode, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  if (
    ['country_tipo'].includes(questionCode) &&
    (!value || value.code !== '47')
  ) {
    return null;
  }

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeCollege({
          t,
          code,
          complexCode,
          setLoading,
          requesterIin,
          questionCode,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default ComplexCollege;