import moment from 'moment';

export default class FlcDate {
  momentValue = null;
  value = null;
  constructor(value) {
    if (value === undefined) {
      value = moment()
        .hours(0)
        .minutes(0)
        .seconds(0)
        .milliseconds(0);
    }
    if (value) {
      if (value instanceof moment) {
        this.momentValue = value;
        this.value = value.format('YYYY-MM-DDTHH:mm:ss');
      } else {
        this.momentValue = moment(value);
        this.value = moment(value).format('YYYY-MM-DDTHH:mm:ss');
      }
    }
  }
  day(day) {
    if (this.momentValue === null) return null;

    if (day) {
      const mValue = this.momentValue.date(day);
      return new FlcDate(mValue);
    } else {
      return this.momentValue.date();
    }
  }
  year(year) {
    if (this.momentValue === null) return null;

    if (year) {
      const mValue = this.momentValue.year(year);
      return new FlcDate(mValue);
    } else {
      return this.momentValue.year();
    }
  }
  month(month) {
    if (this.momentValue === null) return null;

    if (month) {
      const mValue = this.momentValue.month(month);
      return new FlcDate(mValue);
    } else {
      return this.momentValue.month();
    }
  }
  addDay = number => {
    if (this.momentValue === null) return null;
    const mValue = this.momentValue.add(number, 'd');
    return new FlcDate(mValue);
  };
  diffYear = date => {
    if (!date) date = {};
    if (this.momentValue === null) return NaN;
    return this.momentValue.diff(date.momentValue, 'year');
  };
  diffDay = date => {
    if (!date) date = {};
    if (this.momentValue === null) return NaN;
    return this.momentValue.diff(date.momentValue, 'day');
  };
  isBefore = date => {
    if (!date) date = {};
    if (this.momentValue === null) return false;
    return this.momentValue.isBefore(date.momentValue);
  };
  isAfter = date => {
    if (!date) date = {};
    if (this.momentValue === null) return false;
    return this.momentValue.isAfter(date.momentValue);
  };
  isBetween = (date1, date2) => {
    if (!date1) date1 = {};
    if (!date2) date2 = {};
    if (this.momentValue === null) return false;
    return this.momentValue.isBetween(date1.momentValue, date2.momentValue);
  };
}
