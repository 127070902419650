import { createReducer } from 'redux-starter-kit';
import { OrganizationsApi } from './OrganizationsApi';
import { handleError } from '../../../modules/utils/handleError';
import { translation } from '../../../_helpers/Translate';

export const organizationsModule = 'organizations';
const LOADING = `${organizationsModule}/LOADING`;
const SET_DATA = `${organizationsModule}/SET_DATA`;
const CLEAR_STATE = `${organizationsModule}/CLEAR_STATE`;

const initialState = {
  tableData: {
    content: [],
    totalElements: 0
  },
  loading: false
};

export default createReducer(initialState, {
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [SET_DATA]: (state, action) => {
    const { payload } = action;
    if (payload.pageNumber !== 1) {
      state.tableData.content = [...state.tableData.content, ...payload.content];
    } else {
      state.tableData = payload;
    }
  },
  [CLEAR_STATE]: () => initialState
});

export const loadOrganizations = (params, code) => async dispatch => {
  dispatch({ type: LOADING, loading: true });
  try {
    let serviceProviderType = '';
    if (code.questionCode === 'name_of_university') {
      serviceProviderType = '06';
    } else {
      serviceProviderType = '02';
    }
    const filter = `serviceProviderType=${serviceProviderType}`
      + `&locationCode=${params.location || ''}`
      + `&pageNumber=${params.pageNumber}`
      + `&pageSize=20`;

    const response = await OrganizationsApi.loadOrganization(filter);
    if (response.status === 200) {
      const content = [];
      for (let row of response.data.content) {
        const item = {};
        for (let r of row.passportValues) {
          item[r.code] = r.value;
        }
        content.push(item);
      }
      dispatch({ type: SET_DATA, payload: { ...response.data, content } });
    } else {
      handleError(response, translation('oDucks_loadDataFailed'));
    }
  } catch (e) {
    handleError(e, translation('oDucks_loadDataFailed'));
  } finally {
    dispatch({ type: LOADING, loading: false });
  }
};

export const clearState = () => ({ type: CLEAR_STATE });