import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import Notice from '../../../modules/utils/Notice';
import { handleError } from '../../../modules/utils/handleError';
import { changeComplexValue } from '../../../passport/Passport';
import { ServiceRequestApi } from '../ServiceRequestApi';

async function requestGBDFL({ code, setLoading, t, complexCode, questionCode, value, setPrevIin }) {
  try {
    setLoading(true);
    const { data } = await ServiceRequestApi.searchGbdfl(value);
    const user = data.result;
    let source = '', docInfo = {}, passport = {};
    switch (questionCode) {
      case 'fostercare_child_iin':
      case 'family_IIN':
        let obj = {};
        if (questionCode === 'family_IIN') {
          obj = {
            'family_country': user['regAddressCountryCode'],
            'family_area': user['regAddressDistrictsCode'],
            'family_region': user['regAddressRegionCode'],
            'family_city': user['regAddressCity'],
            'family_street': user['regAddressStreet'],
            'family_house': user['regAddressBuilding'],
            'family_corpus_number': user['regAddressCorpus'],
            'family_flat': user['regAddressFlat']
          }
        }
        source = questionCode.toLowerCase().replace('_iin', '');
        changeComplexValue(code, {
          ...window.PassportForm.cellsValues[code],
          [complexCode]: {
            [questionCode]: value,
            [`${source}_surname`]: user['lastName'],
            [`${source}_name`]: user['firstName'],
            [`${source}_middlename`]: user['middleName'],
            [`${source}_birthday`]: user['birthDate'],
            ...obj
          }
        });
        break;
      case 'guardianship_child_iin':
        source = questionCode.replace('_iin', '');
        changeComplexValue(code, {
          ...window.PassportForm.cellsValues[code],
          [complexCode]: {
            [questionCode]: value,
            [`${source}_surname`]: user['lastName'],
            [`${source}_name`]: user['firstName'],
            [`${source}_middlename`]: user['middleName'],
            [`${source}_birthday`]: user['birthDate'],
            [`${source}_registry_name`]: user['birthCertificateIssueOrganization'],
            [`${source}_registry_name_kk`]: user['birthCertificateIssueOrganization'],
            [`${source}_birthdoc_number`]: user['birthCertificateNumber'],
            [`${source}_birthdoc_date`]: user['birthDate'],
            [`${source}_birthdoc_doc_date`]: user['birthCertificateBeginDate']
          }
        });
        break;
      case 'spouse_iin':
        source = questionCode.toLowerCase().replace('_iin', '');
        passport = user['personalDocuments'].find(doc => doc['typeCode'] === '002' && doc['statusCode'] === '00');
        if (!passport) {
          passport = user['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
        }
        if (!passport) {
          passport = user['personalDocuments'][0];
        }
        if (passport) {
          docInfo = {
            [`doc_type_wife`]: passport['typeCode'],
            [`doc_number_wife`]: passport['number'],
            [`doc_organ_rname_wifer`]: passport['issueOrganizationCode'],
            [`doc_issuedate_wife`]: passport['beginDate'],
            [`doc_expirationdate_wife`]: passport['endDate']
          };
        }
        changeComplexValue(code, {
          ...window.PassportForm.cellsValues[code],
          [complexCode]: {
            [questionCode]: value,
            [`${source}_surname`]: user['lastName'],
            [`${source}_name`]: user['firstName'],
            [`${source}_middlename`]: user['middleName'],
            [`${source}_birthday`]: user['birthDate'],
            ...docInfo
          }
        });
        break;
      case 'iin_pledger':
        source = questionCode.replace('iin_', '');
        passport = user['personalDocuments'].find(doc => doc['typeCode'] === '002' && doc['statusCode'] === '00');
        if (!passport) {
          passport = user['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
        }
        if (!passport) {
          passport = user['personalDocuments'][0];
        }
        if (passport) {
          docInfo = {
            [`documentType_pledger`]: passport['typeCode'],
            [`number_pledger`]: passport['number'],
            [`organ_name_pledger`]: passport['issueOrganizationCode'],
            [`date_issue_pledger`]: passport['beginDate'],
            [`expirationdate_pledger`]: passport['endDate']
          };
        }
        changeComplexValue(code, {
          ...window.PassportForm.cellsValues[code],
          [complexCode]: {
            [questionCode]: value,
            [`surname_${source}`]: user['lastName'],
            [`name_${source}`]: user['firstName'],
            [`patronymic_${source}`]: user['middleName'],
            [`dob_${source}`]: user['birthDate'],
            ...docInfo
          }
        });
        break;
      case 'inn_guarantor':
        source = questionCode.replace('inn_', '');
        changeComplexValue(code, {
          ...window.PassportForm.cellsValues[code],
          [complexCode]: {
            [questionCode]: value,
            [`surname_${source}`]: user['lastName'],
            [`name_${source}`]: user['firstName'],
            [`patronymic_${source}`]: user['middleName'],
            [`bithday_${source}`]: user['birthDate']
          }
        });
        break;
      case 'child_iin':
        if (user['personStatusCode'] === '2') {
          source = questionCode.replace('_iin', '');
          changeComplexValue(code, {
            ...window.PassportForm.cellsValues[code],
            [complexCode]: {
              [questionCode]: value,
              [`${source}_surname`]: user['lastName'],
              [`${source}_name`]: user['firstName'],
              [`${source}_middlename`]: user['middleName'],
              [`${source}_birthday`]: user['birthDate'],
              [`${source}_registry_name`]: user['birthCertificateIssueOrganization'],
              [`${source}_registry_name_kz`]: user['birthCertificateIssueOrganization'],
              [`${source}_birthdoc_number`]: user['birthCertificateNumber'],
              [`${source}_birthdoc_date`]: user['birthDate'],
              [`${source}_birthdoc_doc_date`]: user['birthCertificateBeginDate']
            }
          });
          setPrevIin(value);
        } else {
          Notice.error(t('gbdflButton_gbdfl_person_not_found'));
        }
        break;
      default:
        break;
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.gbdfl_person_not_found') {
      const metadata = code.split('.')[0];
      if (
        [
          'request_form_realestate_bolashak_short',
          'request_form_doc_queued_ddo_short'
        ].includes(metadata)
      ) {
        Notice.error(t('gbdflButton_iinNotFound'));
      } else {
        Notice.error(t('gbdflButton_gbdfl_person_not_found'));
      }
    } else {
      handleError(error, t('gbdflButton_searchFailed'));
    }
  } finally {
    setLoading(false);
  }
}

function ComplexGBDFLButton({ code, value, complexCode, questionCode }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [prevIin, setPrevIin] = useState(null);

  useEffect(() => {
    if (prevIin !== value) {
      switch (questionCode) {
        case 'child_iin':
          const cn = window.PassportForm.cellsValues[code][complexCode]['child_name'];
          if (cn) {
            changeComplexValue(code, {
              ...window.PassportForm.cellsValues[code],
              [complexCode]: {
                [questionCode]: value,
                [`child_surname`]: '',
                [`child_name`]: '',
                [`child_middlename`]: '',
                [`child_birthday`]: '',
                [`child_registry_name`]: '',
                [`child_registry_name_kz`]: '',
                [`child_birthdoc_number`]: '',
                [`child_birthdoc_date`]: '',
                [`child_birthdoc_doc_date`]: ''
              }
            });
          }
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        disabled={value.length !== 12}
        text={t('gbdflButton_search')}
        onClick={() => requestGBDFL({
          t,
          code,
          value,
          setLoading,
          complexCode,
          questionCode,
          setPrevIin
        })}
      />
    </div>
  );
}

export default ComplexGBDFLButton;
