import React, { useState } from 'react';
import uuid from 'uuid/v4';
import moment from 'moment';

import Modal from '../../../_ui/Modal/Modal';
import Select from '../../../modules/Select/Select';
import Button from '../../../modules/Button/Button';
import {
  changeComplexValue,
  changeFormValue
} from '../../../passport/Passport';
import { services } from '../../../_helpers/Constants';
import Notice from '../../../modules/utils/Notice';
import { useTranslation } from 'react-i18next';

let isAllFilled = true;

function changeFormValueWithCheck(questionCode, questionValue) {
  if (!questionValue && questionValue !== false) {
    isAllFilled = false;
  }
  changeFormValue(questionCode, questionValue);
}

function ChooseNCT({ isOpen, close, data }) {
  const [value, setValue] = useState(null);
  const { t } = useTranslation();
  let serviceCode = '';

  let options = [];
  if (data) {
    options = data.ent.map(e => ({
      ...e,
      ru_name: `${e['entinfo_individual_code']} - ${moment(e['entinfo_date']).format('L')}`,
      kk_name: `${e['entinfo_individual_code']} - ${moment(e['entinfo_date']).format('L')}`,
      code: e['entinfo_individual_code']
    }));
    serviceCode = data.serviceCode;
  }

  function submitHandler() {
    isAllFilled = true;
    let creative, creativeExam, entType, entCreative;
    const ent = data.ent.find(e => e['entinfo_individual_code'] === value);
    changeFormValueWithCheck(`${serviceCode}.Individual_test_code`, ent['entinfo_individual_code']);
    changeFormValueWithCheck(`${serviceCode}.certificate_numb`, ent['entinfo_number']);
    changeFormValueWithCheck(`${serviceCode}.Test_Language`, ent['ent_lang'].value);
    changeFormValueWithCheck(`${serviceCode}.Test_year`, ent['entinfo_year']);
    changeFormValueWithCheck(`${serviceCode}.Test_data`, ent['entinfo_date']);
    changeFormValueWithCheck(`${serviceCode}.number_subjects_UNT`, ent['is_change_cnt_subj'].value);
    const score = data.passportValues.find(p => p['code'] === 'score');

    let variant = null;
    if (serviceCode.includes(services.university_admission_short.code)) {
      if (
        ent['entinfo_type'].value === '1' &&
        ent['is_change_cnt_subj'].value === '0'
      ) {
        variant = 0;
      } else if (
        ent['entinfo_type'].value === '1' &&
        ent['is_change_cnt_subj'].value === '1'
      ) {
        variant = 1;
      } else if (
        ent['entinfo_type'].value === '2' &&
        ent['is_change_cnt_subj'].value === '0'
      ) {
        variant = 2;
      } else if (
        ent['entinfo_type'].value === '2' &&
        ent['is_change_cnt_subj'].value === '1'
      ) {
        variant = 3;
      }
    } else {
      if (
        ent['entinfo_period'].value === '3' &&
        ent['entinfo_type'].value === '1' &&
        ent['is_change_cnt_subj'].value === '0'
      ) {
        variant = 0;
      } else if (
        ent['entinfo_period'].value === '3' &&
        ent['entinfo_type'].value === '1' &&
        ent['is_change_cnt_subj'].value === '1'
      ) {
        variant = 1;
      } else if (
        ent['entinfo_period'].value === '3' &&
        ent['entinfo_type'].value === '2' &&
        ent['is_change_cnt_subj'].value === '0'
      ) {
        variant = 2;
      } else if (
        ent['entinfo_period'].value === '3' &&
        ent['entinfo_type'].value === '2' &&
        ent['is_change_cnt_subj'].value === '1'
      ) {
        variant = 3;
      }
    }
    switch (variant) {
      case 0:
        if (score) {
          const ballHistory = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 1 &&
            s['subject_type'].value === '1' &&
            s['subject_id'].value === '1'
          );
          const ballMath = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 3 &&
            s['subject_type'].value === '3' &&
            s['subject_id'].value === '2'
          );
          const ballLiteracy = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 2 &&
            s['subject_type'].value === '2' &&
            s['subject_id'].value === '3'
          );
          const profileOne = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 4 &&
            s['subject_type'].value === '4'
          );
          const profileTwo = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 5 &&
            s['subject_type'].value === '4'
          );
          changeFormValueWithCheck(`${serviceCode}.ball_history_kz`, ballHistory ? ballHistory['total_score'] : '');
          changeFormValueWithCheck(`${serviceCode}.ball_literacy`, ballLiteracy ? ballLiteracy['total_score'] : '');
          setTimeout(() => {
            changeFormValueWithCheck(`${serviceCode}.ball_mathem`, ballMath ? ballMath['total_score'] : '');
            changeFormValueWithCheck(`${serviceCode}.name_profile_one`, profileOne ? profileOne['subject_id'].value : '');
            changeFormValueWithCheck(`${serviceCode}.ball_profile_one`, profileOne ? profileOne['total_score'] : '');
            changeFormValueWithCheck(`${serviceCode}.name_profile_two`, profileTwo ? profileTwo['subject_id'].value : '');
            changeFormValueWithCheck(`${serviceCode}.ball_profile_two`, profileTwo ? profileTwo['total_score'] : '');
          });
        }
        creative = data.passportValues.find(p => p['code'] === 'entinfo_creative' && p.value);
        if (creative) {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, true));
          creativeExam = data.passportValues.find(p => p['code'] === 'creative_exam');
          if (creativeExam) {
            const creativeOne = creativeExam.value.find(c => c['order'] === 1);
            const creativeTwo = creativeExam.value.find(c => c['order'] === 2);
            setTimeout(() => {
              changeFormValueWithCheck(`${serviceCode}.creative_name_one`, creativeOne ? creativeOne['subject_id'].value : '');
              changeFormValueWithCheck(`${serviceCode}.creative_points_one`, creativeOne ? creativeOne['ball'] : '');
              changeFormValueWithCheck(`${serviceCode}.creative_name_two`, creativeTwo ? creativeTwo['subject_id'].value : '');
              changeFormValueWithCheck(`${serviceCode}.creative_points_two`, creativeTwo ? creativeTwo['ball'] : '');
              changeFormValueWithCheck(`${serviceCode}.take_test_creative`, true);
            });
            const univerBIN = data.passportValues.find(p => p['code'] === 'university_bin');
            setTimeout(() => {
              changeFormValueWithCheck(`${serviceCode}.place_creative_exam`, univerBIN ? univerBIN.value.value : '');
            });
          }
        } else {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, false));
        }
        break;
      case 1:
        if (score) {
          const ballHistory = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 1 &&
            s['subject_type'].value === '1' &&
            s['subject_id'].value === '1'
          );
          const ballLiteracy = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 2 &&
            s['subject_type'].value === '2' &&
            s['subject_id'].value === '3'
          );
          changeFormValueWithCheck(`${serviceCode}.ball_history_kz`, ballHistory ? ballHistory['total_score'] : '');
          changeFormValueWithCheck(`${serviceCode}.ball_literacy`, ballLiteracy ? ballLiteracy['total_score'] : '');
        }
        creative = data.passportValues.find(p => p['code'] === 'entinfo_creative' && p.value);
        if (creative) {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, true));
          creativeExam = data.passportValues.find(p => p['code'] === 'creative_exam');
          if (creativeExam) {
            const creativeOne = creativeExam.value.find(c => c['order'] === 1);
            const creativeTwo = creativeExam.value.find(c => c['order'] === 2);
            setTimeout(() => {
              changeFormValueWithCheck(`${serviceCode}.creative_name_one`, creativeOne ? creativeOne['subject_id'].value : '');
              changeFormValueWithCheck(`${serviceCode}.creative_points_one`, creativeOne ? creativeOne['ball'] : '');
              changeFormValueWithCheck(`${serviceCode}.creative_name_two`, creativeTwo ? creativeTwo['subject_id'].value : '');
              changeFormValueWithCheck(`${serviceCode}.creative_points_two`, creativeTwo ? creativeTwo['ball'] : '');
              changeFormValueWithCheck(`${serviceCode}.take_test_creative`, true);
            });
            const univerBIN = data.passportValues.find(p => p['code'] === 'university_bin');
            setTimeout(() => {
              changeFormValueWithCheck(`${serviceCode}.place_creative_exam`, univerBIN ? univerBIN.value.value : '');
            });
          }
        } else {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, false));
        }
        break;
      case 2:
        if (score) {
          const professional = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 1 &&
            s['subject_type'].value === '5'
          );
          const special = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 2 &&
            s['subject_type'].value === '6'
          );
          setTimeout(() => {
            changeFormValueWithCheck(`${serviceCode}.name_professional`, professional ? professional['subject_id'].value : '');
            changeFormValueWithCheck(`${serviceCode}.ball_professional`, professional ? professional['total_score'] : '');
            changeFormValueWithCheck(`${serviceCode}.name_special`, special ? special['subject_id'].value : '');
            changeFormValueWithCheck(`${serviceCode}.ball_special`, special ? special['total_score'] : '');
          });
        }
        entType = data.passportValues.find(p => p['code'] === 'entinfo_type' && p.value.value === '2');
        entCreative = data.passportValues.find(p => p['code'] === 'entinfo_creative' && p.value);
        if (entType && entCreative) {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, true));
          creativeExam = data.passportValues.find(p => p['code'] === 'creative_exam');
          if (creativeExam && creativeExam.value.length) {
            const creative = creativeExam.value[0];
            setTimeout(() => {
              changeFormValueWithCheck(`${serviceCode}.creative_name_quick`, creative['subject_id'].value);
              changeFormValueWithCheck(`${serviceCode}.creative_points_quick`, creative['ball']);
            });
          }
        } else {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, false));
        }
        break;
      case 3:
        if (score) {
          const special = score.value.find(s =>
            s['entinfo_individual_code'] === value &&
            s['order'] === 1 &&
            s['subject_type'].value === '6'
          );
          setTimeout(() => {
            changeFormValueWithCheck(`${serviceCode}.name_special`, special ? special['subject_id'].value : '');
            changeFormValueWithCheck(`${serviceCode}.ball_special`, special ? special['total_score'] : '');
          });
        }
        entType = data.passportValues.find(p => p['code'] === 'entinfo_type' && p.value.value === '2');
        entCreative = data.passportValues.find(p => p['code'] === 'entinfo_creative' && p.value);
        if (entType && entCreative) {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, true));
          creativeExam = data.passportValues.find(p => p['code'] === 'creative_exam');
          if (creativeExam && creativeExam.value.length) {
            const creative = creativeExam.value[0];
            setTimeout(() => {
              changeFormValueWithCheck(`${serviceCode}.creative_name_quick`, creative['subject_id'].value);
              changeFormValueWithCheck(`${serviceCode}.creative_points_quick`, creative['ball']);
            });
          }
        } else {
          setTimeout(() => changeFormValueWithCheck(`${serviceCode}.Test_creative`, false));
        }
        break;
      default:
        break;
    }
    const special = data.passportValues.find(p => p['code'] === 'entinfo_special' && p.value);
    if (special) {
      changeFormValueWithCheck(`${serviceCode}.admission_exam`, true);
      const specExam = data.passportValues.find(p => p['code'] === 'spec_exam');
      if (specExam && specExam.value.length) {
        const complexData = {};
        for (let se of specExam.value) {
          const complexKey = uuid();
          complexData[complexKey] = {
            'admission_type_exam': se['exam_area'].value,
            'admission_exam_result': se['exam_result']
          };
        }
        setTimeout(() => {
          changeComplexValue(`${serviceCode}.special_exam`, complexData);
        });
      }
    } else {
      changeFormValueWithCheck(`${serviceCode}.admission_exam`, false);
    }

    const english = data.passportValues.find(p => p['code'] === 'entinfo_english' && p.value);
    if (english) {
      const englishExam = data.passportValues.find(p => p['code'] === 'english_exam');
      if (englishExam && englishExam.value.length) {
        const ee = englishExam.value[0];
        changeFormValueWithCheck(`${serviceCode}.ball_test_english`, ee['ball']);
      }
    }
    if (!isAllFilled) {
      Notice.info(t('nctNotFound'));
    }
    close();
  }

  return (
    <Modal
      open={isOpen}
      onClose={close}
      title="Выберите один из вариантов"
      children={
        <div className="p3">
          <Select
            withoutForm
            label="Индивидуальный код тестируемого"
            options={options}
            onChange={({ target }) => setValue(target.value)}
            value={value}
          />
          <div className="right-align mt2">
            <Button
              text="ОК"
              disabled={!value}
              onClick={submitHandler}
            />
          </div>
        </div>
      }
    />
  );
}

export default ChooseNCT;