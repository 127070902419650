import paths from './paths';
import Home from '../pages/Home/Home';
import ServiceRequest from '../pages/ServiceRequest/ServiceRequest';
import Account from '../pages/Account/Account';
import Requests from '../pages/Requests/Requests';
import CompletedRequests from '../pages/CompletedRequests/CompletedRequests';
import AccountSettings from '../pages/AccountSettings/AccountSettings';
import Support from '../pages/Support/Support';
import Instructions from '../pages/Instructions/Instructions';

const routerProps = {
  homePage: {
    path: paths.homePage,
    component: Home,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  createRequestPage: {
    path: paths.createRequestPage,
    component: ServiceRequest,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: false
  },
  accountPage: {
    path: paths.accountPage,
    component: Account,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  requestsPage: {
    path: paths.requestsPage,
    component: Requests,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  completedRequestsPage: {
    path: paths.completedRequestsPage,
    component: CompletedRequests,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  accountSettingsPage: {
    path: paths.accountSettingsPage,
    component: AccountSettings,
    withoutHeader: false,
    withoutSideBar: false,
    withoutAuth: false
  },
  viewRequestFormPage: {
    path: paths.viewRequestFormPage,
    component: ServiceRequest,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: false
  },
  supportPage: {
    path: paths.supportPage,
    component: Support,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  },
  instructionsPage: {
    path: paths.instructionsPage,
    component: Instructions,
    withoutHeader: false,
    withoutSideBar: true,
    withoutAuth: true
  }
};

export const headerPaths = Object.values(routerProps)
  .filter(item => !item.withoutHeader)
  .map(item => item.path);

export const sideBarPaths = Object.values(routerProps)
  .filter(item => !item.withoutSideBar)
  .map(item => item.path);

export default routerProps;
