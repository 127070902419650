import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import Dict from '../../../modules/utils/Dict';
import Notice from '../../../modules/utils/Notice';
import { changeComplexValue, changeFormValue } from '../../../passport/Passport';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { loadingAction } from '../ServiceRequestDucks';
import { handleError } from '../../../modules/utils/handleError';
import uuid from 'uuid/v4';
import capitalize from '@material-ui/core/utils/capitalize';

function ListenSimpleQuestions({ code, value, setSPBin, requesterIin }) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [timeOutId, registerTimeOut] = useState(null);

  let v = value;
  if (typeof value === 'object' && value !== null && value.code) {
    v = value.code;
  }
  useEffect(() => {
    let bin = null;
    switch (code) {
      case 'request_form_college_short.name_orgSpec_one':
      case 'request_form_college_short.name_orgSpec_kval':
        if (v) {
          bin = value['BIN_TIPO'];
          setSPBin(bin);
        }
        break;
      case 'request_form_university_grant_short.CertificateResult_N':
      case 'request_form_university_admission_short.CertificateResult_N':
        if (v) {
          const testCode = window.PassportForm.values[`${code.split('.')[0]}.name_internationalCertificate`];
          if (testCode && v) {
            clearTimeout(timeOutId);
            registerTimeOut(
              setTimeout(() => {
                Dict.item('gu_certificate_lang_g', testCode)
                  .then(test => {
                    if (test['ball_min_all_UNIVERSITY_GRANT']) {
                      const isLow = Number(v) < Number(test['ball_min_all_UNIVERSITY_GRANT']);
                      if (isLow) {
                        Notice.warning(
                          t('serviceRequest_lowTestResult', {
                            testName: test[`${i18n.language}_name`],
                            testResult: test['ball_min_all_UNIVERSITY_GRANT']
                          })
                        );
                      }
                    }
                  });
              }, 1000)
            );
          }
        }
        break;
      case 'request_form_university_grant_short.place_creative_exam':
        bin = value ? value['BIN VUZ'] : '';
        changeFormValue('request_form_university_grant_short.BIN_VUZ', bin);
        setSPBin(bin || true);
        break;
      case 'request_form_university_grant_short.selection_Committee_EDU_N':
      case 'request_form_university_grant_short.selection_Committee_Target':
        bin = value ? value['BIN VUZ'] : '';
        setSPBin(bin || true);
        break;
      case 'request_form_university_admission_short.VUZ_HEI_all':
      case 'request_form_university_admission_short.VUZ_HEI_quick':
        bin = value ? value['BIN_VUZ'] : '';
        changeFormValue(`request_form_university_admission_short.BIN_VUZ`, bin);
        setSPBin(bin || true);
        break;
      case 'request_form_university_admission_short.VUZ_HEI':
      case 'request_form_university_admission_short.VUZ_quick':
        bin = value ? value['BIN_VUZ'] : '';
        setSPBin(bin || true);
        break;
      case 'request_form_university_admission_short.place_creative_exam':
        bin = value ? value['BIN VUZ'] : '';
        changeFormValue(`request_form_university_admission_short.BIN_VUZ`, bin);
        setSPBin(bin || true);
        break;
      case 'request_form_graduate_admission_short.name_EDU_grant':
      case 'request_form_graduate_admission_short.name_HEY_doctoral':
        bin = value ? value['code'] : '';
        setSPBin(bin || true);
        break;
      case 'request_form_graduate_admission_short.choosing_HEY':
      case 'request_form_graduate_admission_short.choosing_HEY_doc':
        bin = value ? value['BIN_VUZ'] : '';
        setSPBin(bin || true);
        break;
      case 'request_form_graduate_admission_short.form_payment':
        if (v) {
          (async function() {
            dispatch(loadingAction(true));
            const studentIin = window.PassportForm.values['request_form_graduate_admission_short.student_iin'];
            const levelHEY = window.PassportForm.values['request_form_graduate_admission_short.level_HEY'];

            const response = await ServiceRequestApi.getStudent(studentIin);
            let { content } = response.data;
            content = content.map(({ passportValues }) =>
              passportValues.reduce((obj, { code, value }) => ({ ...obj, [code]: value }), {})
            );
            content = content.filter(item => item['code_level'] === levelHEY);
            if (content.length) {
              const student = content[0];
              if (v === '001' && levelHEY === '001' && student['grant'] === '1') {
                changeFormValue('request_form_graduate_admission_short.gop_name_grant', student['code_GOP']);
                changeFormValue('request_form_graduate_admission_short.name_EDU_grant', student['BIN_VUZ']);
                changeFormValue('request_form_graduate_admission_short.direction_training_grant', student['code_direction']);
              } else if (v === '001' && levelHEY === '001' && student['grant'] === '0') {
                Dict.item('validation_messages', 'not_grant').then(msg => {
                  Notice.info(msg[`${i18n.language}_name`]);
                });
              } else if (v === '003' && levelHEY === '001') {
                changeFormValue('request_form_graduate_admission_short.GOP_paid', student['code_GOP']);
                changeFormValue('request_form_graduate_admission_short.profile_direction', student['profile_direction']);
                changeFormValue('request_form_graduate_admission_short.ped_direction', student['ped_direction']);
              }
            }
            dispatch(loadingAction(false));
          }());
        }
        break;
      case 'request_form_graduate_admission_short.level_HEY':
        changeFormValue('request_form_graduate_admission_short.student_iin', '');
        changeFormValue('request_form_graduate_admission_short.student_surname', '');
        changeFormValue('request_form_graduate_admission_short.student_name', '');
        changeFormValue('request_form_graduate_admission_short.student_middlename', '');
        break;
      case 'request_form_duplic_edu_short.doctype_edu_name':
        changeFormValue(`request_form_duplic_edu_short.SchoolBin`, '');
        changeFormValue(`request_form_duplic_edu_short.schoolNameKk`, '');
        changeFormValue(`request_form_duplic_edu_short.schoolNameRu`, '');
        break;
      case 'request_form_organization_type_short.Applic_type':
        const school = window.PassportForm.values['request_form_organization_type_short.School_Name'];
        if (school) {
          changeFormValue('request_form_organization_type_short.School_Name', '');
          changeFormValue('request_form_organization_type_short.location_university', '');
          changeFormValue('request_form_organization_type_short.Specialty_classifier', '');
          changeFormValue('request_form_organization_type_short.Course_numb', '');
          changeFormValue('request_form_organization_type_short.lang_instruc_cerf', '');
          changeFormValue('request_form_organization_type_short.Form_train_school', '');
          changeFormValue('request_form_organization_type_short.reg_date_school', '');
          changeFormValue('request_form_organization_type_short.graduate_date', '');
          changeFormValue('request_form_organization_type_short.reason_disposal', '');
        }
        break;
      case 'request_form_contest_leaders_short.con_com':
        if (v === '1') {
          setSPBin('000140000647');
        } else {
          setSPBin(true);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [v]);

  useEffect(() => {
    let response, user, doc;
    try {
      switch (code) {
        case 'request_form_program_bolashak_short.doc_organ_rname_passport':
          (async function() {
            dispatch(loadingAction(true));
            response = await ServiceRequestApi.searchGbdfl(requesterIin);
            user = response.data.result;
            changeFormValue(`request_form_program_bolashak_short.user_sex`, user['sexCode']);
            changeFormValue(`request_form_program_bolashak_short.surname_passport`, user['lastName']);
            changeFormValue(`request_form_program_bolashak_short.name_passport`, user['firstName']);
            changeFormValue(`request_form_program_bolashak_short.middlename_passport`, user['middleName']);
            doc = user['personalDocuments'].find(doc => doc['statusCode'] === '00');
            if (doc) {
              changeFormValue(`request_form_program_bolashak_short.doc_type_passport`, doc['typeCode']);
              changeFormValue(`request_form_program_bolashak_short.passport_number`, doc['number']);
              changeFormValue(`request_form_program_bolashak_short.doc_organ_rname_passport`, doc['issueOrganizationCode']);
              changeFormValue(`request_form_program_bolashak_short.doc_issuedate_passport`, doc['beginDate']);
              changeFormValue(`request_form_program_bolashak_short.doc_expirationdate_passport`, doc['endDate']);
            }
            dispatch(loadingAction(false));
          })();
          break;
        case 'request_form_attestation_teacher_short.application_type':
          (async function() {
            dispatch(loadingAction(true));
            const sResponse = await ServiceRequestApi.getSchools(requesterIin);
            const cResponse = await ServiceRequestApi.getColleges(requesterIin);
            const uResponse = await ServiceRequestApi.getUniversities(requesterIin);
            const schools = sResponse.data.content.filter(item => item.status === 2);
            const colleges = cResponse.data.content.filter(item => item.status === 2);
            const univers = uResponse.data.content.filter(item => item.status === 2);
            if (![...schools, ...colleges, ...univers].length) {
              dispatch(loadingAction(false));
              return Notice.info(t('gbdflButton_currentEduNotFound'));
            }
            let complexData = {};
            for (let i = 0; i < schools.length; i++) {
              const complexKey = uuid();
              const name = schools[i][`schoolName${capitalize(i18n.language)}`];
              complexData[complexKey] = {
                'name_org': name
              };
            }
            const cAndU = [...colleges, ...univers];
            for (let i = 0; i < cAndU.length; i++) {
              const complexKey = uuid();
              const name = cAndU[i][`schoolName${capitalize(i18n.language)}`];
              const dn = cAndU[i].passportValues.find(q => q.code === 'DiplomaNumber');
              const sc = cAndU[i].passportValues.find(q => q.code === 'SpecialtyAndClassifier');
              const fy = cAndU[i].passportValues.find(q => q.code === 'DateOfDisposal');
              complexData[complexKey] = {
                'name_org': name,
                'number_diplom': dn ? dn.value : '',
                'spec_diplom': sc ? sc.value.value : '',
                'year_ending': fy ? fy.value : ''
              };
            }
            changeComplexValue(code, complexData);
            dispatch(loadingAction(false));
          })();
          break;
        default:
          break;
      }
    } catch (error) {
      handleError(error);
    }
  }, []);

  return null;
}

export default ListenSimpleQuestions;
