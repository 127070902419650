import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeFormValue } from '../../../passport/Passport';
import ChooseOrgn, { changeOrgnFields } from './ChooseOrgn';
import useSimpleModal from '../../../components/_hooks/useSimpleModal';
import _ from 'lodash';

async function changeCollege({ code, t, setLoading, requesterIin, lang, modal }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, college, release, colleges;

    switch (code) {
      case 'request_form_college_short.trusteeGuardian_city':
        category = window.PassportForm.values['request_form_college_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_college_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН абитуриента');
          }
        }

        response = await ServiceRequestApi.getColleges(iin);
        if (response.status === 200) {
          college = response.data.content.find(s => s['status'] === 0);
          if (!college) {
            return Notice.info(t('gbdflButton_currentEduNotFound'));
          } else {
            const name = college[`schoolName${capitalize(lang)}`];
            const location = college[`locationCode`];
            const sc = college.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
            const sf = college.passportValues.find(q => q.code === 'StudyForm');
            const cc = college.passportValues.find(q => q.code === 'Cource/Class');
            const l = college.passportValues.find(q => q.code === 'Language');

            changeFormValue(`request_form_college_short.trusteeGuardian_city`, location);
            changeFormValue(`request_form_college_short.name_orgSpec`, name);
            changeFormValue(`request_form_college_short.postSecondary_spec_code`, sc ? sc.value.value : '');
            changeFormValue(`request_form_college_short.lang_edu_tipo`, l ? l.value.value : '');
            changeFormValue(`request_form_college_short.edu_form_tipo`, sf ? sf.value.value : '');
            changeFormValue(`request_form_college_short.course_tipo`, cc ? cc.value.value : '');
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_university_grant_short.EDU_area':
        release = window.PassportForm.values['request_form_university_grant_short.releases_abroad_EDU'];
        if (release === false) {
          category = window.PassportForm.values['request_form_university_grant_short.user_category'];
          if (!category) {
            return Notice.info('Сначала выберите категорию заявителя');
          }
          iin = '';
          if (category === '003') {
            iin = requesterIin;
          } else {
            iin = window.PassportForm.values['request_form_university_grant_short.Child_iin'];
            if (!iin) {
              return Notice.info('Необходимо указать ИИН поступающего');
            }
          }

          response = await ServiceRequestApi.getColleges(iin);
          if (response.status === 200) {
            college = response.data.content.find(s => s['status'] === 0);

            if (!college) {
              college = response.data.content.find(s => s['status'] === 2);
            }
            if (!college) {
              return Notice.info(t('gbdflButton_collegeNotFound_2'));
            } else {
              const name = college[`schoolName${capitalize(lang)}`];
              const area = college[`areaCode`];
              const location = college[`locationCode`];
              const sc = college.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
              const dsn = college.passportValues.find(q => q.code === 'DiplomaSeriesandNumber');
              const cc = college.passportValues.find(q => q.code === 'Cource/Class');
              const dt = college.passportValues.find(q => q.code === 'DiplomaType');
              const dd = college.passportValues.find(q => q.code === 'DateOfDisposal');

              changeFormValue(`request_form_university_grant_short.EDU_area`, area);
              changeFormValue(`request_form_university_grant_short.EDU_locality`, location);
              changeFormValue(`request_form_university_grant_short.EDU_Name`, name);
              changeFormValue(`request_form_university_grant_short.EDU_course`, cc ? cc.value.value : '');
              changeFormValue(`request_form_university_grant_short.EDU_date_finish`, dd ? dd.value : '');
              changeFormValue(`request_form_university_grant_short.EDU_specialty_N`, sc ? sc.value.value : '');
              changeFormValue(`request_form_university_grant_short.EDU_doc_series`, dsn ? dsn.value : '');
              changeFormValue(`request_form_university_grant_short.diploma_EDU_honors`, dt ? dt.value : '');
            }
          } else {
            handleError(response, t('dataNotFound'));
          }
        } else {
          Notice.info('Запрос данных работает только для выпускников учебного заведения внутри страны Казахстан');
        }
        break;
      case 'request_form_university_admission_short.locality_EDU':
        release = window.PassportForm.values['request_form_university_admission_short.releases_abroad_EDU'];
        if (release === false) {
          category = window.PassportForm.values['request_form_university_admission_short.user_category'];
          if (!category) {
            return Notice.info('Сначала выберите категорию заявителя');
          }
          iin = '';
          if (category === '003') {
            iin = requesterIin;
          } else {
            iin = window.PassportForm.values['request_form_university_admission_short.Child_iin'];
            if (!iin) {
              return Notice.info('Необходимо указать ИИН поступающего');
            }
          }

          response = await ServiceRequestApi.getColleges(iin);
          if (response.status === 200) {
            college = response.data.content.find(s => s['status'] === 0);

            if (!college) {
              college = response.data.content.find(s => s['status'] === 2);
            }
            if (!college) {
              return Notice.info(t('gbdflButton_collegeNotFound_2'));
            } else {
              const name = college[`schoolName${capitalize(lang)}`];
              const location = college[`locationCode`];
              const sc = college.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
              const dsn = college.passportValues.find(q => q.code === 'DiplomaSeriesandNumber');
              const cc = college.passportValues.find(q => q.code === 'Cource/Class');
              const dt = college.passportValues.find(q => q.code === 'DiplomaType');
              const dd = college.passportValues.find(q => q.code === 'DateOfDisposal');
              const da = college.passportValues.find(q => q.code === 'DateOfArrival');

              changeFormValue(`request_form_university_admission_short.locality_EDU`, location);
              changeFormValue(`request_form_university_admission_short.EDU_Name`, name);
              changeFormValue(`request_form_university_admission_short.EDU_course`, cc ? cc.value.value : '');
              changeFormValue(`request_form_university_admission_short.EDU_date_finish`, dd ? dd.value : '');
              changeFormValue(`request_form_university_admission_short.EDU_doc_date`, da ? da.value : '');
              changeFormValue(`request_form_university_admission_short.EDU_specialty_N`, sc ? sc.value.value : '');
              changeFormValue(`request_form_university_admission_short.EDU_doc_series`, dsn ? dsn.value : '');
              if (dt && ['1', '2'].includes(dt.value.value)) {
                changeFormValue(`request_form_university_admission_short.diploma_EDU_honors`, dt.value.value === '1');
              }
            }
          } else {
            handleError(response, t('dataNotFound'));
          }
        } else {
          Notice.info('Запрос данных работает только для выпускников учебного заведения внутри страны Казахстан');
        }
        break;
      case 'request_form_organization_type_short.country_of_university':
        category = window.PassportForm.values['request_form_organization_type_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_organization_type_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН поступающего');
          }
        }
        const applicType = window.PassportForm.values['request_form_organization_type_short.Applic_type'];
        if (!applicType) {
          return Notice.info('Необходимо выбрать тип заявления');
        }
        response = await ServiceRequestApi.getColleges(iin);
        if (response.status === 200) {
          if (applicType === '655') {
            colleges = response.data.content.filter(s => s['status'] === 0);
          } else if (applicType === '656') {
            colleges = response.data.content.filter(s => s['status'] === 1);
          }
          colleges = _.uniqBy(colleges, 'studentId');

          if (!colleges.length) {
            return Notice.info(t('gbdflButton_collegeNotFound'));
          } else if (colleges.length > 1) {
            modal.open({ applicType, organizations: colleges, code });
          } else {
            const edu = colleges[0];
            await changeOrgnFields({ edu, applicType, code });
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      default:
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function SimpleCollege({ code, value, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const modal = useSimpleModal();

  useEffect(() => {
    if (code === 'request_form_organization_type_short.country_of_university') {
      const school = window.PassportForm.values['request_form_organization_type_short.School_Name'];
      if (school) {
        changeFormValue('request_form_organization_type_short.School_Name', '');
        changeFormValue('request_form_organization_type_short.location_university', '');
        changeFormValue('request_form_organization_type_short.Specialty_classifier', '');
        changeFormValue('request_form_organization_type_short.Course_numb', '');
        changeFormValue('request_form_organization_type_short.lang_instruc_cerf', '');
        changeFormValue('request_form_organization_type_short.Form_train_school', '');
        changeFormValue('request_form_organization_type_short.reg_date_school', '');
        changeFormValue('request_form_organization_type_short.graduate_date', '');
        changeFormValue('request_form_organization_type_short.reason_disposal', '');
      }
    }
    // eslint-disable-next-line
  }, [value])

  if (code === 'request_form_college_short.trusteeGuardian_city') {
    const applicType = window.PassportForm.values['request_form_college_short.Applic_type'];
    if (!['02', '03'].includes(applicType)) {
      return null;
    }
  }

  if (code === 'request_form_organization_type_short.country_of_university') {
    if (!value || value.code !== '47') return null;
  }

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeCollege({
          t,
          code,
          setLoading,
          requesterIin,
          lang: i18n.language,
          modal
        })}
      />
      <ChooseOrgn {...modal}/>
    </div>
  );
}

export default SimpleCollege;
