import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { changeFormValue } from '../../../passport/Passport';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';

const changeQuestions = async ({ setLoading, code, t, requesterIin }) => {
  try {
    setLoading(true);
    const metadataKey = code.split('.')[0];
    switch (code) {
      case 'request_form_international_edu_short.user_category':
        const { data } = await ServiceRequestApi.searchGbdfl(requesterIin);
        const doc = data.result['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
        if (doc) {
          changeFormValue(`${metadataKey}.doc_type_passport`, doc['typeCode']);
          changeFormValue(`${metadataKey}.passport_number`, doc['number']);
          changeFormValue(`${metadataKey}.doc_organ_rname_passport`, doc['issueOrganizationCode']);
          changeFormValue(`${metadataKey}.doc_issuedate_passport`, doc['beginDate']);
          changeFormValue(`${metadataKey}.doc_expirationdate_passport`, doc['endDate']);
        }
        break;
      default:
        break;
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.gbdfl_person_not_found') {
      Notice.error(t('gbdflButton_gbdfl_person_not_found'));
    } else {
      handleError(error, t('gbdflButton_searchFailed'));
    }
  } finally {
    setLoading(false);
  }
};

function SimpleQuestions({ code, value, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  if (
    code === 'request_form_international_edu_short.user_category' &&
    (!value || value.code !== '003')
  ) {
    return null;
  }

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        loading={loading}
        text={t('gbdflButton_search')}
        disabled={value.length !== 12}
        onClick={() => changeQuestions({
          t,
          code,
          value,
          setLoading,
          requesterIin,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default SimpleQuestions;