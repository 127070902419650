import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';
import uuid from 'uuid/v4';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeComplexValue } from '../../../passport/Passport';
import Notice from '../../../modules/utils/Notice';

async function changeUniver({ code, complexCode, questionCode, t, setLoading, lang, requesterIin }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, univers = [], release;

    switch (`${code.split('.')[1]}.${questionCode}`) {
      case 'education_list.country_of_university':
        category = window.PassportForm.values['request_form_international_edu_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_international_edu_short.child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН ребенка');
          }
        }
        response = await ServiceRequestApi.getUniversities(iin);
        if (response.status === 200) {
          if (!response.data.content.length) {
            return Notice.info('Данные по высшему/послевузовскому образованию не найдены, введите информацию вручную');
          } else {
            let complexData = {};
            for (let i = 0; i < response.data.content.length; i++) {
              const complexKey = i === 0 ? complexCode : uuid();
              const name = response.data.content[i][`schoolName${capitalize(lang)}`];
              const location = response.data.content[i][`locationCode`];
              const specialty = response.data.content[i].passportValues.find(q => q.code === 'SpecialtyAndClassifier');
              const entry_date = response.data.content[i].passportValues.find(q => q.code === 'DateOfArrival');
              const grad_date = response.data.content[i].passportValues.find(q => q.code === 'DateOfDisposal');
              const diplomaNum = response.data.content[i].passportValues.find(q => q.code === 'DiplomaNumber');
              const serialDiploma = response.data.content[i].passportValues.find(q => q.code === 'DiplomaSeries');
              complexData[complexKey] = {
                'country_of_university': '47',
                'name_of_university': name,
                'location_university': location,
                'specialty': specialty ? specialty.value.value : '',
                'entry_date': entry_date ? entry_date.value : '',
                'grad_date': grad_date ? grad_date.value : '',
                'diplomaNum': diplomaNum ? diplomaNum.value : '',
                'serialDiploma': serialDiploma ? serialDiploma.value : ''
              };
            }
            changeComplexValue(code, complexData);
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'university.country_university':
        category = window.PassportForm.values['request_form_college_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_college_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН абитуриента');
          }
        }
        response = await ServiceRequestApi.getUniversities(iin);
        if (response.status === 200) {
          univers = response.data.content.filter(s => s['status'] === 2);
          if (!univers.length) {
            return Notice.info(t('gbdflButton_univerNotFound'));
          } else {
            let complexData = {};
            for (let i = 0; i < univers.length; i++) {
              const complexKey = i === 0 ? complexCode : uuid();
              const name = univers[i][`schoolName${capitalize(lang)}`];
              const sc = univers[i].passportValues.find(q => q.code === 'SpecialtyAndClassifier');
              const sf = univers[i].passportValues.find(q => q.code === 'StudyForm');
              const cc = univers[i].passportValues.find(q => q.code === 'Cource/Class');
              const da = univers[i].passportValues.find(q => q.code === 'DateOfArrival');
              const dd = univers[i].passportValues.find(q => q.code === 'DateOfDisposal');
              const dn = univers[i].passportValues.find(q => q.code === 'DiplomaNumber');
              const ds = univers[i].passportValues.find(q => q.code === 'DiplomaSeries');
              complexData[complexKey] = {
                'country_university': '47',
                'univer_name': name,
                'univer_specialty': sc ? sc.value.value : '',
                'univer_form': sf ? sf.value.value : '',
                'univer_course': cc ? cc.value.value : '',
                'university_arrivaldate': da ? da.value : '',
                'university_disposaldate': dd ? dd.value : '',
                'univer_numberdiploma': dn ? dn.value : '',
                'university_seriesdiploma': ds ? ds.value : ''
              };
            }
            changeComplexValue(code, complexData);
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'HEI_edu.HEI_Name':
        release = window.PassportForm.values['request_form_university_admission_short.releases_abroad_HEI'];
        if (release === false) {
          category = window.PassportForm.values['request_form_university_admission_short.user_category'];
          if (!category) {
            return Notice.info('Сначала выберите категорию заявителя');
          }
          iin = '';
          if (category === '003') {
            iin = requesterIin;
          } else {
            iin = window.PassportForm.values['request_form_university_admission_short.Child_iin'];
            if (!iin) {
              return Notice.info('Необходимо указать ИИН поступающего');
            }
          }

          response = await ServiceRequestApi.getUniversities(iin);
          if (response.status === 200) {
            univers = response.data.content.filter(s => s['status'] === 2);

            if (!univers.length) {
              return Notice.info(t('gbdflButton_univerNotFound'));
            } else {
              let complexData = {};
              for (let i = 0; i < univers.length; i++) {
                const complexKey = i === 0 ? complexCode : uuid();
                const name = univers[i][`schoolName${capitalize(lang)}`];
                const sc = univers[i].passportValues.find(q => q.code === 'SpecialtyAndClassifier');
                const dsn = univers[i].passportValues.find(q => q.code === 'DiplomaSeries');
                const cc = univers[i].passportValues.find(q => q.code === 'Cource/Class');
                const dn = univers[i].passportValues.find(q => q.code === 'DiplomaNumber');
                const dd = univers[i].passportValues.find(q => q.code === 'DateOfDisposal');
                const dt = univers[i].passportValues.find(q => q.code === 'DiplomaType');

                let honor = null;
                if (dt && ['1', '2'].includes(dt.value.value)) {
                  honor = dt.value.value === '1';
                }
                complexData[complexKey] = {
                  'HEI_Name': name,
                  'HEI_course': cc ? cc.value.value : '',
                  'HEI_date_finish': dd ? dd.value : '',
                  'HEI_specialty_N': sc ? sc.value.value : '',
                  'HEI_doc_series': dsn ? dsn.value : '',
                  'HEI_doc_number': dn ? dn.value : '',
                  'diploma_HEI_honors': honor
                };
              }
              changeComplexValue(code, complexData);
            }
          } else {
            handleError(response, t('dataNotFound'));
          }
        } else {
          Notice.info('Запрос данных работает только для выпускников учебного заведения внутри страны Казахстан');
        }
        break;
      default:
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function ComplexUniver({ code, value, questionCode, complexCode, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  if (
    ['country_of_university', 'country_university'].includes(questionCode) &&
    (!value || value.code !== '47')
  ) {
    return null;
  }

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeUniver({
          t,
          code,
          complexCode,
          setLoading,
          requesterIin,
          questionCode,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default ComplexUniver;