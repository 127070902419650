let config;
let protocol = window.location.protocol;

switch (window.location.hostname) {
  case 'es.mon.iac.kz': {
    config = {
      url: {
        base: `${protocol}//es-api.iac.kz`,
        service: `${protocol}//nobd-services.iac.kz`,
        fileStore: `${protocol}//nobd-filestore-api.iac.kz`,
        ws: `${protocol}//nobd-ws.iac.kz`
      }
    };
    break;
  }
  case 'es.mon-test.iac.kz': {
    config = {
      url: {
        base: `${protocol}//es-api-test.iac.kz`,
        service: `${protocol}//nobd-services-test.iac.kz`,
        fileStore: `${protocol}//nobd-filestore-api-test.iac.kz`,
        ws: `${protocol}//nobd-ws-test.iac.kz`
      }
    };
    break;
  }
  default: {
    protocol = 'https:';
    config = {
      url: {
        base: `${protocol}//es-api-test.iac.kz`,
        service: `${protocol}//nobd-services-test.iac.kz`,
        fileStore: `${protocol}//nobd-filestore-api-test.iac.kz`,
        ws: `${protocol}//nobd-ws-test.iac.kz`
      }
    };
  }
}

export default config;
