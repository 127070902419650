import React from 'react';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import paths from '../../../_helpers/paths';

export const sideBarItems = [
  {
    translate: 'sidebar_requests',
    path: paths.requestsPage,
    icon: <AssignmentIcon fontSize="small" />,
  },
  {
    translate: 'sidebar_completedRequests',
    path: paths.completedRequestsPage,
    icon: <AssignmentTurnedInIcon fontSize="small" />,
  },
  {
    translate: 'sidebar_account',
    path: paths.accountSettingsPage,
    icon: <AssignmentIndIcon fontSize="small" />,
  },
];
