import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Tooltip,
  Button
} from '@material-ui/core';
import { Wrapper, tableTheme } from './DComplexTableStyle';
import FullScreen from '../../FullScreen/FullScreen';
import { ThemeProvider } from '@material-ui/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import withTranslation from '../../translation/withTranslation';
import uuid from 'uuid/v4';
import withComplexFlc from '../../flc/withComplexFlc';
import FormHelperText from '@material-ui/core/FormHelperText';
import DComplexTableRow from './DComplexTableRow';
import { handleQuestionLabel } from '../../../Utils/handleQuestionLabel';
import Pagination from '@material-ui/lab/Pagination';
import { PaginationItem } from '@material-ui/lab';
import PassportForm from '../../../FormData/PassportForm';
import ArrowLeftIcon from '@material-ui/icons/KeyboardBackspace';

class DComplexTable extends React.Component {
  state = {
    pageNumber: null,
    pageErrors: {}
  };

  deleteRow(complexCode) {
    this.props.onDeleteRow(complexCode);
  }

  checkCellValue(value) {
    this.props.checkCellHasValue(value);
  }

  setPageError(error, rowIndex) {
    const pageSize = this.props.config.pageSize || 0;
    const { pageErrors } = this.state;
    const code = this.props.code;
    const pageNumber = Math.ceil((rowIndex + 1) / pageSize);
    if (error && pageErrors[pageNumber] !== error) {
      this.setState({ pageErrors: { ...pageErrors, [pageNumber]: error } });
      PassportForm.complexPagesErrors[`${code}_page_${pageNumber}`] = {
        validation: true
      };
    } else if (pageErrors[pageNumber]) {
      const newPageErrors = { ...pageErrors };
      delete newPageErrors[pageNumber];
      this.setState({ pageErrors: newPageErrors });
      delete PassportForm.complexPagesErrors[code];
    }
  }

  render() {
    const {
      t,
      lang,
      error,
      rowAdd,
      rowDeletion,
      complexCodes,
      onAddRow,
      hideMode,
      disabled,
      config,
      cellsQuestions,
      cellsQuestionCodes,
      cellsSavedValue,
      cellsSavedVersion,
      cellsDefaultValue,
      cellsSource,
      cancelValidation,
      cellsDependencies = {},
      ...rest
    } = this.props;

    const pageSize = config.pageSize || 0;
    const pageNumber = this.state.pageNumber
      ? this.state.pageNumber
      : Math.ceil(complexCodes.length / pageSize);
    const { pageErrors } = this.state;
    const startIndex = pageSize * (pageNumber - 1);
    const endIndex = pageSize * pageNumber;
    const isLastPage = complexCodes.length <= endIndex || pageSize === 0;

    if (hideMode) {
      return complexCodes.map((complexCode, index) => (
        <DComplexTableRow
          key={complexCode}
          t={t}
          hideMode={hideMode}
          rowIndex={index}
          code={rest.code}
          lang={lang}
          disabled={disabled}
          cancelValidation={cancelValidation}
          complexCode={complexCode}
          cellsQuestionCodes={cellsQuestionCodes}
          cellsQuestions={cellsQuestions}
          checkCellHasValue={value => this.checkCellValue(value)}
          cellsSavedValue={cellsSavedValue}
          cellsSavedVersion={cellsSavedVersion}
          cellsDefaultValue={cellsDefaultValue}
          cellsSource={cellsSource}
          cellsDependencies={cellsDependencies}
          onDeleteRow={complexCode => this.deleteRow(complexCode)}
        />
      ));
    }

    return (
      <Wrapper error={error} id={`anchor_${rest.code}`}>
        <ThemeProvider theme={tableTheme}>
          <FullScreen
            title={handleQuestionLabel(this.props, lang)}
            afterRender={
              <>
                {!disabled &&
                  rowAdd &&
                  isLastPage && (
                    <Button
                      className="add-row"
                      startIcon={<AddIcon />}
                      size="small"
                      onClick={() => onAddRow(uuid())}
                      children={t('passport_complexTableAdd')}
                    />
                  )}
                {pageSize !== 0 &&
                  complexCodes.length > pageSize && (
                    <div className="flex items-center">
                      <Pagination
                        className="py1"
                        count={Math.ceil(complexCodes.length / pageSize)}
                        page={pageNumber}
                        onChange={(e, pageNumber) => this.setState({ pageNumber })}
                        renderItem={item => (
                          <PaginationItem
                            id={
                              item.type === 'page'
                                ? `anchor_${rest.code}_page_${item.page}`
                                : undefined
                            }
                            {...item}
                            style={
                              pageErrors[item.page] && item.type === 'page'
                                ? { border: '1px solid red', color: 'red' }
                                : undefined
                            }
                          />
                        )}
                      />
                      {Object.keys(pageErrors).length > 0 && (
                        <span style={{ color: 'red', marginLeft: 10 }}>
                          <ArrowLeftIcon /> {t('flc_page_validation')}
                        </span>
                      )}
                    </div>
                  )}
              </>
            }
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="column-title row-number">№</TableCell>
                  {cellsQuestionCodes.map(questionCode => (
                    <TableCell className="column-title" key={questionCode}>
                      {handleQuestionLabel(cellsQuestions[questionCode], lang)}
                    </TableCell>
                  ))}
                  {!disabled &&
                    !rowDeletion && (
                      <TableCell className="column-title action-delete">
                        {t('passport_complexTableAction')}
                      </TableCell>
                    )}
                </TableRow>
              </TableHead>

              <TableBody>
                {complexCodes.length === 0 && (
                  <TableRow onClick={() => !disabled && onAddRow(uuid())}>
                    <TableCell
                      className={`row-number ${disabled && 'cell-disabled'}`}
                      children={1}
                    />
                    {cellsQuestionCodes.map(questionCode => (
                      <TableCell
                        className={disabled ? 'cell-disabled' : ''}
                        key={questionCode}
                      />
                    ))}
                    {!disabled &&
                      !rowDeletion && (
                        <TableCell className={`action-delete`}>
                          <Tooltip
                            placement="top"
                            title={t('passport_complexTableDelete')}
                          >
                            <div>
                              <IconButton
                                disabled
                                style={{ padding: 0 }}
                                children={<DeleteIcon />}
                              />
                            </div>
                          </Tooltip>
                        </TableCell>
                      )}
                  </TableRow>
                )}
                {complexCodes.map((complexCode, index) => (
                  <TableRow
                    key={complexCode}
                    className={
                      pageSize !== 0 && !(endIndex > index && index >= startIndex)
                        ? 'display-none'
                        : ``
                    }
                  >
                    <TableCell
                      className={`row-number ${disabled && 'cell-disabled'}`}
                      children={index + 1}
                    />
                    <DComplexTableRow
                      key={complexCode}
                      t={t}
                      setPageError={
                        pageSize !== 0
                          ? error => this.setPageError(error, index)
                          : undefined
                      }
                      hiddenByPagination={
                        pageSize !== 0 && !(endIndex > index && index >= startIndex)
                      }
                      rowIndex={index}
                      code={rest.code}
                      rowDeletion={rowDeletion}
                      lang={lang}
                      disabled={disabled}
                      cancelValidation={cancelValidation}
                      complexCode={complexCode}
                      cellsQuestionCodes={cellsQuestionCodes}
                      cellsQuestions={cellsQuestions}
                      checkCellHasValue={value => this.checkCellValue(value)}
                      cellsSavedValue={cellsSavedValue}
                      cellsSavedVersion={cellsSavedVersion}
                      cellsDefaultValue={cellsDefaultValue}
                      cellsSource={cellsSource}
                      cellsDependencies={cellsDependencies}
                      onDeleteRow={complexCode => this.deleteRow(complexCode)}
                    />
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {error && (
              <FormHelperText
                style={{ marginTop: 4, float: 'right' }}
                error
                children={error}
              />
            )}
          </FullScreen>
        </ThemeProvider>
      </Wrapper>
    );
  }
}

export default withTranslation(withComplexFlc(DComplexTable));
