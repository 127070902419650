import axios from 'axios';
import { metaCaching } from '../../modules/utils/MetaData';

export const ServiceRequestApi = {
  loadMetaData: metaDataKey => metaCaching(
    `/api/public/metadata/${metaDataKey}`
  ),
  loadData: id => axios.get(
    `/api/self/request_form/${id}`
  ),
  createRequestForm: values => axios.post(
    '/api/self/request_form', values
  ),
  serviceProvider: filter => axios.get(
    `/api/public/info/paging/service_provider?filter=${filter}`
  ),
  searchGbdfl: iin => axios.get(
    `/api/info/gbdfl_personal_data/${iin}`
  ),
  getSignString: data => axios.post(
    `/api/info/signature/sign_string/request_form`, data
  ),
  getUniversities: iin => axios.get(
    `api:ws/api/public/data/common_university_education_student_data_by_iin?iin=${iin}`
  ),
  getSchools: iin => axios.get(
    `api:ws/api/public/data/common_school_student_data_by_iin?iin=${iin}`
  ),
  getColleges: iin => axios.get(
    `api:ws/api/public/data/common_college_education_student_data_by_iin?iin=${iin}`
  ),
  getGuardianship: iin => axios.get(
    `api:ws/api/public/data/guardianship_data_by_student_iin?iin=${iin}`
  ),
  getNCT: iin => axios.get(
    `api:ws/api/public/data/common_nct_data_by_iin_student?iin=${iin}`
  ),
  serviceProviderPassport: id => axios.get(
    `/api/public/passport/service_provider/${id}`
  ),
  getStudent: iin => axios.get(
    `/api/public/data/gu_iin_postgraduate?student_iin=${iin}`
  )
};