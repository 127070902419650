import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import Colors from '../../ThemeProvider/Colors';
import { StyledSelectButton } from './HeaderStyle';
import { history } from '../../../_helpers/history';

function HelpButton() {
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledSelectButton onClick={e => {
        setAnchorEl(e.currentTarget);
        setOpen(true);
      }}>
        <HelpOutlineIcon className="icon left"/>
        {t('header_help')}
        <ExpandMoreIcon className="icon"/>
      </StyledSelectButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        style={{ marginTop: 40 }}
        MenuListProps={{ style: { minWidth: 120 } }}
        onClose={() => setOpen(false)}
      >
        <MenuItem
          style={{ color: Colors.action }}
          onClick={() => {
            setOpen(false);
            history.push('/support');
          }}
        >
          <Typography children={t('header_support')}/>
        </MenuItem>
        <MenuItem
          style={{ color: Colors.action }}
          onClick={() => {
            setOpen(false);
            history.push('/instructions');
          }}
        >
          <Typography children={t('header_instruction')}/>
        </MenuItem>
      </Menu>
    </>
  );
}

export default HelpButton;