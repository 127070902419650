import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import { StyledSupport } from './SupportStyles';
import './SupportTranslate';

function Support() {
  const { t } = useTranslation();

  return (
    <div className="mb3 mt3 fullWidth">
      <Typography
        children={t('support_title')}
        color="secondary"
        variant="h6"
      />
      <StyledSupport className="mt2">
        {t('support_content')}
      </StyledSupport>
    </div>
  )
}

export default Support;