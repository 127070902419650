import capitalize from '@material-ui/core/utils/capitalize';

import Dict from '../../../modules/utils/Dict';
import { changeFormValue } from '../../../passport/Passport';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';

export async function isLowResult({ metadataKey }) {
  const testCode = window.PassportForm.values[`request_form_${metadataKey}.name_internationalCertificate`];
  const testResult = window.PassportForm.values[`request_form_${metadataKey}.CertificateResult_N`];
  if (testCode && testResult) {
    const test = await Dict.item('gu_certificate_lang_g', testCode);
    if (test['ball_min_all_UNIVERSITY_GRANT']) {
      return {
        isLow: Number(testResult) < Number(test['ball_min_all_UNIVERSITY_GRANT']),
        ru_name: test['ru_name'],
        kk_name: test['kk_name'],
        testResult: test['ball_min_all_UNIVERSITY_GRANT']
      };
    } else {
      return { isLow: false };
    }
  } else {
    return { isLow: false };
  }
}

export function changeQuestionSerpin() {
  const graduate = window.PassportForm.values['request_form_university_grant_short.choice_graduate_N'];
  const territorial = window.PassportForm.values['request_form_university_grant_short.territorial_school'];
  const locality = window.PassportForm.values['request_form_university_grant_short.locality_school'];
  if (
    graduate && graduate === '001' &&
    territorial && territorial === true &&
    locality && ['19', '31', '43', '47', '61'].includes(locality.slice(0, 2))
  ) {
    changeFormValue('request_form_university_grant_short.grantUnder_Serpin', true);
  } else if (
    graduate && graduate === '001' &&
    locality && ['471810'].includes(locality.slice(0, 6))
  ) {
    changeFormValue('request_form_university_grant_short.grantUnder_Serpin', true);
  } else {
    changeFormValue('request_form_university_grant_short.grantUnder_Serpin', false);
  }
}

export async function changeQuestionGopFactSys({ requesterIin, t, lang }) {
  let category = window.PassportForm.values['request_form_university_grant_short.user_category'];
  if (!category) {
    Notice.info('Сначала выберите категорию заявителя');
    return false;
  }
  let iin = '';
  if (category === '003') {
    iin = requesterIin;
  } else {
    iin = window.PassportForm.values['request_form_university_grant_short.Child_iin'];
    if (!iin) {
      Notice.info('Необходимо указать ИИН поступающего');
      return false;
    }
  }
  const response = await ServiceRequestApi.getUniversities(iin);
  if (response.status === 200) {
    if (response.data.content.length) {
      let univer = response.data.content.find(u => u['status'] === 0);

      if (univer) {
        if (!univer['schoolBin']) {
          return changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', false);
        }
        changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', true);
        Notice.warning(t('studyAtTheMoment', { univer: univer[`schoolName${capitalize(lang)}`] }));
        return false;
      }

      if (!univer) {
        univer = response.data.content.find(u =>
          u['status'] === 2 &&
          u.passportValues.find(q => q.code === 'StudiedAtTheExpense' && ['01', '10', '11', '12', '12.1', '13', '14', '15', '22', '31', '32', '34', '35', '36', '37'].includes(q.value.value))
        );
      }

      if (univer) {
        if (!univer['schoolBin']) {
          return changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', false);
        }
        changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', true);
        Notice.warning(t('finishedSameUniver', { univer: univer[`schoolName${capitalize(lang)}`] }));
        return false;
      } else {
        changeFormValue('request_form_university_grant_short.candidate_gop_fact', '1');
        changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', false);
      }
    }
  }
  return true;
}