import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import useTableFilter from '../../modules/TableFilter/useTableFilter';
import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import { completedRequestsModule, getCompletedRequests } from './CompletedRequestsDucks';
import { history } from '../../_helpers/history';
import { LightTooltip, StyledTableContainer } from '../Requests/RequestsStyles';
import { translation } from '../../_helpers/Translate';
import { requestsColumns } from '../Requests/RequestsColumns';
import { TooltipContent } from '../Requests/Requests';
import Colors from '../../components/ThemeProvider/Colors';
import { services } from '../../_helpers/Constants';
import './CompletedRequestsTranslate';

function CompletedRequests({ data, loading, getCompletedRequests }) {
  const { t, i18n } = useTranslation();
  const { filter } = useTableFilter(getCompletedRequests);

  const extraColumns = [
    {
      Header: translation('completedRequests_outputForm'),
      accessor: 'accessedExportForms',
      style: { textAlign: 'center' },
      width: 200,
      Cell: ({ value }) => value.length ? (
        <LightTooltip
          interactive
          title={<TooltipContent reports={value} lang={i18n.language}/>}
        >
          <div
            onClick={e => e.stopPropagation()}
            style={{ color: Colors.primary }}
          >
            {t('completedRequests_download')}
          </div>
        </LightTooltip>
      ) : null
    }
  ];

  return (
    <div className="mt3 mb3 fullWidth">
      <Typography
        children={t('completedRequests_title')}
        color="secondary"
        variant="h6"
      />
      <StyledTableContainer>
        <div className="mt2">
          <StaticTablePaginated
            pageNumber={data.pageNumber}
            loading={loading}
            data={data.content}
            totalElements={data.totalElements}
            columns={requestsColumns({
              pageNumber: data.pageNumber,
              extraColumns,
              lang: i18n.language
            })}
            filterable={false}
            onClickRow={({ original }) => {
              if (services[original.serviceType]) {
                history.push(`/view/completed_requests/${original.id}`);
              }
            }}
            pagePagination={page => {
              if (page !== data.pageNumber) {
                getCompletedRequests({
                  ...filter,
                  pageNumber: page
                });
              }
            }}
          />
        </div>
      </StyledTableContainer>
    </div>
  );
}

export default connect(
  state => ({
    data: state[completedRequestsModule].tableData,
    loading: state[completedRequestsModule].loadingTable,
  }),
  {
    getCompletedRequests
  }
)(CompletedRequests);