import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    home_title: 'Портал государственных услуг \nМинистерства образования и науки Республики Казахстан',
    home_subtitle: 'Государственные услуги',
  },
  kk: {
    home_title: 'ҚАЗАҚСТАН РЕСПУБЛИКАСЫ БІЛІМ ЖӘНЕ ҒЫЛЫМ МИНИСТРЛІГІНІҢ \nМЕМЛЕКЕТТІК ҚЫЗМЕТТЕР ПОРТАЛЫ',
    home_subtitle: 'Мемлекеттік қызметтер',
  }
});