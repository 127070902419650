import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';
import _ from 'lodash';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeFormValue } from '../../../passport/Passport';
import ChooseOrgn, { changeOrgnFields } from './ChooseOrgn';
import useSimpleModal from '../../../components/_hooks/useSimpleModal';

async function changeSchool({ code, t, setLoading, requesterIin, lang, modal }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, school;

    switch (code) {
      case 'request_form_college_short.pmpk_number':
        category = window.PassportForm.values['request_form_college_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_college_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН абитуриента');
          }
        }
        const study = window.PassportForm.values['request_form_college_short.study'];
        if (!study) {
          return Notice.info('Необходимо указать "Документ о полученном образовании"');
        }
        if (study === '001') {
          response = await ServiceRequestApi.getSchools(iin);
        } else if (study === '002') {
          response = await ServiceRequestApi.getColleges(iin);
        } else {
          response = await ServiceRequestApi.getUniversities(iin);
        }
        if (response.status === 200) {
          if (study === '001') {
            school = response.data.content.find(s => s['status'] === 2 && s.passportValues.find(q => q.code === 'Parallel' && ['13', '14', '15'].includes(q.value.value)));
          } else if (study === '003') {
            school = response.data.content.find(s => s['status'] === 2);
          } else {
            const applicType = window.PassportForm.values['request_form_college_short.Applic_type'];
            const status = applicType === '001' ? 2 : 0;
            school = response.data.content.find(s => s['status'] === status);
          }
          if (!school) {
            return Notice.info(t('gbdflButton_pmpkNotFound'));
          } else {
            const c = school.passportValues.find(q => q.code === 'PMPKNumber');
            const cd = school.passportValues.find(q => q.code === 'PMPKDate');
            const dt = school.passportValues.find(q => q.code === 'DefectionTypes');

            if (!c && !cd && !dt) {
              return Notice.info(t('gbdflButton_pmpkNotFound'));
            }

            changeFormValue(`request_form_college_short.pmpk_number`, c ? c.value : '');
            changeFormValue(`request_form_college_short.pmpk_date`, cd ? cd.value : '');
            changeFormValue(`request_form_college_short.pmpk_type`, dt ? dt.value.value : '');
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_university_grant_short.area_school':
      case 'request_form_university_admission_short.area_school':
        const serviceCode = code.split('.')[0];
        category = window.PassportForm.values[`${serviceCode}.user_category`];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values[`${serviceCode}.Child_iin`];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН поступающего');
          }
        }
        response = await ServiceRequestApi.getSchools(iin);
        if (response.status === 200) {
          school = response.data.content.find(s => s['status'] === 2 && s.passportValues.find(q => q.code === 'Parallel' && '15' === q.value.value));
          if (!school) {
            school = response.data.content.find(s => s['status'] === 0 && s.passportValues.find(q => q.code === 'Parallel' && '15' === q.value.value));
          }
          if (!school) {
            school = response.data.content.find(s => s['status'] === 2 && s.passportValues.find(q => q.code === 'Parallel' && '14' === q.value.value));
          }
          if (!school) {
            school = response.data.content.find(s => s['status'] === 0 && s.passportValues.find(q => q.code === 'Parallel' && '14' === q.value.value));
          }
          if (!school) {
            school = response.data.content.find(s => s['status'] === 2 && s.passportValues.find(q => q.code === 'Parallel' && '13' === q.value.value));
          }
          if (!school) {
            school = response.data.content.find(s => s['status'] === 0 && s.passportValues.find(q => q.code === 'Parallel' && '13' === q.value.value));
          }
          if (!school) {
            return Notice.info(t('gbdflButton_schoolNotFound'));
          } else {
            const area = school[`areaCode`];
            const location = school[`locationCode`];
            const name = school[`schoolName${capitalize(lang)}`];
            const ta = school.passportValues.find(q => q.code === 'TerritorialAccessories');
            const parallel = school.passportValues.find(q => q.code === 'Parallel');
            const r = school.passportValues.find(q => q.code === 'Released');
            const dt = school.passportValues.find(q => q.code === 'DiplomaType');
            const l = school.passportValues.find(q => q.code === 'Language');
            const ab = school.passportValues.find(q => q.code === 'AltynBelgi');
            const dd = school.passportValues.find(q => q.code === 'DateOfDisposal');
            const snc = school.passportValues.find(q => q.code === 'SerialNumberCert');
            const sn = school.passportValues.find(q => q.code === 'CertificateNumber');
            const dic = school.passportValues.find(q => q.code === 'DateIssueCert');

            changeFormValue(`${serviceCode}.area_school`, area);
            changeFormValue(`${serviceCode}.locality_school`, location);
            changeFormValue(`${serviceCode}.School_Name`, name);
            changeFormValue(`${serviceCode}.territorial_school`, ta ? ta.value.value === '2' : '');
            changeFormValue(`${serviceCode}.school_class`, parallel ? parallel.value.value : '');
            changeFormValue(`${serviceCode}.school_date_finish`, dd ? dd.value : '');
            changeFormValue(`${serviceCode}.school_certificate_number`, sn ? sn.value : '');
            changeFormValue(`${serviceCode}.school_serial_certificate`, snc ? snc.value : '');
            changeFormValue(`${serviceCode}.school_date_cert`, dic ? dic.value : '');
            changeFormValue(`${serviceCode}.sign_gold_medal`, ab ? ab.value : '');
            changeFormValue(`${serviceCode}.school_lang`, l ? l.value.value : '');

            if (r && ['12', '22'].includes(r.value.value)) {
              changeFormValue(`${serviceCode}.certificate_distinction`, true);
            } else if (dt && ['10', '11', '20', '21'].includes(dt.value.value)) {
              changeFormValue(`${serviceCode}.certificate_distinction`, false);
            }
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_duplic_edu_short.SchoolBin':
        const docType = window.PassportForm.values['request_form_duplic_edu_short.doctype_edu_name'];
        if (!docType) {
          return Notice.info('Сначала выберите код документа');
        }
        if (['0', '4', '9'].includes(docType)) {
          response = await ServiceRequestApi.getSchools(requesterIin);
        } else if (['1', '5', '7'].includes(docType)) {
          response = await ServiceRequestApi.getColleges(requesterIin);
        } else if (['2', '6', '8'].includes(docType)) {
          response = await ServiceRequestApi.getUniversities(requesterIin);
        } else {
          return Notice.info('Для указанного кода документа "Запрос данных" не работает');
        }
        if (response.status === 200) {
          let data = response.data.content.filter(s => s['status'] === 2);
          data = _.uniqBy(data, 'studentId');
          if (!data.length) {
            return Notice.info('Данные об образовании не найдены');
          }

          if (data.length > 1) {
            modal.open({ organizations: data, docType, code });
          } else if (data.length === 1) {
            const edu = data[0];
            await changeOrgnFields({ edu, docType, code });
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_extern_study_short.nameOrg_edu':
        category = window.PassportForm.values[`request_form_extern_study_short.category`];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values[`request_form_extern_study_short.student_iin`];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН учащегося');
          }
        }
        response = await ServiceRequestApi.getSchools(requesterIin);
        school = response.data.content.find(s => s['status'] === 0);
        if (!school) {
          return Notice.info(t('gbdflButton_schoolNotFound'));
        }
        const name = school[`schoolName${capitalize(lang)}`];
        const da = school.passportValues.find(q => q.code === 'DateOfArrival');
        const parallel = school.passportValues.find(q => q.code === 'Parallel');
        const sf = school.passportValues.find(q => q.code === 'StudyForm');
        const bi = school.passportValues.find(q => q.code === 'BoardingInformation');
        const ssc = school.passportValues.find(q => q.code === 'StudyingInSpecialClasses');
        changeFormValue('request_form_extern_study_short.nameOrg_edu', name);
        changeFormValue('request_form_extern_study_short.classNum', parallel?.value?.value);
        changeFormValue('request_form_extern_study_short.date_enrollment', da?.value);
        changeFormValue('request_form_extern_study_short.formTraining', sf?.value?.value);
        changeFormValue('request_form_extern_study_short.boardingDetails', bi?.value?.value);
        changeFormValue('request_form_extern_study_short.study_specClasses', ssc?.value?.value);
        break;
      default:
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function SimpleSchool({ code, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const modal = useSimpleModal(false);

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeSchool({
          t,
          code,
          setLoading,
          requesterIin,
          lang: i18n.language,
          modal
        })}
      />
      <ChooseOrgn {...modal}/>
    </div>
  );
}

export default SimpleSchool;
