import React from 'react';
import _ from 'lodash';

import {
  OO_MODAL_TRIGGERS,
  SIMPLE_IIN_QUESTIONS,
  COMPLEX_IIN_QUESTIONS,
  COMPLEX_SCHOOL_QUESTIONS,
  COMPLEX_UNIVER_QUESTIONS,
  SIMPLE_NOT_IIN_QUESTIONS,
  SIMPLE_SP_MODAL_TRIGGERS,
  LISTEN_SIMPLE_QUESTIONS,
  COMPLEX_COLLEGE_QUESTIONS,
  SIMPLE_SCHOOL_QUESTIONS,
  SIMPLE_COLLEGE_QUESTIONS,
  SIMPLE_GUARDIANSHIP_QUESTIONS,
  SIMPLE_UNIVER_QUESTIONS, NCT_QUESTIONS, SIMPLE_BUTTON_QUESTIONS
} from './constants';
import SPModalButton from './SPModalButton';
import SimpleGBDFLButton from './SimpleGBDFLButton';
import ComplexGBDFLButton from './ComplexGBDFLButton';
import ComplexSchool from './ComplexSchool';
import ComplexUniver from './ComplexUniver';
import SimpleQuestions from './SimpleQuestions';
import OOModalButton from './OOModalButton';
import ListenSimpleQuestions from './ListenSimpleQuestions';
import ComplexCollege from './ComplexCollege';
import SimpleSchool from './SimpleSchool';
import SimpleCollege from './SimpleCollege';
import SimpleGuardianship from './SimpleGuardianship';
import SimpleUniver from './SimpleUniver';
import NCT from './NCT';
import SimpleButton from './SimpleButton';

export function extraFunctionality({ code, value, SPModal, OOModal, requesterIin, setSPBin }) {
  switch (typeof code) {
    case 'object':
      const { questionCode, complexCode } = code;
      const ctCode = code.code.split('.').pop();
      if (OO_MODAL_TRIGGERS.includes(questionCode)) {
        return (
          <OOModalButton
            code={code}
            OOModal={OOModal}
          />
        );
      } else if (_.get(COMPLEX_IIN_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return (
          <ComplexGBDFLButton
            code={code.code}
            questionCode={questionCode}
            complexCode={complexCode}
            value={value}
          />
        );
      } else if (_.get(COMPLEX_SCHOOL_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return (
          <ComplexSchool
            value={value}
            code={code.code}
            complexCode={complexCode}
            questionCode={questionCode}
            requesterIin={requesterIin}
          />
        );
      } else if (_.get(COMPLEX_UNIVER_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return (
          <ComplexUniver
            value={value}
            code={code.code}
            complexCode={complexCode}
            questionCode={questionCode}
            requesterIin={requesterIin}
          />
        );
      } else if (_.get(COMPLEX_COLLEGE_QUESTIONS, `${ctCode}.${questionCode}`)) {
        return (
          <ComplexCollege
            value={value}
            code={code.code}
            complexCode={complexCode}
            questionCode={questionCode}
            requesterIin={requesterIin}
          />
        );
      }
      break;
    case 'string':
      if (SIMPLE_IIN_QUESTIONS.includes(code)) {
        return (
          <SimpleGBDFLButton
            code={code}
            value={value}
          />
        );
      } else if (SIMPLE_NOT_IIN_QUESTIONS.includes(code)) {
        return (
          <SimpleQuestions
            code={code}
            requesterIin={requesterIin}
          />
        );
      } else if (SIMPLE_SP_MODAL_TRIGGERS.includes(code)) {
        return (
          <SPModalButton
            code={code}
            value={value}
            SPModal={SPModal}
          />
        );
      } else if (LISTEN_SIMPLE_QUESTIONS.includes(code)) {
        return (
          <ListenSimpleQuestions
            code={code}
            value={value}
            setSPBin={setSPBin}
            requesterIin={requesterIin}
          />
        );
      } else if (SIMPLE_SCHOOL_QUESTIONS.includes(code)) {
        return (
          <SimpleSchool
            code={code}
            requesterIin={requesterIin}
          />
        );
      } else if (SIMPLE_COLLEGE_QUESTIONS.includes(code)) {
        return (
          <SimpleCollege
            code={code}
            value={value}
            requesterIin={requesterIin}
          />
        );
      } else if (SIMPLE_GUARDIANSHIP_QUESTIONS.includes(code)) {
        return (
          <SimpleGuardianship
            code={code}
            value={value}
            requesterIin={requesterIin}
          />
        );
      } else if (SIMPLE_UNIVER_QUESTIONS.includes(code)) {
        return (
          <SimpleUniver
            code={code}
            value={value}
            requesterIin={requesterIin}
          />
        );
      } else if (NCT_QUESTIONS.includes(code)) {
        return (
          <NCT
            code={code}
            requesterIin={requesterIin}
          />
        );
      } else if (SIMPLE_BUTTON_QUESTIONS.includes(code)) {
        return (
          <SimpleButton
            code={code}
            value={value}
            requesterIin={requesterIin}
          />
        );
      }
      break;
    default:
      return null;
  }
}
