import styled from 'styled-components';
import Colors from '../ThemeProvider/Colors';

export const StyledTitle = styled.div`
  position: sticky;
  top: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  background: ${Colors.secondary};
  padding: 40px;
    
  & div {
    font-weight: bold;
    font-size: 24px;
    text-transform: uppercase;
    margin: 0 30px;
    color: white;
    width: 60%;
  }
  
  & img {
    width: 130px;
    height: auto;
  }
  
  @media screen and (max-width: 600px) {
    flex-direction: column;
    padding: 20px;
    position: static;
    img {
      width: 100px;
    }
    div {
      font-size: 16px;
      text-align: center;
      margin: 20px 0 0 0;  
      width: 100%;
    }
  }
`;