import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';

import Modal from '../../../_ui/Modal/Modal';
import { StyledModalContainer, TableWrapper, TreeSelectWrapper } from './ServiceProvidersModalStyles';
import ServiceProvidersTable from './ServiceProvidersTable';
import useColumnFilter from '../../../components/TableFilter/useColumnFilter';
import { changeFormValue } from '../../../passport/Passport';
import TreeSelect from '../../../modules/TreeSelect/TreeSelect';
import useTree from '../../../modules/TableFilter/ColumnsFilters/FilterTreeSelect/useTree';
import { services } from '../../../_helpers/Constants';
import Colors from '../../../components/ThemeProvider/Colors';
import './ServiceProvidersTranslate';

const lookupId = 60;

function ServiceProvidersModal({ modal, setFormData, metadataKey, setSPBin }) {
  const { data, close } = modal;
  const { t, i18n } = useTranslation();
  const { value, setFilter } = useColumnFilter('extraFilters', 1);
  const parsedValue = JSON.parse(value) || {};
  const { tree, onExpand, loadingArr } = useTree({
    lookupId,
    value: parsedValue['locationCode']
  });

  const onClickRow = ({ original }) => {
    if (data.code) {
      if (
        [
          'request_form_college_short.name_orgSpec'
        ].includes(data.code)
      ) {
        setSPBin(original['bin']);
      }
      changeFormValue(data.code, original[`${i18n.language}_name`]);
    } else {
      setFormData({ ...original });
    }
    if (metadataKey === services.doc_queued_ddo_short.code) {
      changeFormValue('request_form_doc_queued_ddo_short.queued_region', parsedValue['locationCode']);
    } else if (metadataKey === services.children_adoption_short.code) {
      changeFormValue('request_form_children_adoption_short.guardianship_child_birthplace', parsedValue['locationCode']);
    } else if (metadataKey === services.children_disposable_short.code) {
      changeFormValue('request_form_children_disposable_short.court_decision_region', parsedValue['locationCode']);
    }
    modal.close();
  };

  useEffect(() => {
    if (data.code) {
      let location;
      if (data.code === 'request_form_college_short.name_orgSpec') {
        location = window.PassportForm.values['request_form_college_short.trusteeGuardian_city'];
        if (location) {
          setFilter(JSON.stringify({
            ...parsedValue,
            locationCode: location
          }));
        }
      } else if (data.code === 'request_form_contest_leaders_short.type_edu_org') {
        location = window.PassportForm.values['request_form_contest_leaders_short.reqion_contest'];
        setFilter(JSON.stringify({
          ...parsedValue,
          locationCode: location || '',
          serviceProviderType: data.value.code
        }));
      } else if (data.code === 'request_form_organization_type_short.name_orgSpec') {
        const translationType = 'request_form_organization_type_short.translation_type';
        const serviceProviderType = translationType === '165' ? '02' : '03';
        setFilter(JSON.stringify({
          ...parsedValue,
          serviceProviderType: serviceProviderType
        }));
      }
    } else if (
      [
        services.extern_study_short.code,
        services.children_turn_short.code,
        services.children_adoption_short.code,
        services.children_disposable_short
      ].includes(metadataKey)
    ) {
      setFilter(JSON.stringify({
        ...parsedValue,
        serviceProviderType: '13'
      }));
    } else if (
      [
        services.academ_mobile_short.code
      ].includes(metadataKey)
    ) {
      setFilter(JSON.stringify({
        ...parsedValue,
        serviceProviderType: '6'
      }));
    }
    // eslint-disable-next-line
  }, []);

  const disabled = [
    'request_form_college_short.name_orgSpec'
  ].includes(data.code);

  return (
    <Modal
      open
      maxWidth="lg"
      title={t('serviceProviders_title')}
      onClose={close}
      scroll="paper"
    >
      <DialogContent dividers>
        <div className="overflow-auto">
          <StyledModalContainer>
            <TreeSelectWrapper>
              <span>
                {t('serviceProviders_columnLocation')}
                <span style={{ color: Colors.primary }}>*</span>
              </span>
              <TreeSelect
                lookupId={lookupId}
                loadingArr={loadingArr}
                onExpand={onExpand}
                data={tree}
                withoutForm
                fullWidth
                onChange={(v) => setFilter(JSON.stringify({
                  ...parsedValue,
                  locationCode: v
                }))}
                disabled={disabled}
                value={parsedValue['locationCode']}
              />
            </TreeSelectWrapper>
            {value && (
              <TableWrapper>
                <ServiceProvidersTable
                  index={1}
                  config={{ isModal: true, setSelected: onClickRow }}
                  onClickRow={onClickRow}
                />
              </TableWrapper>
            )}
          </StyledModalContainer>
        </div>
      </DialogContent>
    </Modal>
  );
}

export default ServiceProvidersModal;
