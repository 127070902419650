import React, { useState } from 'react';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import LanguageIcon from '@material-ui/icons/Language';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';

import Colors from '../../ThemeProvider/Colors';
import { StyledSelectButton } from './HeaderStyle';

function LocaleButton() {
  const { t, i18n } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);

  return (
    <>
      <StyledSelectButton onClick={e => {
        setAnchorEl(e.currentTarget);
        setOpen(true);
      }}>
        <LanguageIcon className="icon left"/>
        {t(`header_lang_${i18n.language}`)}
        <ExpandMoreIcon className="icon"/>
      </StyledSelectButton>
      <Menu
        open={open}
        anchorEl={anchorEl}
        style={{ marginTop: 40 }}
        MenuListProps={{ style: { minWidth: 120 } }}
        onClose={() => setOpen(false)}
      >
        {
          i18n.language === 'ru' ?
            <MenuItem style={{ color: Colors.action }} onClick={() => {
              i18next.changeLanguage('kk');
              localStorage.setItem('lang', 'kk');
              setOpen(false);
            }}>
              <Typography children={t('header_lang_kk')}/>
            </MenuItem>
            :
            <MenuItem style={{ color: Colors.action }} onClick={() => {
              i18next.changeLanguage('ru');
              localStorage.setItem('lang', 'ru');
              setOpen(false);
            }}>
              <Typography children={t('header_lang_ru')}/>
            </MenuItem>
        }
      </Menu>
    </>
  );
}

export default LocaleButton;