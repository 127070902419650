import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeFormValue } from '../../../passport/Passport';

async function changeUniver({ code, t, setLoading, requesterIin, lang }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, univer, release;

    switch (code) {
      case 'request_form_university_grant_short.candidate_gop_fact':
        category = window.PassportForm.values['request_form_university_grant_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_university_grant_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН поступающего');
          }
        }
        response = await ServiceRequestApi.getUniversities(iin);
        if (response.status === 200) {
          univer = response.data.content.find(u => u['status'] === 0);

          if (univer) {
            if (!univer['schoolBin']) {
              return changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', false);
            }
            changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', true);
            return Notice.warning(t('studyAtTheMoment', { univer: univer[`schoolName${capitalize(lang)}`] }));
          }

          if (!univer) {
            univer = response.data.content.find(u =>
              u['status'] === 2 &&
              u.passportValues.find(q => q.code === 'StudiedAtTheExpense' && ['01', '10', '11', '12', '12.1', '13', '14', '15', '22', '31', '32', '34', '35', '36', '37'].includes(q.value.value))
            );
          }

          if (univer) {
            if (!univer['schoolBin']) {
              return changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', false);
            }
            changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', true);
            Notice.warning(t('finishedSameUniver', { univer: univer[`schoolName${capitalize(lang)}`] }));
          } else {
            changeFormValue('request_form_university_grant_short.candidate_gop_fact', '1');
            changeFormValue('request_form_university_grant_short.candidate_gop_fact_sys', false);
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_university_admission_short.HEI_Name':
        release = window.PassportForm.values['request_form_university_admission_short.releases_abroad_HEI'];
        if (release === false) {
          category = window.PassportForm.values['request_form_university_admission_short.user_category'];
          if (!category) {
            return Notice.info('Сначала выберите категорию заявителя');
          }
          iin = '';
          if (category === '003') {
            iin = requesterIin;
          } else {
            iin = window.PassportForm.values['request_form_university_admission_short.Child_iin'];
            if (!iin) {
              return Notice.info('Необходимо указать ИИН поступающего');
            }
          }

          response = await ServiceRequestApi.getUniversities(iin);
          if (response.status === 200) {
            univer = response.data.content.find(s => s['status'] === 2);

            if (!univer) {
              return Notice.info(t('gbdflButton_univerNotFound'));
            } else {
              const name = univer[`schoolName${capitalize(lang)}`];
              const sc = univer.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
              const dsn = univer.passportValues.find(q => q.code === 'DiplomaSeries');
              const cc = univer.passportValues.find(q => q.code === 'Cource/Class');
              const dn = univer.passportValues.find(q => q.code === 'DiplomaNumber');
              const dd = univer.passportValues.find(q => q.code === 'DateOfDisposal');
              const dt = univer.passportValues.find(q => q.code === 'DiplomaType');

              changeFormValue(`request_form_university_admission_short.HEI_Name`, name);
              changeFormValue(`request_form_university_admission_short.HEI_course`, cc ? cc.value.value : '');
              changeFormValue(`request_form_university_admission_short.HEI_date_finish`, dd ? dd.value : '');
              changeFormValue(`request_form_university_admission_short.HEI_specialty_N`, sc ? sc.value.value : '');
              changeFormValue(`request_form_university_admission_short.HEI_doc_series`, dsn ? dsn.value : '');
              changeFormValue(`request_form_university_admission_short.HEI_doc_number`, dn ? dn.value : '');
              if (dt && ['1', '2'].includes(dt.value.value)) {
                changeFormValue(`request_form_university_admission_short.diploma_HEI_honors`, dt.value.value === '1');
              }
            }
          } else {
            handleError(response, t('dataNotFound'));
          }
        } else {
          Notice.info('Запрос данных работает только для выпускников учебного заведения внутри страны Казахстан');
        }
        break;
      case 'request_form_academ_mobile_short.name_univ':
        release = window.PassportForm.values['request_form_academ_mobile_short.Resident_sign'];
        if (release === '002') {
          return Notice.info('Запрос данных работает только резидентов РК');
        }
        category = window.PassportForm.values['request_form_academ_mobile_short.category_appliciant'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        if (['001', '002'].includes(category)) {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_academ_mobile_short.student_IIN'];
        }
        if (!iin) {
          return Notice.info('Заполните поле "ИИН студента"');
        }
        response = await ServiceRequestApi.getUniversities(iin);
        if (response.status === 200) {
          univer = response.data.content.find(s => s['status'] === 0);

          if (!univer) {
            return Notice.info(t('gbdflButton_currentEduNotFound'));
          } else {
            const name = univer[`schoolName${capitalize(lang)}`];
            const sc = univer.passportValues.find(q => q.code === 'SpecialtyAndClassifier');
            const da = univer.passportValues.find(q => q.code === 'DateOfArrival');

            changeFormValue(`request_form_academ_mobile_short.name_univ`, name);
            changeFormValue(`request_form_academ_mobile_short.spec_univer`, sc ? sc.value.value : '');
            changeFormValue(`request_form_academ_mobile_short.year_univer`, da ? da.value : '');
          }
        }
        break;
      default:
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function SimpleUniver({ code, requesterIin, value }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);

  if (code === 'request_form_university_grant_short.candidate_gop_fact' && !value) {
    return null;
  }

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeUniver({
          t,
          code,
          setLoading,
          requesterIin,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default SimpleUniver;
