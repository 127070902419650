import React from 'react';

import FilterInputField from '../../../modules/TableFilter/ColumnsFilters/FilterInputField';

export const serviceProvidersColumns = (
  t,
  lang
) => {
  return [
    {
      Header: t('serviceProviders_columnName'),
      Cell: ({ original }) => original[`${lang}_name`],
      Filter: <FilterInputField name={`${lang}Name`} type="text"/>
    }
  ];
};
