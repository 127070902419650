import React from 'react';
import { useTranslation } from 'react-i18next';

import { FooterText } from './FooterStyle';

function Footer() {
  const { t } = useTranslation();

  return (
    <FooterText id="footer">
      {t('home_title')}
    </FooterText>
  );
}

export default Footer;
