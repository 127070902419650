import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Button from '@material-ui/core/Button';

import { StyledServiceCard } from './ServiceCardStyles';
import { history } from '../../../_helpers/history';
import useUserInfo from '../../../components/_hooks/useUserInfo';
import { loginModalHandler } from '../../LoginPage/LoginDucks';
import './ServiceCardTranslate';

function ServiceCard({ metadata }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const user = useUserInfo();

  return (
    <StyledServiceCard>
      <div className="text">
        {metadata[`${i18n.language}_name`]}
      </div>
      <div className="button-container">
        {
          user.username
            ? <Button
              variant="contained"
              color="secondary"
              target={metadata.url && '_blank'}
              onClick={() => {
                if (!metadata.url) {
                  history.push(`/services/create/${metadata.code}`);
                }
              }}
              href={metadata.url}
              children={t('serviceCard_button')}
            />
            : <Button
              variant="contained"
              color="secondary"
              onClick={() => dispatch(loginModalHandler(true, metadata.code))}
              children={t('serviceCard_button')}
            />
        }
      </div>
    </StyledServiceCard>
  );
}

export default ServiceCard;