import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '../../modules/Button/Button';
import { ServiceRequestApi } from '../ServiceRequest/ServiceRequestApi';
import { changeFormValue } from '../../passport/Passport';
import { handleError } from '../../modules/utils/handleError';
import Dict from '../../modules/utils/Dict';

function GBDFLButton({ iin }) {
  const [loading, setLoading] = useState(false);
  const { t, i18n } = useTranslation();

  async function searchGBDFL() {
    setLoading(true);
    try {
      const { data } = await ServiceRequestApi.searchGbdfl(iin);
      if (data.status === 'SUCCESS') {
        const user = data.result;
        const countryName = (await Dict.item('38934', user['regAddressCountryCode']))[`${i18n.language}_name`];
        changeFormValue(`requester_short.user_surname`, user['lastName']);
        changeFormValue(`requester_short.user_name`, user['firstName']);
        changeFormValue(`requester_short.user_middlename`, user['middleName']);
        changeFormValue(`requester_short.user_birthday`, user['birthDate']);
        changeFormValue(`requester_short.country`, countryName);
        changeFormValue(`requester_short.area`, user['regAddressDistrictsCode']);
        changeFormValue(`requester_short.region`, user['regAddressRegionCode']);
        changeFormValue(`requester_short.city`, user['regAddressCity']);
        changeFormValue(`requester_short.street`, user['regAddressStreet']);
        changeFormValue(`requester_short.house`, user['regAddressBuilding']);
        changeFormValue(`requester_short.corpus_number`, user['regAddressCorpus']);
        changeFormValue(`requester_short.flat`, user['regAddressFlat']);
        let docInfo = user['personalDocuments'].find(doc => doc['typeCode'] === '002' && doc['statusCode'] === '00');
        if (!docInfo) {
          docInfo = user['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
        }
        if (!docInfo) {
          docInfo = user['personalDocuments'][0];
        }
        if (docInfo) {
          changeFormValue(`requester_short.doc_type`, docInfo['typeCode']);
          changeFormValue(`requester_short.doc_number`, docInfo['number']);
          changeFormValue(`requester_short.doc_issuedate`, docInfo['beginDate']);
          changeFormValue(`requester_short.doc_expirationdate`, docInfo['endDate']);
          changeFormValue(`requester_short.doc_organ_rname`, docInfo['issueOrganizationCode']);
        }
      }
    } catch (error) {
      handleError(error, t('accountSettings_updateFailed'));
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className="right-align mb2">
      <Button
        text={t('accountSettings_update')}
        loading={loading}
        color="secondary"
        onClick={searchGBDFL}
      />
    </div>
  );
}

export default GBDFLButton;