import React from 'react';
import { useTranslation } from 'react-i18next';

import Row from '../../../_ui/Row/Row';
import Button from '../../../modules/Button/Button';
import { ServiceProviderContainer } from '../ServiceRequestStyles';
import { services } from '../../../_helpers/Constants';

function switchServiceProviderLabel(metadataKey, t) {
  switch (metadataKey) {
    /*case services.payment_patronage_short.code:
      return 'Местный исполнительный орган';*/
    case services.doc_acceptance_ddo_short.code:
      return t('serviceRequest_ddo');
    default:
      return t('serviceRequest_serviceProvider');
  }
}

function SPPicker(
  {
    serviceProvider,
    SPModal,
    metadataKey
  }
) {
  const { t, i18n } = useTranslation();
  let serviceProviderLabel = switchServiceProviderLabel(metadataKey, t);

  return (
    <ServiceProviderContainer error={!serviceProvider}>
      <Row
        className="flex items-center service-provider"
        label={
          <>
            {serviceProviderLabel}
            <span id="required">*</span>
          </>
        }
        children={
          <div id="sp-button">
            <Button
              fullWidth
              color="default"
              variant="outlined"
              text={
                serviceProvider
                  ? serviceProvider[`${i18n.language}_name`]
                  : t('serviceRequest_chooseServiceProvider')
              }
              onClick={() => SPModal.open(true)}
            />
            {!serviceProvider && (
              <span id="error">{t('requiredField')}</span>
            )}
          </div>
        }
      />
    </ServiceProviderContainer>
  );
}

export default SPPicker;