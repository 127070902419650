import React, { useEffect, useReducer, createContext, useState } from 'react';
import { reducer, initialState, initPassport } from './PassportStore';
import { ErrorMessage, PassportWrapper, LoadingWrapper } from './PassportStyle';
import Loading from './Components/Loading/Loading';
import Form from './FormData/PassportForm';
import ThemeProvider from './Components/ThemeProvider/ThemeProvider';
import { hasPassportData } from './Utils/hasPassportData';

/**
 * Пример для задачи mimeType и maxSize для определенных документов
 * documentsProps = {
 *    mimeType - для всех документов
 *    maxSize - для всех документов
 *    multiple - для всех документов
 *   'questionCode': {
 *     multiple: true (это пока только для tooltip инфо)
 *     mimeType: 'image/jpeg,image/png,image/gif'
 *     maxSize: 1000000 (в байтах)
 *   }
 * }
 */
const defaultConfig = { withoutScroll: true, documentsProps: {} };
const defaultQuestionRender = null;

export const PassportContext = createContext(initialState);
export const ConfigContext = createContext(defaultConfig);
export const QuestionRender = createContext(defaultQuestionRender);

function PassportProvider({
  info,
  data: dataM,
  meta: metaM,
  children,
  withoutScroll = true,
  stickyTop,
  config: configParams,
  questionRender
}) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const hideExpander = state.error || state.groups.length === 0 || state.loading;
  const [data, setData] = useState(dataM);
  const [meta, setMeta] = useState(metaM);
  const [config, setConfig] = useState({
    withoutScroll,
    stickyTop,
    documentsProps: {},
    ...(configParams || {})
  });
  useEffect(
    () => {
      if (JSON.stringify(dataM) !== JSON.stringify(data)) {
        setData(dataM);
      }
      if (JSON.stringify(metaM) !== JSON.stringify(meta)) {
        setMeta(metaM);
      }
    },
    // eslint-disable-next-line
    [dataM, metaM]
  );

  useEffect(
    () =>
      setConfig({ withoutScroll, stickyTop, documentsProps: {}, ...(configParams || {}) }),
    // eslint-disable-next-line
    [configParams]
  );

  useEffect(() => {
    if (hasPassportData(data, meta)) {
      if (data instanceof Array && meta instanceof Array) {
        let allData = [];
        for (const dataItem of data) {
          let item = { passport: dataItem };
          for (const metaItem of meta) {
            if (dataItem.metadataKey === metaItem.id) {
              item.metaData = metaItem;
            }
          }
          allData.push(item);
        }
        initPassport(allData)(dispatch);
      } else {
        initPassport([{ metaData: meta, passport: data }])(dispatch);
      }
    }
  }, [data, meta]);

  useEffect(() => {
    if (!state.loading && info) Form.initInfo(info);
  }, [info, state.loading]);

  if (state.loading) {
    return (
      <PassportWrapper minHeight="300px">
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      </PassportWrapper>
    );
  }

  if (state.error) {
    return (
      <PassportWrapper>
        <ErrorMessage>
          <code>{state.error}</code>
        </ErrorMessage>
      </PassportWrapper>
    );
  }

  return (
    <PassportContext.Provider value={{ ...state, hideExpander }}>
      <ConfigContext.Provider value={config}>
        <QuestionRender.Provider value={questionRender}>
          <ThemeProvider>{children}</ThemeProvider>
        </QuestionRender.Provider>
      </ConfigContext.Provider>
    </PassportContext.Provider>
  );
}

export default PassportProvider;
