import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch, withRouter } from 'react-router-dom';
import NotificationContainer from 'react-notifications-component';

import ProtectedRoute from './ProtectedRoute';
import Page404 from '../pages/Page404/Page404';
import routerProps, { sideBarPaths } from '../_helpers/routerProps';
import Header from '../components/Header/Header';
import { Content } from '../components/ThemeProvider/ThemeStyle';
import Footer from '../components/Footer/Footer';
import SideBar from '../components/Header/SideBar/SideBar';
import { checkLogin, loginModule } from '../pages/LoginPage/LoginDucks';
import paths from '../_helpers/paths';
import { history } from '../_helpers/history';
import Login from '../pages/LoginPage/Login';
import TokenRefresher from '../modules/TokenRefresher/TokenRefresher';

function Root({ checkLogin, requester, ...props }) {
  const withoutRequiredFields = Boolean(localStorage.getItem('withoutRequiredFields'));
  if (
    !withoutRequiredFields &&
    requester && (!requester['user_email'] || !requester['user_telephone_number']) &&
    props.location.pathname !== paths.accountSettingsPage
  ) {
    history.push(paths.accountSettingsPage);
  }

  useEffect(() => {
    checkLogin();
  }, [checkLogin]);

  return (
    <>
      <NotificationContainer className="left-0 top-0"/>
      {props.user && <TokenRefresher/>}
      <Header/>
      <Login/>
      <Switch>
        <Content>
          <Route exact path={sideBarPaths} component={SideBar}/>
          <ProtectedRoute exact {...props} {...routerProps.homePage}/>
          <ProtectedRoute exact {...props} {...routerProps.createRequestPage}/>
          <ProtectedRoute exact {...props} {...routerProps.accountPage}/>
          <ProtectedRoute exact {...props} {...routerProps.requestsPage}/>
          <ProtectedRoute exact {...props} {...routerProps.completedRequestsPage}/>
          <ProtectedRoute exact {...props} {...routerProps.accountSettingsPage}/>
          <ProtectedRoute exact {...props} {...routerProps.viewRequestFormPage}/>
          <ProtectedRoute exact {...props} {...routerProps.supportPage}/>
          <ProtectedRoute exact {...props} {...routerProps.instructionsPage}/>
        </Content>
        <Route component={Page404}/>
      </Switch>
      <Footer/>
    </>
  );
}

export default connect(
  state => ({
    user: state[loginModule].user,
    requester: state[loginModule].requester,
    userPermissions: state[loginModule].permissions,
    loading: state[loginModule].loading
  }),
  { checkLogin }
)(withRouter(Root));
