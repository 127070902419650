import { addTranslation } from '../../../_helpers/Translate';

addTranslation({
  ru: {
    sidebar_home: 'Главная',
    sidebar_users: 'Пользователи',
    sidebar_exit: 'Выход',
    sidebar_requests: 'Заявки в обработке',
    sidebar_completedRequests: 'Завершенные заявки',
    sidebar_account: 'Управление профилем',
  },
  kk: {
    sidebar_home: 'Главная',
    sidebar_users: 'Пользователи',
    sidebar_exit: 'Выход',
    sidebar_requests: 'Өңдеудегі өтінімдер',
    sidebar_completedRequests: 'Аяқталған өтінімдер',
    sidebar_account: 'Профильді басқару',
  }
});
