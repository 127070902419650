import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React from 'react';
import styled from 'styled-components';

const IconWrapper = styled.div`
  width: 68px;
  background-color: #e0dcff;
  height: 48px;
  border-top-right-radius: inherit;
  border-bottom-right-radius: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const IconComponent = ({ value, onChange }) => {
  return (
    <IconWrapper>
      {value ? (
        <CloseIcon className="pointer" fontSize="small" onClick={() => onChange(null)} />
      ) : (
        <ExpandMoreIcon style={{ fontSize: 25 }} />
      )}
    </IconWrapper>
  );
};

export default IconComponent;
