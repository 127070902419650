import { createReducer } from 'redux-starter-kit';
import i18next from 'i18next';
import _ from 'lodash';

import { history } from '../../_helpers/history';
import routerProps from '../../_helpers/routerProps';
import paths from '../../_helpers/paths';
import permissionsMap from '../../_helpers/permissionsMap';
import { LoginApi } from './LoginApi';
import Notice from '../../modules/utils/Notice';
import { handleError } from '../../modules/utils/handleError';
import { NCALayerSign } from '../../modules/NCALayer/NCALayerSign';

/**
 * Constants
 */

export const loginModule = 'login';
const USER = `${loginModule}/USER`;
const LOGOUT = `${loginModule}/LOGOUT`;
const LOADING = `${loginModule}/LOADING`;
const LOADING_LOGIN = `${loginModule}/LOADING_LOGIN`;
const LOGIN_MODAL_HANDLER = `${loginModule}/LOGIN_MODAL_HANDLER`;
const REQUESTER = `${loginModule}/REQUESTER`;

/**
 * Reducer
 */

const initialState = {
  loadingLogin: false,
  loading: true,
  user: null,
  permissions: [],
  isOpen: false,
  requester: null,
  requesterData: null,
  metadataKey: null
};

export default createReducer(initialState, {
  [USER]: (state, action) => {
    state.user = action.user;
    state.permissions = action.permissions;
  },
  [LOADING]: (state, action) => {
    state.loading = action.loading;
  },
  [LOADING_LOGIN]: (state, action) => {
    state.loadingLogin = action.loadingLogin;
  },
  [LOGOUT]: state => {
    state.user = null;
    state.requester = null;
    state.requesterData = null;
  },
  [LOGIN_MODAL_HANDLER]: (state, action) => {
    state.isOpen = action.isOpen;
    state.metadataKey = action.metadataKey;
  },
  [REQUESTER]: (state, action) => {
    state.requester = action.requester;
    state.requesterData = action.requesterData;
  }
});

/**
 * Actions
 */

export const loginWithECP = method => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_LOGIN, loadingLogin: true });
    let { data } = await LoginApi.getStringToSign();
    const signedXml = await NCALayerSign(data.signatureAuthToken, true, method, false);
    if (signedXml) {
      const { data: token } = await LoginApi.loginWithSign({ signature: signedXml });
      localStorage.setItem('accessToken', token.accessToken);
      localStorage.setItem('refreshToken', token.refreshToken);
      localStorage.setItem('expiredAt', Date.now() + 600000);
      await dispatch(setUser(token.user));
      await dispatch(setUserRequester());
      const { requester, metadataKey } = getState().login;
      dispatch({ type: LOADING, loading: false });
      dispatch({ type: LOGIN_MODAL_HANDLER, isOpen: false, metadataKey: null });
      const withoutRequiredFields = Boolean(localStorage.getItem('withoutRequiredFields'));
      if (!withoutRequiredFields && (!requester['user_email'] || !requester['user_telephone_number'])) {
        return history.push(paths.accountSettingsPage);
      }
      if (metadataKey) {
        history.push(`/services/create/${metadataKey}`);
      }
    }
  } catch (error) {
    const status = _.get(error, 'response.data.status');
    if (status === 'INCORRECT_USERNAME_PASSWORD') {
      Notice.error(i18next.t('loginDucks_userNotFound'));
    } else if (status === 'SIGNATURE_VERIFICATION_FAIL') {
      Notice.error(i18next.t('loginDucks_signatureVerificationFail'));
    } else if (status === 'USER_NOT_ACTIVE') {
      Notice.error(i18next.t('loginDucks_userNotActive'));
    } else {
      handleError(error, i18next.t('loginDucks_authError'));
    }
  } finally {
    dispatch({ type: LOADING_LOGIN, loadingLogin: false });
  }
};

export const login = params => async (dispatch, getState) => {
  try {
    dispatch({ type: LOADING_LOGIN, loadingLogin: true });
    let { data } = await LoginApi.login(params);
    localStorage.setItem('accessToken', data.accessToken);
    localStorage.setItem('refreshToken', data.refreshToken);
    localStorage.setItem('expiredAt', Date.now() + 600000);
    await dispatch(setUser(data.user));
    await dispatch(setUserRequester());
    const { requester, metadataKey } = getState().login;
    dispatch({ type: LOADING, loading: false });
    dispatch({ type: LOGIN_MODAL_HANDLER, isOpen: false, metadataKey: null });
    const withoutRequiredFields = Boolean(localStorage.getItem('withoutRequiredFields'));
    if (!withoutRequiredFields && (!requester['user_email'] || !requester['user_telephone_number'])) {
      return history.push(paths.accountSettingsPage);
    }
    if (metadataKey) {
      history.push(`/services/create/${metadataKey}`);
    }
  } catch (error) {
    const status = _.get(error, 'response.data.status');
    if (status === 'INCORRECT_USERNAME_PASSWORD') {
      Notice.error(i18next.t('loginDucks_incorrectUsernamePassword'));
    } else if (status === 'USER_NOT_ACTIVE') {
      Notice.error(i18next.t('loginDucks_userNotActive'));
    } else {
      handleError(error, i18next.t('loginDucks_authError'));
    }
  } finally {
    dispatch({ type: LOADING_LOGIN, loadingLogin: false });
  }
};

export const checkLogin = () => async dispatch => {
  try {
    dispatch({ type: LOADING, loading: true });
    let expiredAt = localStorage.getItem('expiredAt');
    if (!expiredAt || expiredAt < Date.now() + 10000) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('expiredAt');
    }
    const { data } = await LoginApi.getUser();
    await dispatch(setUser(data.user));
    await dispatch(setUserRequester());
    dispatch({ type: LOADING, loading: false });
  } catch (error) {
    let location = window.location;
    for (const route of Object.values(routerProps)) {
      if (route.path === location.pathname && !route.withoutAuth) {
        window.addressBarUrl = `${location.pathname}${location.search}`;
        history.push(paths.homePage);
      }
    }
    dispatch({ type: LOADING, loading: false });
  }
};

export const setUser = user => async dispatch => {
  let permissions = [];
  let profileData = {};

  // collect permissions
  for (const group of user.groups || []) {
    for (const permission of group.items || []) {
      permissionsMap(group.name, permission.name, permissions);
    }
  }

  dispatch({
    type: USER,
    user: { ...user, ...profileData },
    permissions
  });
};

export const setUserRequester = () => async dispatch => {
  const { data } = await LoginApi.getRequesterData();

  const questions = data.questionnaire.questionCodeToAnswers;
  const values = {};

  for (let key in questions) {
    if (
      questions[key] &&
      questions[key].values &&
      questions[key].values[0] &&
      questions[key].values[0].value
    ) {
      values[key] = questions[key].values[0].value;
    }
  }

  dispatch({
    type: REQUESTER,
    requester: {
      id: data.id,
      iin: data.iin,
      ...values
    },
    requesterData: data
  });
};

export const logout = () => async dispatch => {
  try {
    dispatch({ type: LOGOUT });
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    localStorage.removeItem('expiredAt');
    window.addressBarUrl = undefined;
    await LoginApi.logout();
  } catch (e) {
    // ignore
  }
};

export const loginModalHandler = (isOpen, metadataKey) => dispatch => {
  dispatch({ type: LOGIN_MODAL_HANDLER, isOpen, metadataKey });
};
