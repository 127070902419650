import axios from 'axios';

export const LoginApi = {
  login: credentials =>
    axios.post('/login', credentials),

  logout: () => axios.get('/logout'),

  getStringToSign: () => axios.get('/login/signature'),

  loginWithSign: data => axios.post('/login/signature', data),

  getUser: () => axios.get('/login'),

  getRequesterData: () => axios.get(
    `/api/self/requester`
  )
};
