import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import Paper from '@material-ui/core/Paper';
import Avatar from '@material-ui/core/Avatar';

import { sideBarItems } from './SideBarItems';
import { useStyles, Wrapper } from './SideBarStyles';
import useUserInfo from '../../_hooks/useUserInfo';
import './SideBarTranslate';

export function getFullName(user) {
  const userFullName = [user.lastName, user.firstName, user.middleName];
  return userFullName
    .filter(n => n)
    .map(n => n.charAt(0).toUpperCase() + n.slice(1).toLowerCase())
    .join(' ');
}

const SideBar = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const location = useLocation();
  const user = useUserInfo();

  return (
    <div className="flex">
      <Wrapper>
        <Paper className={classes.paper}>
          <div className="flex items-center">
            {user.username && (
              <Avatar className={classes.avatar} src="/head_avatar.png"/>
            )}
            <div className="ml2">
              {user.username && (
                <Typography
                  color="textPrimary"
                  className={classes.userName}
                  children={getFullName(user)}
                />
              )}
            </div>
          </div>
        </Paper>
        <List className={classes.menu}>
          {sideBarItems.map(({ path, translate, icon }) => (
            <Link to={path} style={{ textDecoration: 'none' }} key={path}>
              <ListItem
                key={path}
                selected={location.pathname === path}
                button
                className={classes.menuItem}
              >
                <ListItemIcon className={classes.icon} children={icon}/>
                <Typography noWrap children={t(translate)}/>
              </ListItem>
            </Link>
          ))}
        </List>
      </Wrapper>
    </div>
  );
};

export default SideBar;
