import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import capitalize from '@material-ui/core/utils/capitalize';

import StaticTablePaginated from '../../_ui/Table/StaticTablePaginated';
import { requestsColumns } from './RequestsColumns';
import { getRequests, requestsModule } from './RequestsDucks';
import useTableFilter from '../../modules/TableFilter/useTableFilter';
import { history } from '../../_helpers/history';
import { translation } from '../../_helpers/Translate';
import { LightTooltip, StyledButton, StyledTableContainer } from './RequestsStyles';
import { handleError } from '../../modules/utils/handleError';
import { RequestsApi } from './RequestsApi';
import Colors from '../../components/ThemeProvider/Colors';
import { services } from '../../_helpers/Constants';
import './RequestsTranslate';

export async function downloadReportForm({ name, id, reportId, resetId }) {
  try {
    const { data } = await RequestsApi.downloadReportForm(id, reportId);
    let element = document.createElement('a');
    document.body.appendChild(element);
    element.style.display = 'none';

    let blob = await new Blob([data], { type: 'application/download' });
    let url = window.URL.createObjectURL(blob);
    element.href = url;
    element.download = `${name}.pdf`;
    element.click();
    window.URL.revokeObjectURL(url);
    element.remove();
  } catch (error) {
    const fileReader = new FileReader();
    fileReader.readAsText(error.response.data);
    fileReader.onload = function() {
      handleError(
        {
          ...error.response,
          data: JSON.parse(this.result)
        },
        translation('downloadReportForm_failed')
      );
    };
  } finally {
    resetId();
  }
}

export function TooltipContent({ reports, lang }) {
  const [id, setId] = useState(null);

  return (
    <div className="flex flex-column p1" style={{ width: '150px' }}>
      {reports.map((item, key) => (
        <div key={key}>
          {key !== 0 && <div className="mt1"/>}
          <StyledButton
            fullWidth
            size="small"
            variant="outlined"
            loading={id === item['id']}
            text={item[`description${capitalize(lang)}`] || item['exportFormName']}
            onClick={async e => {
              e.stopPropagation();
              setId(item['id']);
              await downloadReportForm({
                name: item[`description${capitalize(lang)}`] || item['exportFormName'],
                id: item['requestFormId'],
                reportId: item['exportFormName'],
                resetId: () => setId(null)
              });
            }}
          />
        </div>
      ))}
    </div>
  );
}

function Requests({ loading, data, getRequests }) {
  const { t, i18n } = useTranslation();
  const { filter } = useTableFilter(getRequests);

  const extraColumns = [
    {
      Header: translation('requests_notifications'),
      accessor: 'accessedExportForms',
      style: { textAlign: 'center' },
      width: 200,
      Cell: ({ value }) => value.length ? (
        <LightTooltip
          interactive
          title={<TooltipContent reports={value} lang={i18n.language}/>}
        >
          <div
            onClick={e => e.stopPropagation()}
            style={{ color: Colors.primary }}
          >
            {t('completedRequests_download')}
          </div>
        </LightTooltip>
      ) : null
    }
  ];

  return (
    <div className="mb3 mt3 fullWidth">
      <Typography
        children={t('requests_title')}
        color="secondary"
        variant="h6"
      />
      <StyledTableContainer>
        <div className="mt2">
          <StaticTablePaginated
            pageNumber={data.pageNumber}
            loading={loading}
            data={data.content}
            totalElements={data.totalElements}
            columns={requestsColumns({
              pageNumber: data.pageNumber,
              extraColumns,
              lang: i18n.language
            })}
            filterable={false}
            onClickRow={({ original }) => {
              if (services[original.serviceType]) {
                history.push(`/view/requests/${original.id}`);
              }
            }}
            pagePagination={page => {
              if (page !== data.pageNumber) {
                getRequests({
                  ...filter,
                  pageNumber: page
                });
              }
            }}
          />
        </div>
      </StyledTableContainer>
    </div>
  );
}

export default connect(
  state => ({
    data: state[requestsModule].tableData,
    loading: state[requestsModule].loadingTable
  }),
  {
    getRequests
  }
)(Requests);