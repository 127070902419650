import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledCardsContainer, StyledSubtitle } from './HomeStyles';
import ServiceCard from './ServiceCard/ServiceCard';
import { services } from '../../_helpers/Constants';
import './HomeTranslate';

function Home() {
  const { t } = useTranslation();
  return (
    <div className="mb3 mt3 fullWidth">
      <StyledSubtitle
        children={t('home_subtitle')}
      />
      <StyledCardsContainer>
        {Object.values(services).map(metadata => !metadata.isHidden && (
          <ServiceCard
            key={metadata.code}
            metadata={metadata}
          />
        ))}
      </StyledCardsContainer>
    </div>
  );
}

export default Home;