import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DialogContent from '@material-ui/core/DialogContent';

import Modal from '../../../_ui/Modal/Modal';
import {
  StyledModalContainer,
  TableWrapper,
  TreeSelectWrapper
} from '../ServiceProvidersModal/ServiceProvidersModalStyles';
import TreeSelect from '../../../modules/TreeSelect/TreeSelect';
import useColumnFilter from '../../../components/TableFilter/useColumnFilter';
import useTree from '../../../modules/TableFilter/ColumnsFilters/FilterTreeSelect/useTree';
import { loadOrganizations, organizationsModule, clearState } from './OrganizationsDucks';
import TableFilterProvider from '../../../modules/TableFilter/TableFilterProvider';
import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';
import useTableFilter from '../../../modules/TableFilter/useTableFilter';
import { columns } from './OrganizationsColumns';
import { changeComplexValue } from '../../../passport/Passport';
import './OrganizationsTranslate';

const lookupId = 60;

function OrganizationsModal({ data: { code }, close, clearState, loadOrganizations, tableData, loading }) {
  const { t, i18n } = useTranslation();
  const { value, setFilter: setKatoFilter } = useColumnFilter('location');
  const { tree, onExpand, loadingArr } = useTree({
    value,
    lookupId
  });
  const filter = useTableFilter(params => loadOrganizations(params, code));

  useEffect(() => {
    let kato = '';
    if (code.questionCode === 'name_of_university') {
      kato = window.PassportForm.cellsValues['request_form_international_edu_short.education_list'][code.complexCode]['location_university'];
    } else {
      kato = window.PassportForm.cellsValues['request_form_international_edu_short.info_edu'][code.complexCode]['location_school'];
    }
    if (kato) {
      setKatoFilter(kato);
    }
    return () => clearState();
    // eslint-disable-next-line
  }, []);

  return (
    <Modal
      open
      maxWidth="lg"
      title={t('oModal_title')}
      onClose={close}
      scroll="paper"
    >
      <DialogContent dividers>
        <div className="overflow-auto">
          <StyledModalContainer>
            <TreeSelectWrapper>
              <span>{t('oModal_location')}</span>
              <TreeSelect
                lookupId={lookupId}
                loadingArr={loadingArr}
                onExpand={onExpand}
                data={tree}
                withoutForm
                fullWidth
                onChange={(v) => setKatoFilter(v)}
                value={value}
              />
            </TreeSelectWrapper>
            <TableWrapper>
              <TableFilterProvider>
                <StaticTablePagination
                  loading={loading}
                  columns={columns({ t, lang: i18n.language })}
                  data={tableData.content}
                  totalElements={tableData.totalElements}
                  onClickLoadMore={filter.nextPage}
                  onClickRow={({ original }) => {
                    changeComplexValue(code.code, {
                      [code.complexCode]: {
                        [code.questionCode]: original[`${i18n.language}_name`]
                      }
                    });
                    close();
                  }}
                  filterable={false}
                />
              </TableFilterProvider>
            </TableWrapper>
          </StyledModalContainer>
        </div>
      </DialogContent>
    </Modal>
  );
}

export default connect(
  state => ({
    loading: state[organizationsModule].loading,
    tableData: state[organizationsModule].tableData
  }),
  {
    loadOrganizations,
    clearState
  }
)(OrganizationsModal);