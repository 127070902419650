import React from 'react';
import ReactTable, { ReactTableDefaults } from 'react-table';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import withTranslation from '../../components/_hoc/withTranslation';
import Loading from '../../modules/Loading/CircularLoading';
import { Div, LoadingBox } from './TableStyle';
import { Link } from 'react-router-dom';
import Input from '../Input/Input';
import './TableTranslate'

function TrComponent(props) {
  if (props.className && props.link) {
    return (
      <Link
        to={props.link}
        {...props}
        className={`rt-tr ${props.className ? props.className : ''}`}
      />
    );
  } else {
    return <ReactTableDefaults.TrComponent {...props} />;
  }
}

const ReactTableWrapper = props => {
  const {
    loading,
    data,
    columns,
    onClickRow,
    pageSize,
    markText,
    urlOnClick,
    rowBgColor,
    t,
    ...restProps
  } = props;

  const getMarkedText = currentText => {
    if (markText && currentText) {
      currentText = String(currentText);
      let arrText = currentText.split(markText);
      return arrText.map((text, index) => {
        if (index !== arrText.length - 1) {
          return (
            <span key={index}>
              {text}
              <span style={{ backgroundColor: 'yellow' }}>{markText}</span>
            </span>
          );
        } else {
          return <span key={index}>{text}</span>;
        }
      });
    }
    return <span>{currentText}</span>;
  };

  const getColumns = () => {
    let newColumns = [];
    // eslint-disable-next-line
    for (let column of columns) {
      const filter = column.hasOwnProperty('Filter')
        ? { Filter: column.Filter }
        : !column.hasOwnProperty('filterable')
        ? {
            Filter: ({ filter, onChange }) => (
              <Input
                withoutForm
                name={column.accessor}
                onChange={({ target }) => onChange(target.value)}
                value={filter ? filter.value : ''}
              />
            )
          }
        : {};

      let newColumn = {
        Cell: row =>
          column.accessor === 'rowNumber'
            ? data.indexOf(row.original) + 1
            : getMarkedText(row.original[column.accessor]),
        ...column,
        ...filter,
        Header: () => (
          <div>
            {column.Header}
            {column.sortable === true && (
              <span className="rt-sort">
                <div style={{ marginBottom: -10 }}>
                  <ArrowDropUpIcon />
                </div>
                <div>
                  <ArrowDropDownIcon />
                </div>
              </span>
            )}
          </div>
        )
      };
      if (typeof column.accessor === 'function') {
        delete newColumn.Cell;
        newColumn.accessor = row => {
          let text = column.accessor(row);
          return getMarkedText(text);
        };
      }
      newColumns.push(newColumn);
    }
    return newColumns;
  };
  return (
    <Div>
      <ReactTable
        NoDataComponent={() => {
          if (loading) {
            return null;
          } else {
            return (
              <LoadingBox className="-loading -active">
                <div className="-loading-inner">{t('staticTable_selectNoOptions')}</div>
              </LoadingBox>
            );
          }
        }}
        TrComponent={TrComponent}
        LoadingComponent={data => {
          if (!data.loading) return null;
          return (
            <LoadingBox className="-loading -active">
              <div className="-loading-inner">
                <Loading />
              </div>
            </LoadingBox>
          );
        }}
        getTrProps={(props, object) => {
          let link;
          let bgColor;
          if (typeof urlOnClick === 'function' && object) {
            link = urlOnClick(object);
          }
          if (typeof rowBgColor === 'function' && object) {
            bgColor = rowBgColor(object);
          }
          return {
            onClick() {
              if (typeof onClickRow === 'function') {
                onClickRow(object);
              }
            },
            style: {
              cursor:
                typeof onClickRow === 'function' || typeof urlOnClick === 'function'
                  ? 'pointer'
                  : 'default',
              textDecoration: 'none',
              backgroundColor: bgColor
            },
            link: link
          };
        }}
        showPaginationBottom={false}
        data={data}
        pageSize={pageSize}
        defaultFilterMethod={(filter, row) => {
          return (
            String(row[filter.id])
              .toLowerCase()
              .indexOf(filter.value.toLowerCase()) !== -1
          );
        }}
        columns={getColumns()}
        loading={loading}
        {...restProps}
      />
    </Div>
  );
};

export default withTranslation(ReactTableWrapper);
