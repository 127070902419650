import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { changeFormValue } from '../../../passport/Passport';
import Notice from '../../../modules/utils/Notice';
import Dict from '../../../modules/utils/Dict';
import { ServiceRequestApi } from '../ServiceRequestApi';
import _ from 'lodash';
import { handleError } from '../../../modules/utils/handleError';

const request = async ({ setLoading, code, value, t, lang, setPrevIin, requesterIin }) => {
  try {
    setLoading(true);
    let response, user, doc, iin;

    switch (code) {
      case 'request_form_graduate_admission_short.student_iin':
        const levelHEY = window.PassportForm.values['request_form_graduate_admission_short.level_HEY'];
        response = await ServiceRequestApi.getStudent(value);
        let { content } = response.data;
        content = content.map(({ passportValues }) =>
          passportValues.reduce((obj, { code, value }) => ({ ...obj, [code]: value }), {})
        );
        content = content.filter(item => item['code_level'] === levelHEY);
        if (!content.length) {
          const errorMsg = await Dict.item('validation_messages', 'not_list');
          return Notice.info(errorMsg[`${lang}_name`]);
        }
        const student = content[0];
        setTimeout(() => {
          changeFormValue('request_form_graduate_admission_short.student_surname', student['student_surname']);
          changeFormValue('request_form_graduate_admission_short.student_middlename', student['student_middlename']);
          changeFormValue('request_form_graduate_admission_short.student_name', student['student_name']);
          if (levelHEY === '002') {
            changeFormValue('request_form_graduate_admission_short.GOP_paid', student['code_GOP']);
          }
        });
        setPrevIin(value);
        break;
      case 'request_form_study_abroad_short.doc_type_passport':
        response = await ServiceRequestApi.searchGbdfl(requesterIin);
        user = response.data.result;
        changeFormValue(`request_form_study_abroad_short.surname_passport`, user['lastName']);
        changeFormValue(`request_form_study_abroad_short.name_passport`, user['firstName']);
        doc = user['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
        if (doc) {
          changeFormValue(`request_form_study_abroad_short.doc_type_passport`, doc['typeCode']);
          changeFormValue(`request_form_study_abroad_short.passport_number`, doc['number']);
          changeFormValue(`request_form_study_abroad_short.doc_organ_rname_passport`, doc['issueOrganizationCode']);
          changeFormValue(`request_form_study_abroad_short.doc_issuedate_passport`, doc['beginDate']);
          changeFormValue(`request_form_study_abroad_short.doc_expirationdate_passport`, doc['endDate']);
        }
        break;
      case 'request_form_advance_payment_short.surname_passport':
        response = await ServiceRequestApi.searchGbdfl(requesterIin);
        user = response.data.result;
        changeFormValue(`request_form_advance_payment_short.surname_passport`, user['lastName']);
        changeFormValue(`request_form_advance_payment_short.name_passport`, user['firstName']);
        changeFormValue(`request_form_advance_payment_short.middlename_passport`, user['middleName']);
        doc = user['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
        if (doc) {
          changeFormValue(`request_form_advance_payment_short.passport_number`, doc['number']);
        }
        break;
      case 'request_form_academ_mobile_short.category_appliciant':
        if (value) {
          if (['001', '002'].includes(value.code)) {
            iin = requesterIin;
          } else {
            iin = window.PassportForm.values['request_form_academ_mobile_short.student_IIN'];
          }
          if (!iin) {
            return Notice.info('Заполните поле "ИИН студента"');
          }
          response = await ServiceRequestApi.searchGbdfl(iin);
          user = response.data.result;
          changeFormValue(`request_form_academ_mobile_short.surname_passport`, user['lastName']);
          changeFormValue(`request_form_academ_mobile_short.name_passport`, user['firstName']);
          doc = user['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
          if (doc) {
            changeFormValue(`request_form_academ_mobile_short.passport_number`, doc['number']);
            changeFormValue(`request_form_academ_mobile_short.doc_organ_rname_passport`, doc['issueOrganizationCode']);
            changeFormValue(`request_form_academ_mobile_short.doc_issuedate_passport`, doc['beginDate']);
            changeFormValue(`request_form_academ_mobile_short.doc_expirationdate_passport`, doc['endDate']);
          }
        }
        break;
      default:
        break;
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.gbdfl_person_not_found') {
      Notice.error(t('gbdflButton_gbdfl_person_not_found'));
    } else {
      handleError(error);
    }
  } finally {
    setLoading(false);
  }
};

function SimpleButton({ code, value, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [prevIin, setPrevIin] = useState(null);

  useEffect(() => {
    if (prevIin !== value) {
      if (code === 'request_form_graduate_admission_short.student_iin') {
        const name = window.PassportForm.values['request_form_graduate_admission_short.student_name'];
        if (name) {
          changeFormValue('request_form_graduate_admission_short.student_name', '');
          changeFormValue('request_form_graduate_admission_short.student_surname', '');
          changeFormValue('request_form_graduate_admission_short.student_middlename', '');
          changeFormValue('request_form_graduate_admission_short.form_payment', '');
          changeFormValue('request_form_graduate_admission_short.GOP_paid', '');
          changeFormValue('request_form_graduate_admission_short.choosing_direction', '');
          changeFormValue('request_form_graduate_admission_short.choosing_HEY', '');
        }
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        loading={loading}
        text={t('gbdflButton_search')}
        disabled={
          [
            'request_form_graduate_admission_short.student_iin'
          ].includes(code) && value.length !== 12
        }
        onClick={() => request({
          t,
          code,
          value,
          setLoading,
          lang: i18n.language,
          setPrevIin,
          requesterIin
        })}
      />
    </div>
  );
}

export default SimpleButton;
