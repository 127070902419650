import React from 'react';
import { AppBar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { HeaderTitle, StyledToolbar } from './HeaderStyle';
import LocaleButton from './LocaleButton';
import { history } from '../../../_helpers/history';
import AccountButton from './AccountButton';
import HelpButton from './HelpButton';
import paths from '../../../_helpers/paths';
import Logo from '../../../_assets/images/gerb-left.png';
import './HeaderTranslate';

function HeaderWithAuth() {
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <AppBar position="static" elevation={1} color="transparent" id="header">
      <StyledToolbar>
        <HeaderTitle>
          {
            location.pathname !== paths.homePage
            && (
              <>
                <img src={Logo} alt="Logo"/>
                <li
                  style={{ whiteSpace: 'pre-line' }}
                  onClick={() => history.push('/')}
                >
                  {t('home_title')}
                </li>
              </>
            )
          }
        </HeaderTitle>
        <ul>
          <HelpButton/>
          <LocaleButton/>
          <AccountButton/>
        </ul>
      </StyledToolbar>
    </AppBar>
  );
}

export default HeaderWithAuth;
