import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    accountSettings_iin: 'ИИН заявителя',
    accountSettings_update: 'Обновить данные',
    accountSettings_updateFailed: 'Не удалось обновить данные пользователя',

    accountSettingsDucks_loadRequesterFailed: 'Не удалось загрузить данные пользователя',
    accountSettingsDucks_saveRequesterFailed: 'Не удалось сохранить данные пользователя',
    accountSettingsDucks_saveRequesterSuccess: 'Данные успешно сохранены',
  },
  kk: {
    accountSettings_iin: 'ИИН заявителя',
    accountSettings_update: 'Деректерді жаңарту',
    accountSettings_updateFailed: 'Не удалось обновить данные пользователя',

    accountSettingsDucks_loadRequesterFailed: 'Не удалось загрузить данные пользователя',
    accountSettingsDucks_saveRequesterFailed: 'Не удалось сохранить данные пользователя',
    accountSettingsDucks_saveRequesterSuccess: 'Данные успешно сохранены'
  }
});