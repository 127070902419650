import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    support_title: 'Служба поддержки',
    support_content:
      'Вы можете направить свое обращение (вопрос, ошибку) на электронную почту технической поддержки support_nobd@iac.kz\n' +
      'При направлении обращения на электронную почту необходимо указать:\n' +
      '\n' +
      '1. В заголовке письма (тема) кратко указать суть вопроса. Например: «Тип заявки не отображается» или «Не открывается сайт es.mon.iac.kz»;\n' +
      '2. В тексте письма отразить наименование государственной услуги. Например : Услуга - «Прием документов для участия в конкурсе на обучение за рубежом в рамках международных договоров в области образования» и дату возникновения ошибки;\n' +
      '3. В тексте письма прописать уникальный номер заявки и дата/время;\n' +
      '4. В тексте письма подробно описать проблему и прикрепите скриншоты, ссылки. Например: «страница es.mon.iac.kz не открывается и выдает ошибку 504».'
  },
  kk: {
    support_title: 'Служба поддержки',
    support_content:
      'Сіз өзіңіздің өтінішіңізді (сұрақ, қате) техникалық қолдау электрондық поштасына жібере аласыз: support_nobd@iac.kz\n' +
      'Өтінішті электрондық поштаға жіберген кезде келесіні көрсету қажет:\n' +
      '\n' +
      '1. Хаттың (тақырыптың) тақырыбында мәселенің мәнін қысқаша көрсету. Мысалы: "өтінім түрі көрсетілмейді" немесе "сайт ашылмайды es.mon.iac.kz";\n' +
      '2. Хат мәтінінде мемлекеттік қызметтің атауы көрсетілсін. Мысалы: қызмет - "Білім беру саласындағы халықаралық шарттар шеңберінде шетелде оқу конкурсына қатысу үшін құжаттар қабылдау" және қатенің пайда болу күні;\n' +
      '3. Хаттың мәтінінде өтінімнің бірегей нөмірін және күнін/уақытын жазу;\n' +
      '4. Хат мәтінінде мәселені егжей-тегжейлі сипаттап, сілтемелерді,скриншоттарды тіркеңіз. Мысалы: "es.mon.iac.kz ашылмайды және 504 қатесін береді".'
  }
})