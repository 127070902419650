import React from 'react';
import Group from './Group';

function GenerateGroups({
  groups,
  questions,
  lang,
  disabled,
  betweenRender,
  hideMode,
  excludeGroupId,
  groupHided,
  firstLevel
}) {
  return groups.map(group => (
    <Group
      key={group.id}
      {...group}
      hideTitle={firstLevel && groups.length === 1}
      hideMode={hideMode}
      questions={questions}
      groupHided={groupHided}
      excludeGroupId={excludeGroupId}
      lang={lang}
      disabled={disabled}
      betweenRender={betweenRender}
    />
  ));
}

export default GenerateGroups;
