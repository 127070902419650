import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Prompt } from 'react-router-dom';

import { loginModule } from '../LoginPage/LoginDucks';
import {
  clearState,
  getServiceProvider,
  loadingData,
  onSave,
  serviceRequestModule
} from './ServiceRequestDucks';
import PassportProvider from '../../passport/PassportProvider';
import PassportForm from '../../passport/PassportForm';
import BreadCrumbs from '../../components/BreadCrumbs/BreadCrumbs';
import paths from '../../_helpers/paths';
import PassportExpander from '../../passport/Components/PassportExpander';
import PassportNavigation from '../../passport/Components/PassportNavigation';
import ServiceRequestInfo from './ServiceRequestInfo';
import ServiceProvidersModal from './ServiceProvidersModal/ServiceProvidersModal';
import useSimpleModal from '../../components/_hooks/useSimpleModal';
import { services } from '../../_helpers/Constants';
import OrganizationsModal from './OrganizationsModal/OrganizationsModal';
import { extraFunctionality } from './ExtraFunctionality/ExtraFunctionality';
import Loading from './Loading';
import SubmitButton from './SubmitButton/SubmitButton';
import SPPicker from './SPPicker/SPPicker';
import {
  PassportLeftInfo,
  StyledPassportContainer,
  StyledProviderContainer
} from './ServiceRequestStyles';
import './ServiceRequestTranslate';

function ServiceRequest(props) {
  const { meta, data, match, loading, user, loadingSave } = props;
  const { onSave, loadingData, clearState, getServiceProvider } = props;
  const { id, metadataKey, from } = match.params;
  const { t, i18n } = useTranslation();
  const [serviceProvider, setServiceProvider] = useState(null);
  const [spBin, setSPBin] = useState(null);
  const SPModal = useSimpleModal();
  const OOModal = useSimpleModal();

  function serviceProviderHandler(sp) {
    setServiceProvider(sp);

    if (services.doc_acceptance_ddo_short.code === metadataKey) {
      getServiceProvider(sp.id);
    }
  }

  const isDisabled = !!from;

  const metadataName = meta && meta[`${i18n.language}_name`];

  const breadCrumbItems = [];

  if (isDisabled) {
    if (`/${from}` === paths.requestsPage) {
      breadCrumbItems.push({ name: t('requests_title'), href: paths.requestsPage });
    } else {
      breadCrumbItems.push({
        name: t('completedRequests_title'),
        href: paths.completedRequestsPage
      });
    }
  } else {
    breadCrumbItems.push({ name: t('home_subtitle'), href: paths.homePage });
  }

  breadCrumbItems.push({ name: metadataName });

  useEffect(() => {
    if (
      [
        services.realestate_bolashak_short.code,
        services.international_edu_short.code,
        services.letter_guarantee_short.code,
        services.status_bolashak_short.code,
        services.program_bolashak_short.code,
        services.advance_payment_short.code
      ].includes(metadataKey)
    ) {
      setSPBin('050440007456');
    } else if (
      [
        services.college_short.code,
        services.university_grant_short.code,
        services.university_admission_short.code,
        services.graduate_admission_short.code,
        services.organization_type_short.code,
        services.children_adoption_short.code,
        services.children_disposable_short.code
      ].includes(metadataKey)
    ) {
      setSPBin(true);
    } else if (
      [
        services.study_abroad_short.code
      ].includes(metadataKey)
    ) {
      setSPBin('000140000647');
    } else if (
      [
        services.recognition_education_short.code
      ].includes(metadataKey)
    ) {
      setSPBin('050640004360');
    }

    if (!isDisabled) {
      window.onbeforeunload = () => t('serviceRequest_onbeforunload');
      return () => (window.onbeforeunload = null);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    loadingData(id, metadataKey);
    return () => clearState();
  }, [id, metadataKey, clearState, loadingData]);

  function extraF(code, value) {
    return extraFunctionality({
      code,
      value,
      SPModal,
      OOModal,
      requesterIin: user.iin,
      setSPBin
    });
  }

  return (
    <div className="fullWidth mb3 mt3">
      {user && !isDisabled && !SPModal.isOpen && !OOModal.isOpen && !loadingSave && (
        <Prompt message={() => t('serviceRequest_onbeforunload')}/>
      )}
      <BreadCrumbs items={breadCrumbItems}/>
      <div
        style={{
          fontSize: '1.2em',
          textAlign: 'center',
          textTransform: 'uppercase',
          margin: '30px 0',
          fontWeight: 'bold'
        }}
      >
        {metadataName}
      </div>

      <StyledProviderContainer>
        <PassportProvider
          config={{
            fullDatePicker: true,
            documentsProps: {
              mimeType: 'image/jpeg,image/png,application/pdf',
              maxSize: 1000000
            }
          }}
          withoutScroll={false}
          data={data}
          meta={meta}
          questionRender={!isDisabled && extraF}
          children={
            <>
              {isDisabled && <PassportExpander/>}
              <StyledPassportContainer>
                {isDisabled && (
                  <PassportLeftInfo>
                    <ServiceRequestInfo/>
                    <PassportNavigation/>
                  </PassportLeftInfo>
                )}
                <div className="flex-auto">
                  {!isDisabled && !spBin && (
                    <SPPicker
                      serviceProvider={serviceProvider}
                      SPModal={SPModal}
                      metadataKey={metadataKey}
                    />
                  )}
                  <PassportForm
                    disabled={isDisabled}
                    loading={loading}
                    buttonRender={btnLoading =>
                      <SubmitButton
                        btnLoading={btnLoading}
                        isDisabled={isDisabled}
                        spBin={spBin}
                        user={user}
                        metadataKey={data.metadataKey}
                        serviceProvider={serviceProvider}
                        onSave={onSave}
                      />
                    }
                  />
                </div>
              </StyledPassportContainer>
            </>
          }
        />
      </StyledProviderContainer>
      {SPModal.isOpen && (
        <ServiceProvidersModal
          modal={SPModal}
          setFormData={serviceProviderHandler}
          metadataKey={metadataKey}
          setSPBin={setSPBin}
        />
      )}
      {OOModal.isOpen && <OrganizationsModal {...OOModal} />}
      <Loading/>
    </div>
  );
}

export default connect(
  state => ({
    user: state[loginModule].user,
    meta: state[serviceRequestModule].meta,
    data: state[serviceRequestModule].data,
    loading: state[serviceRequestModule].loading,
    loadingSave: state[serviceRequestModule].loadingSave
  }),
  {
    onSave,
    loadingData,
    clearState,
    getServiceProvider
  }
)(ServiceRequest);
