import styled from 'styled-components';
import Colors from '../ThemeProvider/Colors';

export const FooterText = styled.div`
  white-space: pre-line;
  width: 100%;
  font-size: 14px;
  color: ${Colors.lightGrey};
  padding: 11px 40px;
  background-color: #fafafa;
  align-self: center;
`;
