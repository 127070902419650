import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputBase from '@material-ui/core/InputBase/InputBase';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import DirectionsIcon from '@material-ui/icons/Directions';
import Paper from '@material-ui/core/Paper';
import Pagination from '@material-ui/lab/Pagination';

import { usePagePaginationStyles } from './TableStyle';

/**
 * @return {null}
 */
function PagePagination({pageNumber, totalPages, onPageChange}) {
  const [page, setPage] = useState('');
  const classes = usePagePaginationStyles();
  const { t } = useTranslation();

  const onChangeHandler = ({ target }) => {
    const reg = /^\d+$/;
    if (reg.test(target.value)) {
      if (target.value === '0') return null;
      if (JSON.parse(target.value) > totalPages) return null;

      setPage(target.value);
    } else if (!target.value) {
      setPage('');
    }
  };

  if (totalPages > 0 && onPageChange) {
    return (
      <div className="mt1">
        <Pagination
          variant="outlined"
          color="primary"
          page={pageNumber}
          count={totalPages}
          onChange={(_, page) => onPageChange(page)}
          showFirstButton
          showLastButton
        />
        <Paper className={classes.root}>
          <InputBase
            value={page}
            onChange={onChangeHandler}
            className={classes.input}
            placeholder={t('staticTable_page')}
            max={totalPages}
          />
          <Divider className={classes.divider} orientation="vertical" />
          <IconButton
            className={classes.iconButton}
            disabled={!page || String(page) === String(pageNumber)}
            onClick={() => onPageChange(page)}
          >
            <DirectionsIcon />
          </IconButton>
        </Paper>
      </div>
    );
  }

  return null;
}

export default PagePagination;