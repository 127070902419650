import { addTranslation } from '../../_helpers/Translate';

addTranslation({
  ru: {
    serviceRequest_number: 'Номер заявки',
    serviceRequest_status: 'Статус заявки',
    serviceRequest_processing_result: 'Результат обработки',
    serviceRequest_date: 'Дата заявки',
    serviceRequest_iin: 'ИИН заявителя',
    serviceRequest_PEP_number: 'Номер заявки ПЭП',
    serviceRequest_providerId: 'Номер услугодателя',
    serviceRequest_providerBin: 'БИН услугодателя',
    serviceRequest_serviceProvider: 'Услугодатель',
    serviceRequest_ddo: 'Дошкольные организации',
    serviceRequest_chooseServiceProvider: 'Выбрать',
    serviceRequest_lowTestResult: 'Набранный балл по Сертификату {{testName}} меньше минимального балла {{testResult}}. Поступающий не может участвовать в конкурсе с указанным сертификатом',
    gbdflButton_searchFailed: 'Не удалось найти пользователя',
    gbdflButton_gbdfl_person_not_found: 'В Государственной базе данных физических лиц данные не найдены. Проверьте корректность ввода ИИН или приложите сканированный документ свидетельства о рождении',
    gbdflButton_iinNotFound: 'В Государственной базе данных физических лиц данные не найдены. Проверьте корректность ввода ИИН или обратитесь в государственный орган.',
    gbdflButton_search: 'Запрос данных',
    serviceRequest_onbeforunload: 'Вы хотите покинуть страницу?',
    gbdflButton_childDocNotFound: 'Данных по свидетельству о рождении ребенка в базе данных ГБД ФЛ не найдены, необходимо обратиться в государственный орган или воспользоваться сервисом по проверке актовых записей физических лиц.',
    gbdflButton_alreadyStudy: 'Поступающий числится в организации образования "{{name}}", воспользуйтесь типом заявления Прием документов для перевода детей между организациями начального, основного среднего, общего среднего образования',
    gbdflButton_schoolNotFound: 'Данные по среднему образованию не найдены, заполните данные документа об образовании',
    gbdflButton_collegeNotFound: 'Данные по техническому и профессиональному образованию не найдены, заполните данные документа об образовании',
    gbdflButton_collegeNotFound_2: 'Данные по диплому технического и профессионального образования В ИС НОБД не найдены, заполните данные документа об образовании',
    gbdflButton_univerNotFound: 'Данные по высшему образованию не найдены, заполните данные документа об образовании',
    gbdflButton_univerNotFound_2: 'Данные по высшему образованию не найдены',
    gbdflButton_pmpkNotFound: 'Сведения о льготе не найдены, заполните данные',
    gbdflButton_currentEduNotFound: 'Данные о текущем образовании не найдены, заполните данные об образовании',
    gbdflButton_guardianshipNotFound: 'Данные о законном представителе не найдены, заполните данные',
    gbdflButton_guardianshipNotFound_v2: 'Данные о законном представителе не найдены, необходимо прикрепить подтверждающий документ',
    gbdflButton_guardianshipNotFound_v3: 'Данные по льготе "Дети-сирот и дети, оставшихся без попечения родителей, а также гражданин Республики Казахстан из числа молодежи, потерявших или оставшихся без попечения родителей до совершеннолетия" не найдены, необходимо заполнить данные',
    studyAtTheMoment: 'Поступающий на момент подачи заявки обучается (числиться) в ВУЗе {{univer}}. В связи с чем не может участвовать в конкурсе на присуждение гранта',
    finishedSameUniver: 'Поступающий ранее завершил ВУЗ {{univer}} аналогичного уровня образования. Согласно п. 2 статьи 8 Закона РК об образовании от 27 июля 2007 года № 319-III Поступающий не может участвовать в конкурсе на присуждение гранта',
    nctNotFound: 'Сведения по ЕНТ отсутствуют, Вам необходимо обратиться в РГКП "НЦТ"',

    serviceRequestDucks_saveSuccess: 'Ваша заявка успешно подписана',
    serviceRequestDucks_signCanceled: 'Подпись заявки отменена',
    serviceRequestDucks_saveFailed: 'Не удалось подписать заявку',
    serviceRequestDucks_loadDataFailed: 'Не удалось загрузить данные',
  },
  kk: {
    serviceRequest_number: 'Номер заявки',
    serviceRequest_status: 'Статус заявки',
    serviceRequest_processing_result: 'Результат обработки',
    serviceRequest_date: 'Дата заявки',
    serviceRequest_iin: 'ИИН заявителя',
    serviceRequest_PEP_number: 'Номер заявки ПЭП',
    serviceRequest_providerId: 'Номер услугодателя',
    serviceRequest_providerBin: 'БИН услугодателя',
    serviceRequest_serviceProvider: 'Услугодатель',
    serviceRequest_ddo: 'Мектепке дейінгі ұйымдар',
    serviceRequest_chooseServiceProvider: 'Выбрать',
    serviceRequest_lowTestResult: '{{testName}} сертификаты бойынша жинаған балл ең төменгі баллдан кем {{testResult}}. Оқуға түсуші көрсетілген сертификатпен конкурсқа қатыса алмайды',
    gbdflButton_searchFailed: 'Не удалось найти пользователя',
    gbdflButton_gbdfl_person_not_found: 'Жеке тұлғалардың мемлекеттік деректер базасында деректер табылған жоқ. ЖСН дұрыс енгізілгеніне көз жеткізіңіз немесе туу туралы куәліктің сканерленген көшірмесін қоса беріңіз',
    gbdflButton_iinNotFound: 'Жеке тұлғалардың мемлекеттік деректер базасында деректер табылған жоқ. ЖСН дұрыс енгізілгеніне көз жеткізіңіз немесе немесе мемлекеттік органға жүгініңіз',
    gbdflButton_search: 'Запрос данных',
    serviceRequest_onbeforunload: 'Сіз парақтан кеткіңіз келе ме?',
    gbdflButton_childDocNotFound: 'ЖТ МДҚ деректер қорында баланың туу туралы куәлігі бойынша деректер табылған жоқ, мемлекеттік органға жүгіну қажет немесе жеке тұлғалардың акт жазбаларын тексеру сервисін пайдалану қажет.',
    gbdflButton_alreadyStudy: 'Оқуға түсуші білім беру ұйымдарында "{{name}}", бастауыш, негізгі орта, жалпы орта білім беру ұйымдары арасында балаларды ауыстыру үшін құжаттарды қабылдау өтінішінің түрін пайдаланыңыз',
    gbdflButton_schoolNotFound: 'Орта білім туралы деректер табылған жоқ, білім туралы құжат деректерін толтырыңыз',
    gbdflButton_collegeNotFound: 'Техникалық және кәсіптік білім бойынша деректер табылмады, білім туралы құжаттың деректерін толтырыңыз',
    gbdflButton_collegeNotFound_2: 'ҰБДҚ АЖ-да техникалық және кәсіптік білім дипломы бойынша деректер табылмады, білімі туралы құжат деректерін толтыру',
    gbdflButton_univerNotFound: 'Жоғары білім туралы деректер табылған жоқ, білім туралы құжат деректерін толтырыңыз',
    gbdflButton_univerNotFound_2: 'Жоғары білім туралы деректер табылған жоқ',
    gbdflButton_pmpkNotFound: 'Жеңілдік туралы ақпарат табылмады, деректерді толтырыңыз',
    gbdflButton_currentEduNotFound: 'Білім туралы мәліметтер табылған жоқ, білім туралы мәліметтерді толтырыңыз',
    gbdflButton_guardianshipNotFound: 'Заңды өкіл туралы деректер табылмады, деректерді толтырыңыз',
    gbdflButton_guardianshipNotFound_v2: 'Заңды өкіл туралы деректер табылмады, растайтын құжатты тіркеу қажет',
    gbdflButton_guardianshipNotFound_v3: 'Жетім балалар мен ата-анасының қамқорлығынсыз қалған балалардың, сондай-ақ кәмелетке толғанға дейін ата-анасынан айырылған немесе ата-анасының қамқорлығынсыз қалған жастардың қатарындағы Қазақстан Республикасы азаматтары" жеңілдігі бойынша деректер табылмады, мәліметті толтыру қажет',
    studyAtTheMoment: 'Оқуға түсуші өтінім беру кезінде {{univer}} ЖОО-да оқиды (есепте тұрады). Осыған байланысты грант беру конкурсына қатыса алмайды',
    finishedSameUniver: 'Оқуға түсуші бұрын ұқсас Білім беру деңгейінде {{univer}} жоғары оқу орнын аяқтады. ҚР Білім туралы 2007 жылғы 27 шілдедегі № 319-III Заңының 8-бабының 2-тармағына сәйкес оқуға түсуші грант тағайындау конкурсына қатыса алмайды',
    nctNotFound: 'ҰБТ бойынша мәлімет жоқ, ҰТО РМҚК-ға жүгінуіңіз қажет',

    serviceRequestDucks_saveSuccess: 'Сіздің өтінішіңізге қол қойылды',
    serviceRequestDucks_signCanceled: 'Подпись заявки отменена',
    serviceRequestDucks_saveFailed: 'Не удалось подписать заявку',
    serviceRequestDucks_loadDataFailed: 'Не удалось загрузить данные'
  }
})