import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import RuInstruction from '../../_assets/files/ru_instruction.pdf';
import KkInstruction from '../../_assets/files/kk_instruction.pdf';
import Colors from '../../components/ThemeProvider/Colors';
import './InstructionsTranslate';

const instructions = {
  ru: RuInstruction,
  kk: KkInstruction
};

function Instructions() {
  const { t, i18n } = useTranslation();

  return (
    <div className="mb3 mt3 fullWidth">
      <Typography
        children={t('instructions_title')}
        color="secondary"
        variant="h6"
      />
      <object
        width="100%"
        className="mt2"
        data={instructions[i18n.language]}
        style={{ height: 'calc(100vh - 270px)' }}
      >
        <div className="bold center p3" style={{ color: Colors.primary }}>
          {t('instructions_viewFileFailed')}
        </div>
      </object>
    </div>
  );
}

export default Instructions;