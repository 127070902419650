import React, { useEffect } from 'react';
import i18next from 'i18next';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import HeaderWithoutAuth from './Headers/HeaderWithoutAuth';
import { loginModule } from '../../pages/LoginPage/LoginDucks';
import HeaderWithAuth from './Headers/HeaderWithAuth';
import GerbLeft from '../../_assets/images/gerb-left.png';
import { StyledTitle } from './HeaderStyles';
import paths from '../../_helpers/paths';

function Header() {
  const { t, i18n } = useTranslation();
  const { user } = useSelector(state => state[loginModule]);
  const location = useLocation();
  useEffect(() => {
    let lsLang = localStorage.getItem('lang');
    if (lsLang && lsLang !== i18n.language) {
      i18next.changeLanguage(lsLang);
    } else {
      localStorage.setItem('lang', i18n.language);
    }
  }, [i18n.language]);

  return (
    <>
      {
        !user
          ? <HeaderWithoutAuth/>
          : <HeaderWithAuth/>
      }
      {
        location.pathname === paths.homePage && (
          <StyledTitle>
            <img src={GerbLeft} alt="Герб"/>
            <div style={{ whiteSpace: 'pre-line' }}>{t('home_title')}</div>
          </StyledTitle>
        )
      }
    </>
  );
}

export default Header;
