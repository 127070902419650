import { createReducer } from 'redux-starter-kit';
import _ from 'lodash';

import { ServiceRequestApi } from './ServiceRequestApi';
import Notice from '../../modules/utils/Notice';
import { handleError } from '../../modules/utils/handleError';
import Dict from '../../modules/utils/Dict';
import { translation } from '../../_helpers/Translate';
import { history } from '../../_helpers/history';
import paths from '../../_helpers/paths';
import { NCALayerSign } from '../../modules/NCALayer/NCALayerSign';
import { changeFormValue } from '../../passport/Passport';

/**
 * Constants
 */
export const serviceRequestModule = 'serviceRequest';
const LOAD_DATA_REQUEST = `${serviceRequestModule}/LOAD_DATA_REQUEST`;
const SET_DATA_REQUEST = `${serviceRequestModule}/SET_DATA_REQUEST`;
const LOAD_SAVING_REQUEST = `${serviceRequestModule}/LOAD_SAVING_REQUEST`;
const CLEAR_STATE = `${serviceRequestModule}/CLEAR_STATE`;
const LOADING_ACTION = `${serviceRequestModule}/LOADING_ACTION`;

/**
 * Reducer
 */
export const initialState = {
  meta: null,
  data: {},
  loading: false,
  loadingSave: false,
  loadingAction: false
};

export default createReducer(initialState, {
  [LOAD_DATA_REQUEST]: (state, action) => {
    state.loading = action.loading;
  },
  [LOAD_SAVING_REQUEST]: (state, action) => {
    state.loadingSave = action.loading;
  },
  [SET_DATA_REQUEST]: (state, action) => {
    state.meta = action.meta;
    state.data = action.data || { metadataKey: action.meta.id };
  },
  [LOADING_ACTION]: (state, action) => {
    state.loadingAction = action.loading;
  },
  [CLEAR_STATE]: () => initialState
});

/**
 * Action Creators
 */

export const loadingData = (id, metadataKey) => async (dispatch, getState) => {
  dispatch({ type: LOAD_DATA_REQUEST, loading: true });
  try {
    if (id) {
      const { data } = await ServiceRequestApi.loadData(id);
      const response = await ServiceRequestApi.loadMetaData(data.metadataKey);
      const filter = JSON.stringify({ id: data.serviceProviderId });
      const { data: serviceProviderData } = await ServiceRequestApi.serviceProvider(filter);
      let serviceProvider;
      if (serviceProviderData.result.content.length) {
        serviceProvider = serviceProviderData.result.content[0];
      }
      const dictStatuses = await Dict.itemsObject('gu_status_res');
      const dictResolutions = await Dict.itemsObject('gu_status_pr');
      dispatch({
        type: SET_DATA_REQUEST,
        meta: response.data,
        data: {
          ...data,
          serviceProvider,
          ruProcessingStatus: dictStatuses[data.processingStatus][`ru_name`],
          kkProcessingStatus: dictStatuses[data.processingStatus][`kk_name`],
          ruResolutionType: dictResolutions[data.resolutionType][`ru_name`],
          kkResolutionType: dictResolutions[data.resolutionType][`kk_name`],
          requesterIin: getState().login.user.iin
        }
      });
    } else {
      const response = await ServiceRequestApi.loadMetaData(`request_form_${metadataKey}`);
      dispatch({ type: SET_DATA_REQUEST, meta: response.data });
    }
  } catch (errorCode) {
    handleError(errorCode, translation('serviceRequestDucks_loadDataFailed'));
  } finally {
    dispatch({ type: LOAD_DATA_REQUEST, loading: false });
  }
};

export const onSave = formData => async dispatch => {
  dispatch({ type: LOAD_SAVING_REQUEST, loading: true });
  try {
    const { data } = await ServiceRequestApi.getSignString(formData);
    const signString = data['result']['signature'];
    const signedString = await NCALayerSign(signString, false, 'PKCS12', false);
    if (!signedString) {
      return Notice.warning(translation('serviceRequestDucks_signCanceled'));
    }
    const response = await ServiceRequestApi.createRequestForm({
      ...formData,
      signature: signedString
    });
    if (response.data.results.length === 0) {
      Notice.success(translation('serviceRequestDucks_saveSuccess'));
      history.push(paths.homePage);
    } else {
      handleError(response, translation('serviceRequestDucks_saveFailed'));
    }
  } catch (e) {
    const errorCode = _.get(e, 'response.data.errors[0].code');
    if (errorCode === 'error.runtime_user_must_sing_own_signature') {
      Notice.error(translation('must_sing_own_signature'));
    } else {
      handleError(e, translation('serviceRequestDucks_saveFailed'));
    }
  } finally {
    dispatch({ type: LOAD_SAVING_REQUEST, loading: false });
  }
};

export const getServiceProvider = id => async dispatch => {
  try {
    dispatch({ type: LOADING_ACTION, loading: true });
    const { data } = await ServiceRequestApi.serviceProviderPassport(id);
    const ruName = _.get(data, 'questionnaire.questionCodeToAnswers.ru_name.values[0].value');
    const kkName = _.get(data, 'questionnaire.questionCodeToAnswers.kk_name.values[0].value');
    const locality = _.get(data, 'questionnaire.questionCodeToAnswers.legal_locality.values[0].value');
    const subType = _.get(data, 'questionnaire.questionCodeToAnswers.sub_types.values[0].value[0]');
    changeFormValue('request_form_doc_acceptance_ddo_short.kindergarden_name', ruName);
    changeFormValue('request_form_doc_acceptance_ddo_short.kindergarden_name_kz', kkName);
    changeFormValue('request_form_doc_acceptance_ddo_short.direction_region', locality);
    changeFormValue('request_form_doc_acceptance_ddo_short.kind_kindergarden', subType);
  } catch (error) {
    handleError(error, translation('serviceRequestDucks_getSPFailed'));
  } finally {
    dispatch({ type: LOADING_ACTION, loading: false });
  }
};

export const loadingAction = loading => ({ type: LOADING_ACTION, loading });

export const clearState = () => dispatch => {
  dispatch({ type: CLEAR_STATE });
};
