import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import { capitalize } from '@material-ui/core';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { changeFormValue } from '../../../passport/Passport';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { translation } from '../../../_helpers/Translate';

const requestGBDFL = async ({ setLoading, code, value, t, lang, setPrevIin }) => {
  try {
    setLoading(true);
    let c = code.split('.')[1], doc;
    const metadataKey = code.split('.')[0];
    const { data } = await ServiceRequestApi.searchGbdfl(value);
    const user = data.result;

    switch (code) {
      case 'request_form_payment_patronage_short.spouse_iin':
      case 'request_form_children_property_short.child_iin':
      case 'request_form_pmpk_short.child_iin':
      case 'request_form_program_bolashak_short.spouse_IIN':
      case 'request_form_program_bolashak_short.divorce_iin':
        c = c.toLowerCase().replace('_iin', '');
        changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
        changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
        setPrevIin(value);
        break;
      case 'request_form_doc_queued_ddo_short.child_iin':
        c = c.replace('_iin', '');
        changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
        changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
        if (!user['birthCertificateIssueOrganization']) {
          return Notice.info(translation('gbdflButton_childDocNotFound'));
        }
        changeFormValue(`${metadataKey}.name_reg_birth_child`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.number_reg_child`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.reg_date_child`, user['birthDate']);
        changeFormValue(`${metadataKey}.birthdoc_doc_date_child`, user['birthCertificateBeginDate']);
        break;
      case 'request_form_realestate_bolashak_short.iin_graduate':
        c = c.replace('iin_', '');
        changeFormValue(`${metadataKey}.surname_${c}`, user['lastName']);
        changeFormValue(`${metadataKey}.name_${c}`, user['firstName']);
        changeFormValue(`${metadataKey}.patronymic_${c}`, user['middleName']);
        changeFormValue(`${metadataKey}.dob_${c}`, user['birthDate']);
        break;
      case 'request_form_accept_doc_edu_short.Child_iin':
        c = c.replace('_iin', '').toLowerCase();
        changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
        changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
        changeFormValue(`${metadataKey}.NameZAGS_ru`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.NameZAGS_kk`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.numberAkt`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.reg_date_child`, user['birthDate']);
        changeFormValue(`${metadataKey}.date_issue_ZAGS`, user['birthCertificateBeginDate']);
        const response = await ServiceRequestApi.getSchools(value);
        if (response.status === 200) {
          if (response.data.content.length) {
            const schools = response.data.content.filter(s => [0, 3, 4].includes(s.status));
            if (schools.length) {
              const name = response.data.content[0][`schoolName${capitalize(lang)}`];
              changeFormValue(`${metadataKey}.education_sign`, true);
              Notice.info(<div dangerouslySetInnerHTML={{ __html: t('gbdflButton_alreadyStudy', { name }) }}/>);
            } else {
              changeFormValue(`${metadataKey}.education_sign`, false);
            }
          } else {
            changeFormValue(`${metadataKey}.education_sign`, false);
          }
        }
        break;
      case 'request_form_international_edu_short.child_iin':
        c = c.replace('_iin', '');
        changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
        changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
        changeFormValue(`${metadataKey}.${c}_birthplace`, user[`birthPlaceFullName${capitalize(lang)}`]);
        changeFormValue(`${metadataKey}.RnameZAGS`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.KnameZAGS`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.Number_akt`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.Akt_reg_date`, user['birthDate']);
        changeFormValue(`${metadataKey}.date_issue_ZAGS`, user['birthCertificateBeginDate']);
        doc = user['personalDocuments'].find(doc => doc['typeCode'] === '002' && doc['statusCode'] === '00');
        if (doc) {
          changeFormValue(`${metadataKey}.${c}_doc_type`, doc['typeCode']);
          changeFormValue(`${metadataKey}.${c}_doc_number`, doc['number']);
          changeFormValue(`${metadataKey}.${c}_doc_issuedate`, doc['beginDate']);
          changeFormValue(`${metadataKey}.${c}_doc_expirationdate`, doc['endDate']);
          changeFormValue(`${metadataKey}.${c}_doc_organ_rname`, doc['issueOrganizationCode']);
        }
        const passport = user['personalDocuments'].find(doc => doc['typeCode'] === '001' && doc['statusCode'] === '00');
        if (passport) {
          changeFormValue(`${metadataKey}.doc_type_passport`, passport['typeCode']);
          changeFormValue(`${metadataKey}.passport_number`, passport['number']);
          changeFormValue(`${metadataKey}.doc_organ_rname_passport`, passport['issueOrganizationCode']);
          changeFormValue(`${metadataKey}.doc_issuedate_passport`, passport['beginDate']);
          changeFormValue(`${metadataKey}.doc_expirationdate_passport`, passport['endDate']);
        }
        break;
      case 'request_form_doc_acceptance_ddo_short.child_iin':
        c = c.replace('_iin', '');
        changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
        changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
        changeFormValue(`${metadataKey}.name_reg_birth_child_kz`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.name_reg_birth_child`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.number_reg_child`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.reg_date_child`, user['birthDate']);
        changeFormValue(`${metadataKey}.birthdoc_doc_date_child`, user['birthCertificateBeginDate']);
        break;
      case 'request_form_college_short.Child_iin':
        changeFormValue(`${metadataKey}.child_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.child_name`, user['firstName']);
        changeFormValue(`${metadataKey}.child_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.child_birthday`, user['birthDate']);
        changeFormValue(`${metadataKey}.child_birthplace`, user[`birthPlaceFullName${capitalize(lang)}`]);
        changeFormValue(`${metadataKey}.RnameZAGS`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.KnameZAGS`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.Number_akt`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.Akt_reg_date`, user['birthDate']);
        changeFormValue(`${metadataKey}.date_issue_ZAGS`, user['birthCertificateBeginDate']);
        doc = user['personalDocuments'].find(doc => doc['typeCode'] === '002' && doc['statusCode'] === '00');
        if (doc) {
          changeFormValue(`${metadataKey}.doc_type_stud`, doc['typeCode']);
          changeFormValue(`${metadataKey}.doc_number_stud`, doc['number']);
          changeFormValue(`${metadataKey}.doc_series_stud`, doc['series']);
          changeFormValue(`${metadataKey}.doc_issuedate_stud`, doc['beginDate']);
          changeFormValue(`${metadataKey}.doc_expiration_date`, doc['endDate']);
          changeFormValue(`${metadataKey}.doc_organ_rname_stud`, doc['issueOrganizationCode']);
        }
        break;
      case 'request_form_university_grant_short.Child_iin':
      case 'request_form_university_admission_short.Child_iin':
        changeFormValue(`${metadataKey}.child_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.child_name`, user['firstName']);
        changeFormValue(`${metadataKey}.child_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.child_birthday`, user['birthDate']);
        changeFormValue(`${metadataKey}.child_nationality`, user['nationalityCode']);
        changeFormValue(`${metadataKey}.child_citizenship`, user['citizenshipCode']);
        changeFormValue(`${metadataKey}.RnameZAGS`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.KnameZAGS`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.Number_akt`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.Akt_reg_date`, user['birthDate']);
        changeFormValue(`${metadataKey}.date_issue_ZAGS`, user['birthCertificateBeginDate']);
        doc = user['personalDocuments'].find(doc => doc['typeCode'] === '002' && doc['statusCode'] === '00');
        if (doc) {
          changeFormValue(`${metadataKey}.child_doc_type`, doc['typeCode']);
          changeFormValue(`${metadataKey}.child_doc_number`, doc['number']);
          changeFormValue(`${metadataKey}.child_doc_series`, doc['series']);
          changeFormValue(`${metadataKey}.child_doc_issuedate`, doc['beginDate']);
          changeFormValue(`${metadataKey}.child_doc_expirationdate`, doc['endDate']);
          changeFormValue(`${metadataKey}.child_doc_organ_rname`, doc['issueOrganizationCode']);
        }
        break;
      case 'request_form_organization_type_short.Child_iin':
        c = c.replace('_iin', '').toLowerCase();
        changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
        changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
        changeFormValue(`${metadataKey}.RnameZAGS_ru`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.RnameZAGS_kk`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.Number_akt`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.Akt_reg_date`, user['birthDate']);
        changeFormValue(`${metadataKey}.date_issue_ZAGS`, user['birthCertificateBeginDate']);
        setPrevIin(value);
        break;
      case 'request_form_extern_study_short.student_iin':
        c = c.replace('_iin', '');
        changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
        changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
        changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
        changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
        changeFormValue(`${metadataKey}.doc_birth_name`, user['birthCertificateIssueOrganization']);
        changeFormValue(`${metadataKey}.doc_birth_number`, user['birthCertificateNumber']);
        changeFormValue(`${metadataKey}.doc_birth_date`, user['birthDate']);
        changeFormValue(`${metadataKey}.date_issue_ZAGS`, user['birthCertificateBeginDate']);
        changeFormValue(`${metadataKey}.student_country`, user['regAddressCountryCode']);
        changeFormValue(`${metadataKey}.student_area`, user['regAddressDistrictsCode']);
        changeFormValue(`${metadataKey}.student_region`, user['regAddressRegionCode']);
        changeFormValue(`${metadataKey}.student_city`, user['regAddressCity']);
        changeFormValue(`${metadataKey}.student_street`, user['regAddressStreet']);
        changeFormValue(`${metadataKey}.student_house`, user['regAddressBuilding']);
        changeFormValue(`${metadataKey}.student_corpus`, user['regAddressCorpus']);
        changeFormValue(`${metadataKey}.student_flat`, user['regAddressFlat']);
        setPrevIin(value);
        break;
      case 'request_form_children_adoption_short.guardianship_child_iin':
        if (user['personStatusCode'] === '2') {
          c = c.replace('_iin', '');
          changeFormValue(`${metadataKey}.${c}_surname`, user['lastName']);
          changeFormValue(`${metadataKey}.${c}_name`, user['firstName']);
          changeFormValue(`${metadataKey}.${c}_middlename`, user['middleName']);
          changeFormValue(`${metadataKey}.${c}_birthday`, user['birthDate']);
          changeFormValue(`${metadataKey}.${c}_birthplace`, user['birthPlaceCity']);
          changeFormValue(`${metadataKey}.${c}_registry_nameRu`, user['birthCertificateIssueOrganization']);
          changeFormValue(`${metadataKey}.${c}_registry_nameKk`, user['birthCertificateIssueOrganization']);
          changeFormValue(`${metadataKey}.${c}_birthdoc_number`, user['birthCertificateNumber']);
          changeFormValue(`${metadataKey}.${c}_birthdoc_date`, user['birthDate']);
          setPrevIin(value);
        } else {
          Notice.error(t('gbdflButton_gbdfl_person_not_found'));
        }
        break;
      case 'request_form_recognition_education_short.iin_owner':
        if (user['personStatusCode'] === '2') {
          c = c.replace('iin_', '');
          changeFormValue(`${metadataKey}.surname_${c}`, user['lastName']);
          changeFormValue(`${metadataKey}.name_${c}`, user['firstName']);
          changeFormValue(`${metadataKey}.middlename_${c}`, user['middleName']);
          changeFormValue(`${metadataKey}.birthday_${c}`, user['birthDate']);
          changeFormValue(`${metadataKey}.citizenship_${c}`, user['citizenshipCode']);
          changeFormValue(`${metadataKey}.country_${c}`, user['regAddressCountryCode']);
          changeFormValue(`${metadataKey}.area_${c}`, user['regAddressDistrictsCode']);
          changeFormValue(`${metadataKey}.region_${c}`, user['regAddressRegionCode']);
          changeFormValue(`${metadataKey}.city_${c}`, user['regAddressCity']);
          changeFormValue(`${metadataKey}.street_${c}`, user['regAddressStreet']);
          changeFormValue(`${metadataKey}.house_${c}`, user['regAddressBuilding']);
          changeFormValue(`${metadataKey}.corpus_${c}`, user['regAddressCorpus']);
          changeFormValue(`${metadataKey}.flat_${c}`, user['regAddressFlat']);
          doc = user['personalDocuments'][0];
          if (doc) {
            changeFormValue(`${metadataKey}.doctype_${c}`, doc['typeCode']);
            changeFormValue(`${metadataKey}.docnum_${c}`, doc['number']);
            changeFormValue(`${metadataKey}.docseries_${c}`, doc['series']);
            changeFormValue(`${metadataKey}.dateIssue_${c}`, doc['beginDate']);
            changeFormValue(`${metadataKey}.expDate_${c}`, doc['endDate']);
            changeFormValue(`${metadataKey}.issuedBy_${c}`, doc[`${lang}IssueOrganization`]);
          }
          setPrevIin(value);
        } else {
          Notice.error(t('gbdflButton_gbdfl_person_not_found'));
        }
        break;
      default:
        break;
    }
  } catch (error) {
    const errorCode = _.get(error, 'response.data.errors[0].code');
    if (errorCode === 'error.gbdfl_person_not_found') {
      const metadata = code.split('.')[0];
      if (
        [
          'request_form_realestate_bolashak_short',
          'request_form_doc_queued_ddo_short'
        ].includes(metadata)
      ) {
        Notice.error(t('gbdflButton_iinNotFound'));
      } else {
        Notice.error(t('gbdflButton_gbdfl_person_not_found'));
      }
    } else {
      handleError(error, t('gbdflButton_searchFailed'));
    }
  } finally {
    setLoading(false);
  }
};

function SimpleGBDFLButton({ code, value }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [prevIin, setPrevIin] = useState(null);

  useEffect(() => {
    if (prevIin !== value) {
      switch (code) {
        case 'request_form_organization_type_short.Child_iin':
          const tg = window.PassportForm.values['request_form_organization_type_short.DecNumb_TrustGuard'];
          const name = window.PassportForm.values['request_form_organization_type_short.child_name'];
          const school = window.PassportForm.values['request_form_organization_type_short.School_Name'];
          if (name) {
            changeFormValue(`request_form_organization_type_short.child_surname`, '');
            changeFormValue(`request_form_organization_type_short.child_name`, '');
            changeFormValue(`request_form_organization_type_short.child_middlename`, '');
            changeFormValue(`request_form_organization_type_short.child_birthday`, '');
            changeFormValue(`request_form_organization_type_short.RnameZAGS_ru`, '');
            changeFormValue(`request_form_organization_type_short.RnameZAGS_kk`, '');
            changeFormValue(`request_form_organization_type_short.Number_akt`, '');
            changeFormValue(`request_form_organization_type_short.Akt_reg_date`, '');
            changeFormValue(`request_form_organization_type_short.date_issue_ZAGS`, '');
          }
          if (tg) {
            changeFormValue('request_form_organization_type_short.DecNumb_TrustGuard', '');
            changeFormValue('request_form_organization_type_short.DecDate_TrustGuard', '');
            changeFormValue('request_form_organization_type_short.trusteeGuard_ssign', '');
          }
          if (school) {
            changeFormValue('request_form_organization_type_short.School_Name', '');
            changeFormValue('request_form_organization_type_short.location_university', '');
            changeFormValue('request_form_organization_type_short.Specialty_classifier', '');
            changeFormValue('request_form_organization_type_short.Course_numb', '');
            changeFormValue('request_form_organization_type_short.lang_instruc_cerf', '');
            changeFormValue('request_form_organization_type_short.Form_train_school', '');
            changeFormValue('request_form_organization_type_short.reg_date_school', '');
            changeFormValue('request_form_organization_type_short.graduate_date', '');
            changeFormValue('request_form_organization_type_short.reason_disposal', '');
          }
          break;
        case 'request_form_extern_study_short.student_iin':
          const studentName = window.PassportForm.values['request_form_extern_study_short.student_name'];
          if (studentName) {
            changeFormValue(`request_form_extern_study_short.student_surname`, '');
            changeFormValue(`request_form_extern_study_short.student_name`, '');
            changeFormValue(`request_form_extern_study_short.student_middlename`, '');
            changeFormValue(`request_form_extern_study_short.student_birthday`, '');
            changeFormValue(`request_form_extern_study_short.doc_birth_name`, '');
            changeFormValue(`request_form_extern_study_short.doc_birth_number`, '');
            changeFormValue(`request_form_extern_study_short.doc_birth_date`, '');
            changeFormValue(`request_form_extern_study_short.date_issue_ZAGS`, '');
            changeFormValue(`request_form_extern_study_short.student_country`, '');
            changeFormValue(`request_form_extern_study_short.student_area`, '');
            changeFormValue(`request_form_extern_study_short.student_region`, '');
            changeFormValue(`request_form_extern_study_short.student_city`, '');
            changeFormValue(`request_form_extern_study_short.student_street`, '');
            changeFormValue(`request_form_extern_study_short.student_house`, '');
            changeFormValue(`request_form_extern_study_short.student_corpus`, '');
            changeFormValue(`request_form_extern_study_short.student_flat`, '');
          }
          const g = window.PassportForm.values['request_form_extern_study_short.guardian_reason'];
          if (g) {
            changeFormValue(`request_form_extern_study_short.guardian_reason`, '');
            changeFormValue(`request_form_extern_study_short.guardian_doc_number`, '');
            changeFormValue(`request_form_extern_study_short.guardian_decision_date`, '');
          }
          const edu = window.PassportForm.values['request_form_extern_study_short.nameOrg_edu'];
          if (edu) {
            changeFormValue('request_form_extern_study_short.nameOrg_edu', '');
            changeFormValue('request_form_extern_study_short.classNum', '');
            changeFormValue('request_form_extern_study_short.date_enrollment', '');
            changeFormValue('request_form_extern_study_short.formTraining', '');
            changeFormValue('request_form_extern_study_short.boardingDetails', '');
            changeFormValue('request_form_extern_study_short.study_specClasses', '');
          }
          break;
        case 'request_form_children_adoption_short.guardianship_child_iin':
          const gcn = window.PassportForm.values['request_form_children_adoption_short.guardianship_child_name'];
          if (gcn) {
            changeFormValue(`request_form_children_adoption_short.guardianship_child_surname`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_name`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_middlename`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_birthday`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_birthplace`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_registry_nameRu`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_registry_nameKk`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_birthdoc_number`, '');
            changeFormValue(`request_form_children_adoption_short.guardianship_child_birthdoc_date`, '');
          }
          break;
        case 'request_form_recognition_education_short.iin_owner':
          const n = window.PassportForm.values['request_form_recognition_education_short.name_owner'];
          if (n) {
            changeFormValue(`request_form_recognition_education_short.surname_owner`, '');
            changeFormValue(`request_form_recognition_education_short.name_owner`, '');
            changeFormValue(`request_form_recognition_education_short.middlename_owner`, '');
            changeFormValue(`request_form_recognition_education_short.birthday_owner`, '');
            changeFormValue(`request_form_recognition_education_short.citizenship_owner`, '');
            changeFormValue(`request_form_recognition_education_short.country_owner`, '');
            changeFormValue(`request_form_recognition_education_short.area_owner`, '');
            changeFormValue(`request_form_recognition_education_short.region_owner`, '');
            changeFormValue(`request_form_recognition_education_short.city_owner`, '');
            changeFormValue(`request_form_recognition_education_short.street_owner`, '');
            changeFormValue(`request_form_recognition_education_short.house_owner`, '');
            changeFormValue(`request_form_recognition_education_short.corpus_owner`, '');
            changeFormValue(`request_form_recognition_education_short.flat_owner`, '');
            changeFormValue(`request_form_recognition_education_short.doctype_owner`, '');
            changeFormValue(`request_form_recognition_education_short.docnum_owner`, '');
            changeFormValue(`request_form_recognition_education_short.docseries_owner`, '');
            changeFormValue(`request_form_recognition_education_short.dateIssue_owner`, '');
            changeFormValue(`request_form_recognition_education_short.expDate_owner`, '');
            changeFormValue(`request_form_recognition_education_short.issuedBy_owner`, '');
          }
          break;
        case 'request_form_program_bolashak_short.spouse_IIN':
        case 'request_form_program_bolashak_short.divorce_iin':
          const questionCode = code.split('.')[1]
          const c = questionCode.toLowerCase().replace('_iin', '');
          changeFormValue(`request_form_program_bolashak_short.${c}_surname`, '');
          changeFormValue(`request_form_program_bolashak_short.${c}_name`, '');
          changeFormValue(`request_form_program_bolashak_short.${c}_middlename`, '');
          changeFormValue(`request_form_program_bolashak_short.${c}_birthday`, '');
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line
  }, [value]);

  return (
    <div>
      <StyledGBDFLButton
        color="secondary"
        loading={loading}
        text={t('gbdflButton_search')}
        disabled={value.length !== 12}
        onClick={() => requestGBDFL({
          t,
          code,
          value,
          setLoading,
          lang: i18n.language,
          setPrevIin
        })}
      />
    </div>
  );
}

export default SimpleGBDFLButton;
