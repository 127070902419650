export const SIMPLE_SP_MODAL_TRIGGERS = [
  'request_form_college_short.name_orgSpec',
  'request_form_contest_leaders_short.type_edu_org',
  'request_form_organization_type_short.name_orgSpec',
  'request_form_children_adoption_short.guardianship_child_birthplace',
  'request_form_children_disposable_short.court_decision_region'
];

export const OO_MODAL_TRIGGERS = [
  'name_of_university',
  'school_name'
];

export const SIMPLE_IIN_QUESTIONS = [
  'request_form_payment_patronage_short.spouse_iin',
  'request_form_doc_queued_ddo_short.child_iin',
  'request_form_international_edu_short.child_iin',
  'request_form_realestate_bolashak_short.iin_graduate',
  'request_form_accept_doc_edu_short.Child_iin',
  'request_form_doc_acceptance_ddo_short.child_iin',
  'request_form_college_short.Child_iin',
  'request_form_university_grant_short.Child_iin',
  'request_form_university_admission_short.Child_iin',
  'request_form_children_property_short.child_iin',
  'request_form_pmpk_short.child_iin',
  'request_form_organization_type_short.Child_iin',
  'request_form_extern_study_short.student_iin',
  'request_form_children_adoption_short.guardianship_child_iin',
  'request_form_recognition_education_short.iin_owner',
  'request_form_program_bolashak_short.spouse_IIN',
  'request_form_program_bolashak_short.divorce_iin'
];

export const SIMPLE_NOT_IIN_QUESTIONS = [
  'request_form_international_edu_short.user_category'
];

export const COMPLEX_IIN_QUESTIONS = {
  'fostercare_InfoChild': {
    'fostercare_child_iin': 'fostercare_child_iin'
  },
  'marriage_info': {
    'spouse_iin': 'spouse_iin'
  },
  'info_pledger': {
    'iin_pledger': 'iin_pledger'
  },
  'applicant_relative': {
    'family_IIN': 'family_IIN'
  },
  'guarantor_info': {
    'inn_guarantor': 'inn_guarantor'
  },
  'guardianship_infoList': {
    'guardianship_child_iin': 'guardianship_child_iin'
  },
  'child_infoList': {
    'child_iin': 'child_iin'
  }
};

export const SIMPLE_SCHOOL_QUESTIONS = [
  'request_form_college_short.pmpk_number',
  'request_form_university_grant_short.area_school',
  'request_form_university_admission_short.area_school',
  'request_form_duplic_edu_short.SchoolBin',
  'request_form_extern_study_short.nameOrg_edu'
];

export const SIMPLE_COLLEGE_QUESTIONS = [
  'request_form_college_short.trusteeGuardian_city',
  'request_form_university_grant_short.EDU_area',
  'request_form_university_admission_short.locality_EDU',
  'request_form_organization_type_short.country_of_university'
];

export const SIMPLE_GUARDIANSHIP_QUESTIONS = [
  'request_form_college_short.form_TrustGuard',
  'request_form_university_grant_short.DecNumb_TrustGuard',
  'request_form_university_admission_short.DecNumb_TrustGuard',
  'request_form_university_grant_short.adopter_iin',
  'request_form_organization_type_short.user_category',
  'request_form_extern_study_short.category'
];

export const COMPLEX_SCHOOL_QUESTIONS = {
  'info_edu': {
    'country_of_school': 'country_of_school'
  },
  'oso': {
    'country_oso': 'country_oso'
  }
};

export const COMPLEX_COLLEGE_QUESTIONS = {
  'tipo': {
    'country_tipo': 'country_tipo'
  },
  'TIPO_edu': {
    'locality_EDU': 'locality_EDU'
  }
};

export const COMPLEX_UNIVER_QUESTIONS = {
  'education_list': {
    'country_of_university': 'country_of_university'
  },
  'university': {
    'country_university': 'country_university'
  },
  'HEI_edu': {
    'HEI_Name': 'HEI_Name'
  }
};

export const LISTEN_SIMPLE_QUESTIONS = [
  'request_form_college_short.name_orgSpec_one',
  'request_form_college_short.name_orgSpec_kval',
  'request_form_university_grant_short.CertificateResult_N',
  'request_form_university_admission_short.CertificateResult_N',
  'request_form_university_grant_short.place_creative_exam',
  'request_form_university_grant_short.selection_Committee_EDU_N',
  'request_form_university_grant_short.selection_Committee_Target',
  'request_form_university_admission_short.VUZ_HEI_all',
  'request_form_university_admission_short.place_creative_exam',
  'request_form_university_admission_short.VUZ_HEI_quick',
  'request_form_university_admission_short.VUZ_HEI',
  'request_form_university_admission_short.VUZ_quick',
  'request_form_graduate_admission_short.name_EDU_grant',
  'request_form_graduate_admission_short.name_HEY_doctoral',
  'request_form_graduate_admission_short.choosing_HEY',
  'request_form_graduate_admission_short.form_payment',
  'request_form_graduate_admission_short.level_HEY',
  'request_form_graduate_admission_short.choosing_HEY_doc',
  'request_form_duplic_edu_short.doctype_edu_name',
  'request_form_organization_type_short.Applic_type',
  'request_form_contest_leaders_short.con_com',
  'request_form_program_bolashak_short.doc_organ_rname_passport',
  'request_form_attestation_teacher_short.application_type'
];

export const SIMPLE_UNIVER_QUESTIONS = [
  'request_form_university_grant_short.candidate_gop_fact',
  'request_form_university_admission_short.HEI_Name',
  'request_form_academ_mobile_short.name_univ'
];

export const NCT_QUESTIONS = [
  'request_form_university_grant_short.Individual_test_code',
  'request_form_university_admission_short.Individual_test_code'
];

export const SIMPLE_BUTTON_QUESTIONS = [
  'request_form_graduate_admission_short.student_iin',
  'request_form_study_abroad_short.doc_type_passport',
  'request_form_advance_payment_short.surname_passport',
  'request_form_academ_mobile_short.category_appliciant'
]
