import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Select from '../../modules/Select/Select';
import Button from '../../modules/Button/Button';
import Notice from '../../modules/utils/Notice';
import { loginModule, loginWithECP } from './LoginDucks';

const SIGN_METHOD_PKCS12 = 'PKCS12';
const SIGN_METHOD_AKKaztokenStore = 'AKKaztokenStore';
const SIGN_METHOD_AKKZIDCardStore = 'AKKZIDCardStore';

const ECP_OPTIONS = [
  {
    code: SIGN_METHOD_PKCS12,
    ru_name: 'Ваш компьютер',
    kk_name: 'Сіздің компьютеріңіз'
  },
  {
    code: SIGN_METHOD_AKKZIDCardStore,
    ru_name: 'Удостоверение личности',
    kk_name: 'Жеке куәлік'
  },
  {
    code: SIGN_METHOD_AKKaztokenStore,
    ru_name: 'Казтокен',
    kk_name: 'Қазтокен'
  }
];

function LoginWithECP() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [method, setMethod] = useState(SIGN_METHOD_PKCS12);
  const { loadingLogin } = useSelector(state => state[loginModule]);

  return (
    <div className="center">
      <Select
        withoutForm
        placeholder="Выберите метод входа"
        value={method}
        options={ECP_OPTIONS}
        onChange={e => setMethod(e.target.value)}
      />
      <div className="my2"/>
      <Button
        type="submit"
        color="primary"
        variant="contained"
        loading={loadingLogin}
        text={t('loginPage_loginWithECP')}
        onClick={() => {
          if (!method) {
            return Notice.warning('Необходимо выбрать метод входа через ЭЦП');
          }
          dispatch(loginWithECP(method));
        }}
      />
    </div>
  )
}

export default LoginWithECP;