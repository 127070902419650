import sortReducers from '../modules/utils/sortReducers';
import loginReducer, { loginModule } from '../pages/LoginPage/LoginDucks';
import serviceRequestReducer, { serviceRequestModule } from '../pages/ServiceRequest/ServiceRequestDucks';
import requestsReducer, { requestsModule } from '../pages/Requests/RequestsDucks';
import completedRequestsReducer, { completedRequestsModule } from '../pages/CompletedRequests/CompletedRequestsDucks';
import serviceProvidersReducer, { serviceProvidersModule } from '../pages/ServiceRequest/ServiceProvidersModal/ServiceProvidersDucks';
import accountSettingsReducer, { accountSettingsModule } from '../pages/AccountSettings/AccountSettingsDucks';
import organizationsReducer, { organizationsModule } from '../pages/ServiceRequest/OrganizationsModal/OrganizationsDucks';

export default sortReducers({
  [loginModule]: loginReducer,
  [serviceRequestModule]: serviceRequestReducer,
  [requestsModule]: requestsReducer,
  [completedRequestsModule]: completedRequestsReducer,
  [serviceProvidersModule]: serviceProvidersReducer,
  [accountSettingsModule]: accountSettingsReducer,
  [organizationsModule]: organizationsReducer
});
