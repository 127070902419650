import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeFormValue } from '../../../passport/Passport';

async function changeGuardianship({ code, t, setLoading, requesterIin }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, guardianship;

    switch (code) {
      case 'request_form_college_short.form_TrustGuard':
        category = window.PassportForm.values['request_form_college_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_college_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН абитуриента');
          }
        }

        response = await ServiceRequestApi.getGuardianship(iin);
        if (response.status === 200) {
          guardianship = response.data.content[0];
          if (!guardianship) {
            return Notice.info(t('gbdflButton_guardianshipNotFound'));
          } else {
            const tIIN = guardianship.passportValues.find(q => q.code === 'TrusteeIIN');
            const mar = guardianship.passportValues.find(q => q.code === 'MotherAbsenceReason');
            const far = guardianship.passportValues.find(q => q.code === 'FatherAbsenceReason');
            const p = guardianship.passportValues.find(q => q.code === 'Placement');

            if (
              !tIIN || tIIN.value !== requesterIin ||
              (!mar && !far) ||
              (
                ['court_death_decision', 'death'].includes(mar.value) &&
                ['court_death_decision', 'death'].includes(far.value)
              ) || !p ||
              !['adoption', 'custody', 'ddst', 'foster_family', 'guest_family', 'organization', 'patronage'].includes(p.value.value)
            ) {
              return Notice.info(t('gbdflButton_guardianshipNotFound'));
            }

            const cn = guardianship.passportValues.find(q => q.code === 'ContractNumber');
            const cd = guardianship.passportValues.find(q => q.code === 'ContractDate');

            changeFormValue(`request_form_college_short.form_TrustGuard`, p ? p.value.value : '');
            changeFormValue(`request_form_college_short.DecNumb_TrustGuard`, cn ? cn.value : '');
            changeFormValue(`request_form_college_short.DecDate_TrustGuard`, cd ? cd.value : '');
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_university_grant_short.DecNumb_TrustGuard':
      case 'request_form_university_admission_short.DecNumb_TrustGuard':
        const serviceCode = code.split('.')[0];
        category = window.PassportForm.values[`${serviceCode}.user_category`];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values[`${serviceCode}.Child_iin`];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН поступающего');
          }
        }

        response = await ServiceRequestApi.getGuardianship(iin);
        if (response.status === 200) {
          guardianship = response.data.content[0];
          if (!guardianship) {
            return Notice.info(t('gbdflButton_guardianshipNotFound_v2'));
          } else {
            const tIIN = guardianship.passportValues.find(q => q.code === 'TrusteeIIN');
            const p = guardianship.passportValues.find(q => q.code === 'Placement');

            if (
              !tIIN || tIIN.value !== requesterIin || !p ||
              !['adoption', 'custody', 'ddst', 'foster_family', 'guest_family', 'patronage'].includes(p.value.value)
            ) {
              return Notice.info(t('gbdflButton_guardianshipNotFound_v2'));
            }

            const mar = guardianship.passportValues.find(q => q.code === 'MotherAbsenceReason');
            const far = guardianship.passportValues.find(q => q.code === 'FatherAbsenceReason');
            const cn = guardianship.passportValues.find(q => q.code === 'ContractNumber');
            const cd = guardianship.passportValues.find(q => q.code === 'ContractDate');

            changeFormValue(`${serviceCode}.absence_father_one`, far ? far.value.value : '');
            changeFormValue(`${serviceCode}.absence_mother_one`, mar ? mar.value.value : '');
            changeFormValue(`${serviceCode}.legal_representative_one`, p ? p.value.value : '');
            changeFormValue(`${serviceCode}.DecNumb_TrustGuard`, cn ? cn.value : '');
            changeFormValue(`${serviceCode}.DecDate_TrustGuard`, cd ? cd.value : '');
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_university_grant_short.adopter_iin':
        category = window.PassportForm.values['request_form_university_grant_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_university_grant_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН поступающего');
          }
        }
        response = await ServiceRequestApi.getGuardianship(iin);
        if (response.status === 200) {
          guardianship = response.data.content[0];
          if (!guardianship) {
            return Notice.info(t('gbdflButton_guardianshipNotFound_v3'));
          } else {
            const p = guardianship.passportValues.find(q => q.code === 'Placement');

            if (
              !p || !['adoption', 'custody', 'ddst', 'foster_family', 'guest_family', 'patronage', 'organization'].includes(p.value.value)
            ) {
              return Notice.info(t('gbdflButton_guardianshipNotFound_v3'));
            }

            const tIIN = guardianship.passportValues.find(q => q.code === 'TrusteeIIN');
            const tFIO = guardianship.passportValues.find(q => q.code === 'TrusteeFIO');
            const tBD = guardianship.passportValues.find(q => q.code === 'TrusteeBD');
            const cn = guardianship.passportValues.find(q => q.code === 'ContractNumber');
            const cd = guardianship.passportValues.find(q => q.code === 'ContractDate');
            const dsd = guardianship.passportValues.find(q => q.code === 'DeregistrationStartDate');
            const ded = guardianship.passportValues.find(q => q.code === 'DeregistrationEndDate');
            const cr = guardianship.passportValues.find(q => q.code === 'CancelReason');
            const mar = guardianship.passportValues.find(q => q.code === 'MotherAbsenceReason');
            const far = guardianship.passportValues.find(q => q.code === 'FatherAbsenceReason');


            changeFormValue(`request_form_university_grant_short.adopter_iin`, tIIN ? tIIN.value : '');
            changeFormValue(`request_form_university_grant_short.adopter_surname`, tFIO ? tFIO.value : '');
            changeFormValue(`request_form_university_grant_short.adopterBirthDate`, tBD ? tBD.value : '');
            changeFormValue(`request_form_university_grant_short.DecNumb_TrustGuard_d`, cn ? cn.value : '');
            changeFormValue(`request_form_university_grant_short.DecDate_TrustGuard_d`, cd ? cd.value : '');
            changeFormValue(`request_form_university_grant_short.legal_representative_two`, p ? p.value.value : '');
            changeFormValue(`request_form_university_grant_short.date_registration`, dsd ? dsd.value : '');
            changeFormValue(`request_form_university_grant_short.date_termination`, ded ? ded.value : '');
            changeFormValue(`request_form_university_grant_short.reason_termination`, cr ? cr.value.value : '');
            changeFormValue(`request_form_university_grant_short.absence_father_two`, far ? far.value.value : '');
            changeFormValue(`request_form_university_grant_short.absence_mother_two`, mar ? mar.value.value : '');
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_organization_type_short.user_category':
        iin = window.PassportForm.values['request_form_organization_type_short.Child_iin'];
        if (!iin) {
          return Notice.info('Необходимо указать ИИН ребенка');
        }

        response = await ServiceRequestApi.getGuardianship(iin);
        if (response.status === 200) {
          guardianship = response.data.content[0];
          if (!guardianship) {
            return Notice.info(t('gbdflButton_guardianshipNotFound'));
          } else {
            const tIIN = guardianship.passportValues.find(q => q.code === 'TrusteeIIN');
            const p = guardianship.passportValues.find(q => q.code === 'Placement');

            if (
              !tIIN || tIIN.value !== requesterIin ||
              !p ||
              !['adoption', 'custody', 'ddst', 'foster_family', 'guest_family', 'patronage'].includes(p.value.value)
            ) {
              return Notice.info(t('gbdflButton_guardianshipNotFound'));
            }

            const cn = guardianship.passportValues.find(q => q.code === 'ContractNumber');
            const cd = guardianship.passportValues.find(q => q.code === 'ContractDate');

            if (p.value.value === 'adoption') {
              changeFormValue(`request_form_organization_type_short.trusteeGuard_ssign`, true);
            }
            changeFormValue(`request_form_organization_type_short.DecNumb_TrustGuard`, cn ? cn.value : '');
            changeFormValue(`request_form_organization_type_short.DecDate_TrustGuard`, cd ? cd.value : '');
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'request_form_extern_study_short.category':
        iin = window.PassportForm.values['request_form_extern_study_short.student_iin'];
        if (!iin) {
          return Notice.info('Необходимо указать ИИН учащегося');
        }

        response = await ServiceRequestApi.getGuardianship(iin);
        if (response.status === 200) {
          guardianship = response.data.content[0];
          if (!guardianship) {
            return Notice.info(t('gbdflButton_guardianshipNotFound'));
          } else {
            const tIIN = guardianship.passportValues.find(q => q.code === 'TrusteeIIN');
            const p = guardianship.passportValues.find(q => q.code === 'Placement');

            if (
              !tIIN || tIIN.value !== requesterIin ||
              !p ||
              !['adoption', 'custody', 'ddst', 'foster_family', 'guest_family', 'patronage'].includes(p.value.value)
            ) {
              return Notice.info(t('gbdflButton_guardianshipNotFound'));
            }

            const cn = guardianship.passportValues.find(q => q.code === 'ContractNumber');
            const cd = guardianship.passportValues.find(q => q.code === 'ContractDate');

            changeFormValue(`request_form_extern_study_short.guardian_reason`, p.value.value);
            changeFormValue(`request_form_extern_study_short.guardian_doc_number`, cn ? cn.value : '');
            changeFormValue(`request_form_extern_study_short.guardian_decision_date`, cd ? cd.value : '');
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      default:
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function SimpleGuardianship({ code, value, requesterIin }) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    switch (code) {
      case 'request_form_organization_type_short.user_category':
        const school = window.PassportForm.values['request_form_organization_type_short.School_Name'];
        if (school) {
          changeFormValue('request_form_organization_type_short.School_Name', '');
          changeFormValue('request_form_organization_type_short.location_university', '');
          changeFormValue('request_form_organization_type_short.Specialty_classifier', '');
          changeFormValue('request_form_organization_type_short.Course_numb', '');
          changeFormValue('request_form_organization_type_short.lang_instruc_cerf', '');
          changeFormValue('request_form_organization_type_short.Form_train_school', '');
          changeFormValue('request_form_organization_type_short.reg_date_school', '');
          changeFormValue('request_form_organization_type_short.graduate_date', '');
          changeFormValue('request_form_organization_type_short.reason_disposal', '');
        }
        break;
      case 'request_form_extern_study_short.category':
        const studentName = window.PassportForm.values['request_form_extern_study_short.student_name'];
        if (studentName) {
          changeFormValue(`request_form_extern_study_short.student_surname`, '');
          changeFormValue(`request_form_extern_study_short.student_name`, '');
          changeFormValue(`request_form_extern_study_short.student_middlename`, '');
          changeFormValue(`request_form_extern_study_short.student_birthday`, '');
          changeFormValue(`request_form_extern_study_short.doc_birth_name`, '');
          changeFormValue(`request_form_extern_study_short.doc_birth_number`, '');
          changeFormValue(`request_form_extern_study_short.doc_birth_date`, '');
          changeFormValue(`request_form_extern_study_short.date_issue_ZAGS`, '');
          changeFormValue(`request_form_extern_study_short.student_country`, '');
          changeFormValue(`request_form_extern_study_short.student_area`, '');
          changeFormValue(`request_form_extern_study_short.student_region`, '');
          changeFormValue(`request_form_extern_study_short.student_city`, '');
          changeFormValue(`request_form_extern_study_short.student_street`, '');
          changeFormValue(`request_form_extern_study_short.student_house`, '');
          changeFormValue(`request_form_extern_study_short.student_corpus`, '');
          changeFormValue(`request_form_extern_study_short.student_flat`, '');
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [value]);

  switch (code) {
    case 'request_form_extern_study_short.category':
    case 'request_form_organization_type_short.user_category':
      if (value?.code !== '002') return null;
      break;
    default:
      break;
  }

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeGuardianship({
          t,
          code,
          setLoading,
          requesterIin
        })}
      />
    </div>
  );
}

export default SimpleGuardianship;
