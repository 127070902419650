import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import capitalize from '@material-ui/core/utils/capitalize';
import uuid from 'uuid/v4';

import { StyledGBDFLButton } from '../ServiceRequestStyles';
import { handleError } from '../../../modules/utils/handleError';
import Notice from '../../../modules/utils/Notice';
import { ServiceRequestApi } from '../ServiceRequestApi';
import { changeComplexValue } from '../../../passport/Passport';

async function changeSchool({ code, questionCode, complexCode, t, setLoading, lang, requesterIin }) {
  try {
    setLoading(true);
    let category = '', iin = '', response, school;

    switch (`${code.split('.')[1]}.${questionCode}`) {
      case 'info_edu.country_of_school':
        category = window.PassportForm.values['request_form_international_edu_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_international_edu_short.child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН ребенка');
          }
        }
        response = await ServiceRequestApi.getSchools(iin);
        if (response.status === 200) {
          if (!response.data.content.length) {
            return Notice.info(t('gbdflButton_schoolNotFound'));
          } else {
            let complexData = {};
            for (let i = 0; i < response.data.content.length; i++) {
              const complexKey = i === 0 ? complexCode : uuid();
              const name = response.data.content[i][`schoolName${capitalize(lang)}`];
              const location = response.data.content[i][`locationCode`];
              const year_grad = response.data.content[i].passportValues.find(q => q.code === 'DateOfDisposal');
              complexData[complexKey] = {
                'country_of_school': '47',
                'school_name': name,
                'location_school': location,
                'year_grad': year_grad ? year_grad.value : ''
              };
            }
            changeComplexValue(code, complexData);
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
      case 'oso.country_oso':
        category = window.PassportForm.values['request_form_college_short.user_category'];
        if (!category) {
          return Notice.info('Сначала выберите категорию заявителя');
        }
        iin = '';
        if (category === '003') {
          iin = requesterIin;
        } else {
          iin = window.PassportForm.values['request_form_college_short.Child_iin'];
          if (!iin) {
            return Notice.info('Необходимо указать ИИН абитуриента');
          }
        }
        response = await ServiceRequestApi.getSchools(iin);
        if (response.status === 200) {
          school = response.data.content.find(s => s['status'] === 2 && s.passportValues.find(q => q.code === 'Parallel' && ['13', '14', '15'].includes(q.value.value)));
          if (!school) {
            return Notice.info(t('gbdflButton_schoolNotFound'));
          } else {
            const name = school[`schoolName${capitalize(lang)}`];
            const ta = school.passportValues.find(q => q.code === 'TerritorialAccessories');
            const parallel = school.passportValues.find(q => q.code === 'Parallel');
            const sf = school.passportValues.find(q => q.code === 'StudyForm');
            const l = school.passportValues.find(q => q.code === 'Language');
            const da = school.passportValues.find(q => q.code === 'DateOfArrival');
            const dd = school.passportValues.find(q => q.code === 'DateOfDisposal');
            const snc = school.passportValues.find(q => q.code === 'SerialNumberCert');
            const sn = school.passportValues.find(q => q.code === 'CertificateNumber');
            const dic = school.passportValues.find(q => q.code === 'DateIssueCert');

            changeComplexValue(
              code,
              {
                [complexCode]: {
                  'country_oso': '47',
                  'name_oso': name,
                  'territorial_oso': ta ? ta.value : '',
                  'parallel': parallel ? parallel.value.value : '',
                  'form_edu': sf ? sf.value.value : '',
                  'language_edu': l ? l.value.value : '',
                  'arrival_date': da ? da.value : '',
                  'disposal_date': dd ? dd.value : '',
                  'series_certificate': snc ? snc.value : '',
                  'number_certificate': sn ? sn.value : '',
                  'issue_date': dic ? dic.value : ''
                }
              }
            );
          }
        } else {
          handleError(response, t('dataNotFound'));
        }
        break;
    }
  } catch (error) {
    handleError(error, t('dataNotFound'));
  } finally {
    setLoading(false);
  }
}

function ComplexSchool({ code, value, questionCode, complexCode, requesterIin }) {
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  if (
    ['country_of_school', 'country_oso'].includes(questionCode) &&
    (!value || value.code !== '47')
  ) {
    return null;
  }

  return (
    <div>
      <StyledGBDFLButton
        loading={loading}
        color="secondary"
        text={t('gbdflButton_search')}
        onClick={() => changeSchool({
          t,
          code,
          complexCode,
          setLoading,
          requesterIin,
          questionCode,
          lang: i18n.language
        })}
      />
    </div>
  );
}

export default ComplexSchool;
