import axios from 'axios';
import i18next from 'i18next';
import paths from './paths';
import { history } from './history';
import appConfig from './config';

const handleBaseUrlInterceptor = config => {
  if (config && config.url) {
    if (config.url.indexOf('api:services') !== -1) {
      const newConfig = {
        ...config,
        baseURL: appConfig.url.service,
        url: config.url.slice(12)
      };
      if (config.url.indexOf('/nedb-ddm/api/') !== -1) {
        newConfig.headers['Cache-Control'] = 'no-cache';
      }
      return newConfig;
    }
    if (config.url.indexOf('api:fileStore') !== -1) {
      return {
        ...config,
        baseURL: appConfig.url.fileStore,
        url: config.url.slice(13)
      };
    }
    if (config.url.indexOf('api:ws') !== -1) {
      return {
        ...config,
        baseURL: appConfig.url.ws,
        url: config.url.slice(6)
      };
    }
  }
  return config;
};

const handleLanguageInterceptor = config => {
  let contentLang;
  switch (i18next.language) {
    case 'ru':
      contentLang = 'ru,ru-RU';
      break;
    case 'kk':
      contentLang = 'kk,kk-KZ';
      break;
    case 'en':
      contentLang = 'en,en-US';
      break;
    default:
      contentLang = 'en,en-US';
  }
  return {
    ...config,
    headers: {
      ...config.headers,
      'Content-Language': contentLang
    }
  };
};

const configureAuth = config => {
  if (!config.headers.Authorization) {
    const newConfig = {
      headers: {},
      ...config
    };
    let accessToken = localStorage.getItem('accessToken');
    if (accessToken) {
      newConfig.headers.Authorization = `Bearer ${accessToken}`;
    }
    return newConfig;
  }
  return config;
};

const unauthorizedResponse = async error => {
  if (error.response && error.response.status === 401) {
    if (error.response.config.url.indexOf('login') === -1) {
      if (window.location.pathname !== paths.homePage) {
        history.push(paths.homePage);
      }
    }
  }
  return Promise.reject(error);
};

axios.defaults.baseURL = appConfig.url.base;
axios.interceptors.request.use(handleBaseUrlInterceptor, e => Promise.reject(e));
axios.interceptors.request.use(handleLanguageInterceptor, e => Promise.reject(e));
axios.interceptors.request.use(configureAuth, e => Promise.reject(e));
axios.interceptors.response.use(r => r, unauthorizedResponse);
