import React from 'react';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppBar } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import green from '@material-ui/core/colors/green';
import { withStyles } from '@material-ui/styles';

import { HeaderTitle, StyledToolbar } from './HeaderStyle';
import LocaleButton from './LocaleButton';
import { loginModalHandler } from '../../../pages/LoginPage/LoginDucks';
import HelpButton from './HelpButton';
import paths from '../../../_helpers/paths';
import Logo from '../../../_assets/images/gerb-left.png';
import { history } from '../../../_helpers/history';
import './HeaderTranslate';

const ColorButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: green[400],
    '&:hover': {
      backgroundColor: green[700]
    }
  }
}))(Button);

function HeaderWithoutAuth() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  return (
    <AppBar position="static" elevation={1} color="transparent" id="header">
      <StyledToolbar>
        <HeaderTitle>
          {
            [paths.supportPage, paths.instructionsPage].includes(location.pathname)
            && (
              <>
                <img src={Logo} alt="Logo"/>
                <li
                  style={{ whiteSpace: 'pre-line' }}
                  onClick={() => history.push('/')}
                >
                  {t('home_title')}
                </li>
              </>
            )
          }
        </HeaderTitle>
        <ul>
          <li onClick={() => dispatch(loginModalHandler(true))}>
            <span className="icon-span">
              <ExitToAppIcon className="icon"/>
            </span>
            {t('header_login')}
          </li>
          <HelpButton/>
          <LocaleButton/>
          <ColorButton
            size="small"
            target="_blank"
            href="https://egov.kz/cms/ru/services/pass_onlineecp"
            variant="outlined"
            children={t('header_getEDS')}
          />
        </ul>
      </StyledToolbar>
    </AppBar>
  );
}

export default HeaderWithoutAuth;
