import styled from 'styled-components';

import StaticTablePagination from '../../../_ui/Table/StaticTablePaginated';

export const TableWrapper = styled.div`
  .MuiFormControlLabel-root {
    margin: 0 !important;
  }
  .rt-td:first-child {
    //padding: 0 !important;
  }

  .rt-thead.-header {
    top: -15px !important;
  }

  .rt-thead.-filters {
    top: 25px !important;
  }
`;

export const Table = styled(StaticTablePagination)`
  .MuiFormControlLabel-root {
    margin: 0 !important;
  }
  
  .rt-thead.-header {
    top: -15px !important;
  }

  .rt-thead.-filters {
    top: 25px !important;
  }
  
  .MuiInputBase-root {
    max-height: 30px;
  }
  
  .MuiFormControl-root {
    overflow: hidden;
    padding-top: 1px;
  }
`;

export const Wrapper = styled.div`
  .ReactTable {
    border-top: 1px solid rgba(170,170,170,0.08); !important;
    border-right: none;
    border-bottom: none;
    border-left: none;
    border-radius: 0 !important;
  }
`;

export const TreeSelectWrapper = styled.div`
  width: 50%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  & span {
    font-weight: bold;
    font-size: 1rem;
    margin-right: 15px;
    white-space: nowrap;
  }
  & div {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    height: 34px !important;
    line-height: 34px !important;
  }
  
  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;

export const StyledModalContainer = styled.div`
  min-width: 600px;
`;