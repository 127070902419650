import React, { useState, useEffect } from 'react';
import Field from '../../_ui/Form/Field';
import PropTypes from 'prop-types';
import FormControl from '@material-ui/core/FormControl';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';
import FormHelperText from '@material-ui/core/FormHelperText';
import MuiSelect from '@material-ui/core/Select';
import Dict from '../utils/Dict';
import i18next from 'i18next';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import { IconWrapper } from './SelectStyles';

const getChecked = (optionValue, value) =>
  value instanceof Array ? value.includes(optionValue) : value === optionValue;

const getOptionsValue = (options, value) => {
  let result = [];
  options.forEach(option => {
    getChecked(option.code, value) && result.push(option[`${i18next.language}_name`]);
  });
  return result.join(', ');
};

function SelectComponent(props) {
  const {
    name,
    options = [],
    value,
    onChange,
    multiple,
    lookupId,
    disabled,
    label
  } = props;
  const [sOptions, setOptions] = useState(options);
  useEffect(() => {
    if (lookupId) {
      Dict.items(lookupId).then(dictOptions => setOptions(dictOptions));
    }
  }, [lookupId]);

  useEffect(() => {
    if (options.length) setOptions(options);
  }, [options]);

  const IconComponent = props => {
    return (
      <IconWrapper {...props}>
        {value ? (
          <CloseIcon
            className="pointer"
            fontSize="small"
            onClick={() => onChange({ target: { value: null } })}
          />
        ) : (
          <ExpandMoreIcon style={{ fontSize: 25 }} />
        )}
      </IconWrapper>
    );
  };

  return (
    <FormControl fullWidth error={!!props.error}>
      <Typography children={label} style={{ marginBottom: 10 }} />
      <MuiSelect
        disabled={disabled}
        name={name}
        multiple={multiple}
        value={value || ''}
        IconComponent={IconComponent}
        onChange={event => onChange(event)}
        displayEmpty
        MenuProps={{
          MenuListProps: { style: { maxWidth: 650, minHeight: 45 } },
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'left'
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'left'
          }
        }}
        renderValue={value => {
          return value === undefined || value === '' || value.length === 0 ? (
            <span style={{ color: '#BFBFBF' }}> {props.placeholder} </span>
          ) : (
            getOptionsValue(sOptions, value)
          );
        }}
      >
        {sOptions.map(option => (
          <MenuItem key={option.code} value={option.code} disabled={option.disabled}>
            {multiple && (
              <Checkbox
                checked={getChecked(option.code, value)}
                style={{ marginRight: 5, width: 30 }}
              />
            )}
            <ListItemText primary={option[`${i18next.language}_name`]} />
          </MenuItem>
        ))}
      </MuiSelect>
      {props.error && <FormHelperText>{props.helperText}</FormHelperText>}
    </FormControl>
  );
}

const Select = props => {
  const { withoutForm, name, onChange, ...restProps } = props;
  return withoutForm ? (
    <SelectComponent name={name} onChange={onChange} {...restProps} />
  ) : (
    <Field name={name}>
      {({ onChange, ...fieldProps }) => (
        <SelectComponent
          name={name}
          onChange={event => onChange(event.target.value)}
          {...restProps}
          {...fieldProps}
        />
      )}
    </Field>
  );
};

Select.propsTypes = {
  withoutForm: PropTypes.bool,
  name: PropTypes.string.isRequired,
  value: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired
};

Select.defaultProps = {
  withoutForm: false
};

export default Select;
