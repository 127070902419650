import i18next from 'i18next';

import { translation } from '../../_helpers/Translate';
import { services } from '../../_helpers/Constants';
import { DEFAULT_FILTER } from '../../modules/TableFilter/useTableFilter';

const styles = {
  textAlign: 'center'
};

export const requestsColumns = ({ pageNumber, extraColumns = [], lang }) => {
  return [
    {
      Header: '№',
      width: 60,
      style: styles,
      Cell: row => (pageNumber - 1) * DEFAULT_FILTER.pageSize + row.index + 1
    },
    {
      Header: 'ID',
      accessor: 'id',
      width: 60,
      style: styles
    },
    {
      Header: translation('requests_serviceName'),
      accessor: 'serviceType',
      Cell: ({ value }) => services[value] && services[value][`${i18next.language}_name`],
      style: styles
    },
    {
      Header: translation('requests_requestDate'),
      accessor: 'submissionDate',
      style: styles,
      width: 200
    },
    {
      Header: translation('requests_status'),
      accessor: 'status',
      style: styles,
      width: 200,
      Cell: ({ value }) => value && value[`${lang}_name`]
    },
    ...extraColumns
  ];
};