export const PAGE_MODE = {
  CREATE: "CREATE",
  EDIT: "EDIT",
  VIEW: "VIEW",
  INVALID: "INVALID"
};

export const P_STATUS = {
  SUBMITTED: 'SUBMITTED',
  IN_PROGRESS: 'IN_PROGRESS',
  READY_FOR_SIGN: 'READY_FOR_SIGN',
  COMPLETED: 'COMPLETED',
  FAILED: 'FAILED',
  IN_PROGRESS_IN_EXTERNAL_APP: 'IN_PROGRESS_IN_EXTERNAL_APP'
};

export const services = {
  realestate_bolashak_short: {
    code: 'realestate_bolashak_short',
    ru_name: 'Выдача договора залога недвижимого имущества, предоставленного в качестве обеспечения исполнения обязательств стипендиатов международной стипендии  «Болашак», и уведомление о его прекращении',
    kk_name: 'Болашақ халықаралық стипендиясы стипендиаттарының міндеттемелерін орындауын қамтамасыз ету ретінде ұсынылған жылжымайтын мүлікке кепіл шартын беру, және оны тоқтату туралы хабарлама'
  },
  payment_patronage_short: {
    code: 'payment_patronage_short',
    ru_name: 'Передача ребенка (детей) на патронатное воспитание и назначение выплаты денежных средств на содержание ребенка (детей), переданного патронатным воспитателям',
    kk_name: 'Баланы (балаларды) патронаттық тәрбиелеуге беру және патронат тәрбиешiлерге берiлген баланы (балаларды) асырап-бағуға ақшалай қаражат төлеуді тағайындау'
  },
  international_edu_short: {
    code: 'international_edu_short',
    ru_name: 'Прием документов для участия в конкурсе на обучение за рубежом в рамках международных договоров в области образования',
    kk_name: 'Білім беру саласындағы халықаралық шарттар шеңберінде шетелде оқу конкурсына қатысу үшін құжаттар қабылдау'
  },
  payment_care_define_short: {
    code: 'payment_care_define_short',
    ru_name: 'Установление опеки или попечительства над ребенком- сиротой (детьми-сиротами) и ребенком (детьми), оставшимся без попечения родителей и назначение выплаты пособия опекунам или попечителям на содержание ребенка-сироты (детей-сирот) и ребенка (детей), оставшегося без попечения родителей',
    kk_name: '"Жетім балаға (жетім балаларға) және ата-анасының қамқорлығынсыз қалған балаға (балаларға) қамқоршылық немесе қорғаншылық белгілеу" және "Қамқоршыларға немесе қорғаншыларға жетім баланы (жетім балаларды) және ата-анасының қамқорлығынсыз қалған баланы (балаларды) асырап-бағуға жәрдемақы төлеуді тағайындау"'
  },
  doc_queued_ddo_short: {
    code: 'doc_queued_ddo_short',
    ru_name: 'Постановка на очередь детей дошкольного возраста (до 6 лет) для направления в детские дошкольные организации',
    kk_name: 'Мектепке дейінгі балалар ұйымдарына жіберу үшін мектепке дейінгі (6 жасқа дейін) жастағы балаларды кезекке қою'
  },
  college_short: {
    code: 'college_short',
    ru_name: 'Прием документов в организации технического и профессионального, послесреднего образования',
    kk_name: 'Техникалық және кәсіптік, орта білімнен кейінгі білім беру ұйымдарына құжаттар қабылдау'
  },
  letter_guarantee_short: {
    code: 'letter_guarantee_short',
    ru_name: 'Предоставление гарантийного письма для выезжающих на обучение в качестве стипендиата международной стипендии «Болашак»',
    kk_name: '«Болашақ» халықаралық стипендиясының стипендиаты ретінде оқуға баратындар үшін кепілдік хатын беру'
  },
  doc_acceptance_ddo_short: {
    code: 'doc_acceptance_ddo_short',
    ru_name: 'Прием документов и зачисление детей в дошкольные организации образования',
    kk_name: 'Мектепке дейінгі білім беру ұйымдарына құжаттарды қабылдау және балаларды тіркеу'
  },
  university_grant_short: {
    code: 'university_grant_short',
    ru_name: 'Присуждение образовательных грантов, а также оказание социальной поддержки обучающимся в организациях высшего образования',
    kk_name: 'Білім беруге гранттарды тағайындау, сондай-ақ жоғары білім беру ұйымдарында білім алушыларға әлеуметтік қолдау көрсету'
  },
  university_admission_short: {
    code: 'university_admission_short',
    ru_name: 'Прием документов и зачисление в высшие учебные заведения для обучения по образовательным программам высшего образования',
    kk_name: 'Жоғары білім беру бағдарламалары бойынша оқыту үшін жоғары оқу орындарына құжаттар қабылдау және оқуға қабылдау'
  },
  graduate_admission_short: {
    code: 'graduate_admission_short',
    ru_name: 'Прием документов и зачисление в высшие учебные заведения для обучения по образовательным программам послевузовского образования',
    kk_name: 'Жоғары оқу орнынан кейінгі кәсіптік оқу бағдарламаларымен оқу үшін жоғары оқу орындарына қабылдау және құжаттар қабылдау'
  },
  accept_doc_edu_short: {
    code: 'accept_doc_edu_short',
    ru_name: 'Прием документов и зачисление в организации образования (начальные, осн. ср., общ.ср, спец.)',
    kk_name: 'Білім беру ұйымдарына құжаттарды қабылдау және оқуға қабылдау (мектепке дейінгі, бастауыш, негізгі орта, жалпы орта, арнайы)'
  },
  children_property_short: {
    code: 'children_property_short',
    ru_name: 'Выдача справок для распоряжения имуществом несовершеннолетних детей',
    kk_name: 'Кәмелетке толмағандардың мүлкіне иелік ету үшін анықтамалар беру'
  },
  pmpk_short: {
    code: 'pmpk_short',
    ru_name: 'Прием документов для предоставления психолого-педагогической поддержки в организациях образования',
    kk_name: 'Білім беру ұйымдарында психологиялық-педагогикалық қолдау көрсету үшін құжаттар қабылдау'
  },
  duplic_edu_short: {
    code: 'duplic_edu_short',
    ru_name: 'Выдача дубликата документа об образовании',
    kk_name: 'Оқу туралы құжаттың көшірмесін беру'
  },
  organization_type_short: {
    code: 'organization_type_short',
    ru_name: 'Перевод и восстановление обучающихся по типам организаций образования',
    kk_name: 'Ауыстыру және қалпына келтіру бойынша білім алушыларды білім беру ұйымдарының үлгілері'
  },
  study_abroad_short: {
    code: 'study_abroad_short',
    ru_name: 'Регистрация студента обучающегося за рубежом',
    kk_name: 'Шетелде оқитын студенттерді тіркеу'
  },
  contest_leaders_short: {
    code: 'contest_leaders_short',
    ru_name: 'Прием документов для участия в конкурсе на замещение руководителей ГУ среднего образования, республиканского значения',
    kk_name: 'Орта, оның ішінде республикалық маңызы бар мемлекеттік білім беру мекемелерінің басшыларын алмастыру конкурсына қатысу үшін құжаттарды қабылдау'
  },
  extern_study_short: {
    code: 'extern_study_short',
    ru_name: 'Выдача разрешения на обучение в форме экстерната в организациях основного среднего, общего среднего образования',
    kk_name: 'Негізгі орта, жалпы орта беру ұйымдарында экстернат нысанында оқытуға рұқсат беру'
  },
  attestation_teacher_short: {
    code: 'attestation_teacher_short',
    ru_name: 'Прием документов для прохождения аттестации на присвоение (подтверждение) квалификационных категорий педагогическим работникам и приравненным к ним лицам организаций образования и республиканских подведомственных организаций образования, реализующих программы дошкольного воспитания и обучения, начального, основного среднего, общего среднего, технического и профессионального, послесреднего образования',
    kk_name: 'Мектепке дейінгі тәрбие мен оқыту, бастауыш, негізгі орта, жалпы орта, техникалық және кәсіптік, орта білімнен кейінгі білім беру бағдарламаларын іске асыратын білім беру ұйымдарының және республикалық ведомстволық бағынысты білім беру ұйымдарының педагог қызметкерлері мен оларға теңестірілген тұлғаларға біліктілік санаттарын беру (растау) үшін аттестаттаудан өткізуге құжаттар қабылдау'
  },
  children_adoption_short: {
    code: 'children_adoption_short',
    ru_name: 'Выдача справок по опеке и попечительству',
    kk_name: 'Қорғаншылық және қамқоршылық жөнінде анықтамалар беру'
  },
  children_disposable_short: {
    code: 'children_disposable_short',
    ru_name: 'Назначение единовременной денежной выплаты в связи с усыновлением ребенка-сироты и (или) ребенка, оставшегося без попечения родителей',
    kk_name: 'Жетім баланы және (немесе) ата-анасының қамқорлығынсыз қалған баланы асырап алуға байланысты біржолғы ақшалай төлемді тағайындау'
  },
  children_turn_short: {
    code: 'children_turn_short',
    ru_name: 'Постановка на учет лиц, желающих усыновить детей',
    kk_name: 'Бала асырап алуға тілек білдірген адамдарды есепке қою'
  },
  government_short: {
    code: 'government_short',
    ru_name: 'Прием документов на конкурс по размещению государственного образовательного заказа на подготовку кадров с техническим, профессиональным и послесредним образованием',
    kk_name: 'Техникалық, кәсіптік және орта білімнен кейінгі білімі бар кадрларды даярлауға арналған мемлекеттік білім беру тапсырысын орналастыру бойынша конкурсқа құжаттарды қабылдау'
  },
  payment_family_short: {
    code: 'payment_family_short',
    ru_name: 'Передача ребенка (детей) на воспитание в приемную семью и назначение выплаты денежных средств на их содержание',
    kk_name: 'Баланы (балаларды) қабылдаушы отбасына тәрбиелеуге беру және оларды асырауға ақшалай қаражат төлеуді тағайындау'
  },
  program_bolashak_short: {
    code: 'program_bolashak_short',
    ru_name: 'Прием документов для участия в конкурсе на присуждение международной стипендии «Болашак»',
    kk_name: '«Болашақ» халықаралық стипендиясын алуға конкурсқа қатысу үшін құжаттарды қабылдау'
  },
  status_bolashak_short: {
    code: 'status_bolashak_short',
    ru_name: 'Выдача справки о статусе стипендиата международной стипендии «Болашак»',
    kk_name: '"Болашақ" халықаралық стипендиясы стипендиатының мәртебесі туралы анықтама беру'
  },
  recognition_education_short: {
    code: 'recognition_education_short',
    ru_name: 'Признание и нострификация документов об образовании',
    kk_name: 'Білім туралы құжаттарды тану және нострификациялау'
  },
  advance_payment_short: {
    code: 'advance_payment_short',
    ru_name: 'Авансирование стипендиатов международной стипендии «Болашак»',
    kk_name: '«Болашақ» халықаралық стипендиясы стипендиаттарына аванс беру'
  },
  academ_mobile_short: {
    code: 'academ_mobile_short',
    ru_name: 'Прием документов для участия в конкурсе на обучение за рубежом в рамках академической мобильности',
    kk_name: 'Шетелде академиялық ұтқырлық шеңберінде оқу конкурсына қатысу үшін құжаттар қабылдау'
  }
};
